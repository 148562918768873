import {Component, ViewChild, ViewContainerRef} from '@angular/core';
import 'moment-timezone';
import { ApiService } from '../shared/Api.service';
import { ConfirmService, GridComponent, NotificationService, TranslateService, ValueFormatters, SwxModule, GridModule } from 'swx.front-end-lib';
import {FreezingFogAlertDialogComponent} from "./FreezingFogAlertDialog.component";
import {RootScope} from "../shared/RootScope.service";
import moment from 'moment';
import jQuery from 'jquery';
import {TicketTrackingSystemService} from "../shared/TicketTrackingSystem.service";
import {RawAcarsDialogComponent} from "../acarsOooiReports/RawAcarsDialog.component";
import { NgFor } from '@angular/common';
import { FormsModule } from '@angular/forms';
import {Dialog} from "@angular/cdk/dialog";

@Component({
    templateUrl: 'FreezingFogAlertList.component.html',
    standalone: true,
    imports: [
        SwxModule,
        FormsModule,
        NgFor,
        GridModule,
    ],
})
export class FreezingFogAlertListComponent {
    @ViewChild('grid', { static: true }) grid: GridComponent;
    search;
    clients = this.api.Client.query();
    airports = this.api.Airport.query();
    freezingFogWeatherSources = this.api.FreezingFogWeatherSource.query();
    datePickerOptions: any;
    query: any;
    columnDefs = [
        {
            headerName: "",
            children: [
                { colId: "Id", field: "Id", headerName: "#", width: 90, pinned: 'left' },
                { colId: "CreatedDate", field: "CreatedDate", headerName: "Created date", width: 100, valueFormatter: this.formatters.utcDateFormatter(), pinned: 'left', filterType: 'date' },
                { colId: "CreatedTime", field: "CreatedDate", headerName: "Created time", width: 70, valueFormatter: this.formatters.utcTimeFormatter(), excelIgnore: true, pinned: 'left', searchable: false },
                { colId: "ClientName", field: "ClientName", headerName: "Client", width: 140, pinned: 'left' },
                { colId: "AirportICAOCode", field: "AirportICAOCode", headerName: "Airport", width: 80, pinned: 'left' },
                { colId: "RegistrationNumber", field: "RegistrationNumber", headerName: "Registration number", width: 80, pinned: 'left' },
                { colId: "OnInFlightNumber", field: "OnInFlightNumber", headerName: "ON->IN flight number", width: 80, pinned: 'left' },
                { colId: "OutOffFlightNumber", field: "OutOffFlightNumber", headerName: "OUT->OFF flight number", width: 80, pinned: 'left' },
                { colId: "OnEventDate", field: "OnEventTime", headerName: "ON date", width: 100, valueFormatter: this.formatters.utcDateFormatter(), filterType: 'date' },
                { colId: "OnEventTime", field: "OnEventTime", headerName: "ON time", width: 70, cellTemplate: `<a (click)='parent.showAcars(params.data.OnAcarsOooiReportId)'>{{params.value|utcDateTimeFormat:parent.root.timeFormat}}</a>`, excelIgnore: true, searchable: false },
                { colId: "InEventDate", field: "InEventTime", headerName: "IN date", width: 100, valueFormatter: this.formatters.utcDateFormatter(), filterType: 'date' },
                { colId: "InEventTime", field: "InEventTime", headerName: "IN time", width: 70, cellTemplate: `<a (click)='parent.showAcars(params.data.InAcarsOooiReportId)'>{{params.value|utcDateTimeFormat:parent.root.timeFormat}}</a>`, excelIgnore: true, searchable: false },
                { colId: "OutEventDate", field: "OutEventTime", headerName: "OUT date", width: 100, valueFormatter: this.formatters.utcDateFormatter(), filterType: 'date' },
                { colId: "OutEventTime", field: "OutEventTime", headerName: "OUT time", width: 70, cellTemplate: `<a (click)='parent.showAcars(params.data.OutAcarsOooiReportId)'>{{params.value|utcDateTimeFormat:parent.root.timeFormat}}</a>`, excelIgnore: true, searchable: false },
                { colId: "OffEventDate", field: "OffEventTime", headerName: "OFF date", width: 100, valueFormatter: this.formatters.utcDateFormatter(), filterType: 'date' },
                { colId: "OffEventTime", field: "OffEventTime", headerName: "OFF time", width: 80, cellTemplate: `<a (click)='parent.showAcars(params.data.OffAcarsOooiReportId)'>{{params.value|utcDateTimeFormat:parent.root.timeFormat}}</a>`, excelIgnore: true, searchable: false },
                { colId: "CancelledDate", field: "CancelledTime", headerName: "Alerts cancelled date", width: 100, valueFormatter: this.formatters.utcDateFormatter(), filterType: 'date' },
                { colId: "CancelledTime", field: "CancelledTime", headerName: "Alerts cancelled time", cellTemplate: `
                    <a *ngIf="params.value && params.data.CancelledAcarsOooiReportId" (click)='parent.showAcars(params.data.CancelledAcarsOooiReportId)'>{{params.value|utcDateTimeFormat:parent.root.timeFormat}}</a>
                    <span *ngIf="params.value && !params.data.CancelledAcarsOooiReportId">{{params.value|utcDateTimeFormat:parent.root.timeFormat}}</span>
                    <button *ngIf="!params.value" type="button" class="button" (click)="parent.cancelAlerts(params.data)">{{'Cancel'|translate}}</button>`, width: 70, excelIgnore: true, searchable: false, cellClass: "select" },
                { colId: "AlertRateExceededDate", field: "AlertRateExceededTime", headerName: "Alert rate exceeded date", width: 100, valueFormatter: this.formatters.utcDateFormatter(), filterType: 'date' },
                { colId: "AlertRateExceededTime", field: "AlertRateExceededTime", headerName: "Alert rate exceeded time", width: 70, valueFormatter: this.formatters.utcTimeFormatter(), excelIgnore: true, searchable: false },
                { colId: "FreezingFogMinutes", field: "FreezingFogMinutes", headerName: "FZFG minutes", width: 80, filterType: 'float' },
                { colId: "OnInFreezingFogConditionsStartDate", field: "OnInFreezingFogConditionsStart", headerName: "ON->IN FZFG start date", width: 100, valueFormatter: this.formatters.utcDateFormatter(), filterType: 'date' },
                { colId: "OnInFreezingFogConditionsStartTime", field: "OnInFreezingFogConditionsStart", headerName: "ON->IN FZFG start time", width: 70, valueFormatter: this.formatters.utcTimeFormatter(), excelIgnore: true, searchable: false },
                { colId: "OnInWeatherSource", field: "OnInWeatherSource", headerName: "ON->IN FZFG weather source", width: 70, filterType: "enum", source: "FreezingFogWeatherSource", valueFormatter: (params) => params.value ? this.freezingFogWeatherSources[params.value] : '' },
                { colId: "OutOffFreezingFogConditionsStartDate", field: "OutOffFreezingFogConditionsStart", headerName: "OUT->OFF FZFG start date", width: 100, valueFormatter: this.formatters.utcDateFormatter(), filterType: 'date' },
                { colId: "OutOffFreezingFogConditionsStartTime", field: "OutOffFreezingFogConditionsStart", headerName: "OUT->OFF FZFG start time", width: 70, valueFormatter: this.formatters.utcTimeFormatter(), excelIgnore: true, searchable: false },
                { colId: "OutOffWeatherSource", field: "OutOffWeatherSource", headerName: "OUT->OFF FZFG weather source", width: 70, filterType: "enum", source: "FreezingFogWeatherSource", valueFormatter: (params) => params.value ? this.freezingFogWeatherSources[params.value] : '' },
                { colId: "EquipmentTypeName", field: "EquipmentTypeName", headerName: "Equipment type", width: 140 },
                { colId: "FreezingFogAlertProfileName", field: "FreezingFogAlertProfileName", headerName: "Profile", width: 140 },
                { colId: "InAcarsAlert", field: "InAcarsAlert.CreatedDate", headerName: "IN ACARS", width: 80, cellRenderer: c => this.alertsCellRenderer(c.data?.InAcarsAlert, 'IN ACARS') },
                { colId: "InEmailAlert", field: "OutAcarsAlert.CreatedDate", headerName: "IN Email", width: 80, cellRenderer: c => this.alertsCellRenderer(c.data?.InEmailAlert, 'IN Email') },
                { colId: "OutAcarsAlert", field: "OutAcarsAlert.CreatedDate", headerName: "OUT ACARS", width: 80, cellRenderer: c => this.alertsCellRenderer(c.data?.OutAcarsAlert, 'OUT ACARS') },
                { colId: "OutEmailAlert", field: "OutEmailAlert.CreatedDate", headerName: "OUT Email", width: 80, cellRenderer: c => this.alertsCellRenderer(c.data?.OutEmailAlert, 'OUT Email') },
                { colId: "OffAcarsAlert", field: "OffAcarsAlert.CreatedDate", headerName: "OFF ACARS", width: 80, cellRenderer: c => this.alertsCellRenderer(c.data?.OffAcarsAlert, 'OFF ACARS') },
                { colId: "OffEmailAlert", field: "OffEmailAlert.CreatedDate", headerName: "OFF Email", width: 80, cellRenderer: c => this.alertsCellRenderer(c.data?.OffEmailAlert, 'OFF Email') },
                { colId: "IntervalAcarsAlerts", field: "IntervalAcarsAlertCreatedDates", headerName: "FZFG interval ACARS", width: 150, cellRenderer: c => this.alertsCellRenderer(c.data?.IntervalAcarsAlerts, 'FZFG interval ACARS') },
                { colId: "IntervalEmailAlerts", field: "IntervalEmailAlertCreatedDates", headerName: "FZFG interval Emails", width: 150, cellRenderer: c => this.alertsCellRenderer(c.data?.IntervalEmailAlerts, 'FZFG interval Email') },
            ]
        }
    ];

    constructor(
        public api: ApiService,
        private viewContainerRef: ViewContainerRef,
        private formatters: ValueFormatters,
        private root: RootScope,
        private confirm: ConfirmService,
        private ticketTrackingSystem: TicketTrackingSystemService,
        private translate: TranslateService,
        private notification: NotificationService,
        private dialog: Dialog,
    ) {
        this.datePickerOptions = {
            maxDate: new Date(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate(), new Date().getUTCHours(), new Date().getUTCMinutes(), new Date().getUTCSeconds()),
        };

        if (window.localStorage['freezingFogAlertQueryV2']) {
            this.query = JSON.parse(window.localStorage['freezingFogAlertQueryV2']);
        } else {
            this.resetQuery();
        }
    }

    resetQuery() {
        this.query = {
            ClientId: [],
            AirportId: [],
            FromDate: null,
            ToDate: null,
            FreezingFogConditionsMet: true,
            Filters: [],
        };
    };

    apply() {
        window.localStorage['freezingFogAlertQueryV2'] = JSON.stringify(this.query);
        this.grid.refresh();
    }
    
    alertsCellRenderer(alerts, title) {
        var container = document.createElement('div');
        if (alerts != null) {
            var a = document.createElement('a');
            
            var width = Array.isArray(alerts) ? '80%' : '640px';
            alerts = Array.isArray(alerts) ? alerts : (alerts?.CreatedDate == null ? [] : [alerts]);
            
            a.innerHTML = alerts.map(alert => moment.utc(alert.CreatedDate).format(this.root.timeFormat)).join(', ');
            
            a.addEventListener('click', () => {
                this.dialog.open(FreezingFogAlertDialogComponent,
                    {
                        data: {
                            alerts: alerts,
                            title: title,
                        },
                        width: width,
                        height: '800px',
                    });

                setTimeout(() => jQuery(window).trigger('resize'), 200);
            });
            container.appendChild(a);
        }
        return container;
    }
    
    cancelAlerts(row) {
        this.confirm.confirm(this.translate.translate('Are you sure?'))
            .then(() => {
                if (this.root.currentUser.TicketTrackingRequired) {
                    return this.ticketTrackingSystem.getComment();
                } else {
                    return {};
                }
            })
            .then(ticketTrackingInfo => {
                var update = {
                    ...ticketTrackingInfo,
                    IsCancelled: true
                };
                this.api.FreezingFogAlertAirplane.put(row.Id, update)
                    .then(result => {
                        this.notification.show(this.translate.translate('FZFG alerts have been cancelled for this flight.'));
                        this.grid.refresh();
                    });
            });
    }

    showAcars(id) {
        if (id) {
            this.api.AcarsOooiReport.get(id).$promise.then(result => {
                this.dialog.open(RawAcarsDialogComponent, {
                    data: {
                        rawData: result.AcarsIncomingMessage.RawData,
                        title: 'ACARS',
                    },
                    width: '360px',
                    height: '540px',
                });
            });
        }
    }
}
