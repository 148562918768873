import {Component, ViewChild, ViewContainerRef} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {ApiService} from "../shared/Api.service";
import { ChangesService, SwxModule } from "swx.front-end-lib";
import {TicketTrackingSystemService} from "../shared/TicketTrackingSystem.service";
import { HasPermissionService, HasPermissionPipe } from "../shared/HasPermission.pipe";
import { ServiceProviderNotEnabledPipe } from "./ServiceProviderNotEnabled.pipe";
import { NgIf, NgFor, KeyValuePipe } from "@angular/common";
import { FormsModule } from "@angular/forms";

@Component({
    templateUrl: './SQSQueueConfigurationEdit.component.html',
    standalone: true,
    imports: [SwxModule, FormsModule, NgIf, NgFor, KeyValuePipe, HasPermissionPipe, ServiceProviderNotEnabledPipe]
})
export class SQSQueueConfigurationEditComponent {
    item;
    returnPath;
    @ViewChild('ngForm', { static: true }) ngForm;
    tab;
    configurationTypes = this.api.SQSQueueConfigurationType.query();
    serviceProviders = this.api.DeicingServiceProvider.query();
    serviceProvider;

    constructor(
        private router: Router,
        private viewContainerRef: ViewContainerRef,
        private route: ActivatedRoute,
        private api: ApiService,
        private changes: ChangesService,
        private ticketTrackingSystem: TicketTrackingSystemService,
        public hasPermissionService: HasPermissionService,
    ) {
        this.returnPath = this.route.snapshot.url[0].path.replace('/:id', '');
        const id = this.route.snapshot.params['id'];
        const copyId = this.route.snapshot.queryParams['copy'];
        const isNew = id === 'new';

        if (copyId) {
            this.item = this.api.SQSQueueConfiguration.get({ id: copyId });
            this.item.$promise.then(() => {
                delete this.item.Id;
            });
        } else if (isNew) {
            this.item = this.api.SQSQueueConfiguration.create();
        } else {
            this.item = this.api.SQSQueueConfiguration.get({ id: id });
        }

        this.tab = location.hash ? location.hash.substring(1) : 'basicInfo';
    }

    save() {
        this.ticketTrackingSystem.trackAndSave(this.item, this.returnPath);
    }

    cancel() {
        this.router.navigateByUrl(this.returnPath);
    }

    viewHistory() {
        this.changes.show({
            SubjectType: 'SQSQueueConfiguration',
            SubjectId: this.item.Id
        });
    }

    switchTab(tab) {
        location.hash = tab;
        this.tab = tab;
    }
    
    addServiceProvider() {
        this.item.SQSQueueConfigurationDeicingServiceProviders = this.item.SQSQueueConfigurationDeicingServiceProviders || [];
        this.item.SQSQueueConfigurationDeicingServiceProviders.push({
            DeicingServiceProviderId: this.serviceProvider.Id,
        });
        this.serviceProvider = null;
        this.ngForm.form.updateValueAndValidity();
        this.ngForm.form.markAsDirty();
    }
    
    removeServiceProvider(sqsQueueConfigurationDeicingServiceProvider) {
        this.item.SQSQueueConfigurationDeicingServiceProviders.splice(this.item.SQSQueueConfigurationDeicingServiceProviders.indexOf(sqsQueueConfigurationDeicingServiceProvider), 1);
        this.ngForm.form.updateValueAndValidity();
        this.ngForm.form.markAsDirty();
    }

    addEmail() {
        this.item.SQSQueueConfigurationEmails = this.item.SQSQueueConfigurationEmails || [];
        this.item.SQSQueueConfigurationEmails.push({});
        this.ngForm.form.updateValueAndValidity();
        this.ngForm.form.markAsDirty();
    }

    removeEmail(sqsQueueConfigurationEmail) {
        this.item.SQSQueueConfigurationEmails.splice(this.item.SQSQueueConfigurationEmails.indexOf(sqsQueueConfigurationEmail), 1);
        this.ngForm.form.updateValueAndValidity();
        this.ngForm.form.markAsDirty();
    }
}
