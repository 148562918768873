<page-title>{{('Station alert template'|translate) + ': ' + item.Name}}</page-title>
<form #ngForm="ngForm" (submit)="save()" class="noMargins" >
    <div class="toolbar">
        <a class="tab" (click)="switchTab('basicInfo')" [class.active]="tab == 'basicInfo'" [class.invalid]="!ngForm.form.controls.basicInfoForm?.valid">{{'Basic info'|translate}}</a>
        <input type="submit" [disabled]="!ngForm.form.valid || ngForm.form.pristine" value="{{'Save'|translate}}" />
        <input type="button" (click)="cancel()" value="{{'Cancel'|translate}}" />
        <input type="button" *ngIf="('DataChanges'|hasPermission)" (click)="viewHistory()" [disabled]="!item.Id" value="{{'View history'|translate}}" />
    </div>

    <fieldset class="contentMargins form" [disabled]="!('ConfigEditStationAlertTemplate'|hasPermission)" [hidden]="tab != 'basicInfo'" ngModelGroup="basicInfoForm">
        <label>
            <span class="caption">{{'Name'|translate}}</span>
            <input type="text" required [(ngModel)]="item.Name" name="item.Name" maxlength="100" />
        </label>
        <label>
            <span class="caption">{{'Active'|translate}}</span>
            <input type="checkbox" [(ngModel)]="item.Active" name="item.Active"/>
        </label>
        <label>
            <span class="caption">{{'Auto-select contacts'|translate}}</span>
            <multiselect-enum [enum]="clientContactAirportWarningTypeFlags" [(ngModel)]="item.WarningTypes" [name]="'item.WarningTypes'" width="400"></multiselect-enum>
        </label>
        <label>
            <span class="caption">{{'Sent to client customer representatives'|translate}}</span>
            <input type="checkbox" [(ngModel)]="item.SendToCustomerRepresentatives" name="item.SendToCustomerRepresentatives" />
        </label>
        <label>
            <span class="caption">{{'CC'|translate}}</span>
            <input type="email" [(ngModel)]="item.Cc" name="item.Cc" />
        </label>
        <label>
            <span class="caption">{{'BCC'|translate}}</span>
            <input type="email" [(ngModel)]="item.Bcc" name="item.Bcc" />
        </label>
        <label>
            <span class="caption">{{'Subject'|translate}}</span>
            <input type="text" required [(ngModel)]="item.Subject" name="item.Subject" style="width: 450px;" />
        </label>
        <label>
            <span class="caption">{{'Body'|translate}}</span>
            <ckeditor required [editor]="Editor" [config]="ckConfig" style="width: 100%;" [(ngModel)]="item.Body" [name]="'item.Body'"></ckeditor>
        </label>
        <h3>{{'Available tokens'|translate}}</h3>
        <table class="table">
            <tr>
                <th>{{'Code'|translate}}</th>
                <th>{{'Description'|translate}}</th>
            </tr>
            <tr>
                <td>{{'{AirportCode}'|translate}}</td>
                <td>{{'Airport code'|translate}}</td>
            </tr>
        </table>
    </fieldset>
</form>
