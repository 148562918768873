<swx-dialog cdkDrag>
    <swx-dialog-header cdkDragHandle>
        Acars preview
    </swx-dialog-header>
    <swx-dialog-content>
        <table class="table">
            <tr>
                <th>{{'Request'|translate}}</th>
                <th>{{'Response'|translate}}</th>
            </tr>
            <tr>
                <td>
                    <textarea readonly cols="24" rows="18" [(ngModel)]="hot.RawRequest"></textarea>
                    <br /><br />
                    <input type="button" (click)="onDownload(hot.RawRequest, 'ACARS_request.txt')" value="{{'Download'|translate}}" />
                </td>
                <td>
                    <textarea readonly cols="24" rows="18" [(ngModel)]="hot.RawAcarsResponse"></textarea>
                    <br /><br />
                    <input type="button" [disabled]="!hot.RawAcarsResponse" (click)="onDownload(hot.RawAcarsResponse, 'ACARS_response.txt')" value="{{'Download'|translate}}" />
                </td>
            </tr>
        </table>
    </swx-dialog-content>
</swx-dialog>
