import {Component, Inject, Input, OnInit} from '@angular/core';
import {DialogComponent, DialogContentComponent, DialogHeaderComponent, SwxModule} from 'swx.front-end-lib';
import { NgFor, NgIf } from '@angular/common';
import {DIALOG_DATA} from "@angular/cdk/dialog";

@Component({
    templateUrl: './FreezingFogAlertDialog.component.html',
    styleUrl: './FreezingFogAlertDialog.component.css',
    standalone: true,
    imports: [
        NgFor,
        NgIf,
        SwxModule,
        DialogHeaderComponent,
        DialogComponent,
        DialogContentComponent,
    ],
})
export class FreezingFogAlertDialogComponent implements OnInit {
    alerts!: any;
    title!: string;

    constructor(@Inject(DIALOG_DATA) private data: { alerts: any, title?: string }) {
    }
    
    ngOnInit() {
        this.alerts = this.data.alerts;
        this.title = this.data.title;
    }
    
    getBody(html) {
        const bodyIndex = html?.lastIndexOf("<body>");
        return bodyIndex > -1 ? (html?.substring(bodyIndex + 6, html.lastIndexOf("</body>"))) : html;
    }
}
