import {Component, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from '../shared/Api.service';
import { ChangesService, SwxModule } from 'swx.front-end-lib';
import { TicketTrackingSystemService } from '../shared/TicketTrackingSystem.service';
import { TranslateService, ConfirmService, FilterPipe } from 'swx.front-end-lib';
import { HasPermissionService, HasPermissionPipe } from '../shared/HasPermission.pipe';
import { NgIf, NgFor } from '@angular/common';
import { FormsModule } from '@angular/forms';

@Component({
    templateUrl: 'UserDetail.component.html',
    standalone: true,
    imports: [FormsModule, SwxModule, NgIf, NgFor, HasPermissionPipe]
})
export class UserDetailComponent implements OnInit {
    item;
    returnPath;
    permissions = this.api.Permission.query();
    RepeatPassword;
    @ViewChild('ngForm', { static: true }) ngForm;
    tab;
    sureWxDomainRegex = new RegExp('@surewx.com$');

    ticketTrackers = ['Gemini', 'YouTrack'];
    results: any;
    cruds: any;
    
    showConfig = false;
    showO = false;
    showData = false;
    showClientProfile = false;

    constructor(
        private router: Router,
        private viewContainerRef: ViewContainerRef,
        private route: ActivatedRoute,
        private api: ApiService,
        private changes: ChangesService,
        private ticketTrackingSystem: TicketTrackingSystemService,
        private translateService: TranslateService,
        private confirmService: ConfirmService,
        public hasPermissionService: HasPermissionService,
    ) {
        this.returnPath = this.route.snapshot.url[0].path.replace('/:id', '');
        const id = this.route.snapshot.params['id'];
        const copyId = this.route.snapshot.queryParams['copy'];
        const isNew = id === 'new';

        if (copyId) {
            this.item = this.api.User.get({ id: copyId });
            this.item.$promise.then(() => {
                delete this.item.Id;
                delete this.item.Name;
                delete this.item.Username;
                delete this.item.Email;
                delete this.item.Password;
                delete this.RepeatPassword;
                delete this.item.Comments;
                delete this.item.TicketTrackingUserId;
                delete this.item.TicketTrackingUsername;
                delete this.item.TicketTrackingApiKey;
            });
            this.RepeatPassword = '__PASSWORD__';
        } else if (isNew) {
            this.item = this.api.User.create({
                AirlineId: null,
                Active: true,
                LoginRequired: true
            });
        } else {
            this.item = this.api.User.get({ id: id });
            this.RepeatPassword = '__PASSWORD__';
        }

        this.tab = location.hash ? location.hash.substring(1) : 'basicInfo';
    }
    
    ngOnInit() {
        this.permissions.$promise.then(permissions => {
            let results = { 
                config: [
                    { title: "", data: [] }
                ], 
                clientProfile: [
                    {
                        title: "", 
                        data: []
                    }
                ], 
                other: [], 
                data: []
            };
            let cruds = [];
            for (const [key, value] of Object.entries(permissions)) {
                if (typeof value === 'string') {
                    let category = value.trim().substring(0, value.indexOf('-'));
                    
                    if (category.length === 0) {
                        category = value;
                    }
                    
                    let permissionName = value.substring(value.indexOf('-') + 1);
                    
                    if (permissionName.length === 0) {
                        permissionName = value;
                    }
                    const crud = category.trim() === 'Config' ? permissionName.trim().substring(0, permissionName.trim().indexOf(' ')) : '';
                    const entity = permissionName.replace(crud, '');
                    const currentEntity = results.config.filter(item => item.title === entity)[0];
                    if (cruds.indexOf(crud) === -1 && crud.length > 0) {
                        cruds.push(crud);
                    }
                    
                    switch (category.trim()) {
                        case 'Data':
                            results.data.push({
                                option: key,
                                name: value,
                                title: entity,
                                category: category
                            });
                            break;
                        case 'Config':
                            const currentCrud = cruds.find(elt => elt == crud);
                            if (!currentEntity) {
                                const currentConfig = {
                                    title: entity,
                                    data: [{
                                        option: key,
                                        name: value,
                                        permission: currentCrud
                                    }]
                                };
                                results.config.push(currentConfig);
                            }
                            results.config.forEach(item => {
                                const itemFound = item.data.filter(d => d.option === key)[0];
                                if (item.title === entity && !itemFound) {
                                    item.data.push({ option: key, name: value, permission: currentCrud });
                                }
                            });
                            break;
                        default: results.other.push({ option: key, name: value, title: entity, category: category }); 
                        break;
                    }
                }
                
            }
            const configs = results.config;
            const generalConfig = configs.filter(item => item.title.indexOf(':') === -1);
            const clientProfile = configs.filter(item => {
                const isSubPermission = item.title.indexOf(':') !== -1;
                item.title = isSubPermission ? item.title.substring(item.title.indexOf(':') + 1, item.title.length).trim() : item.title;
                return isSubPermission;
            });
            
            results.clientProfile = clientProfile;
            results.config = generalConfig;
            
            this.results = results;
            this.cruds = cruds;
        });
    }

    filter = (items, searchQuery) => FilterPipe.instance.transform(items, searchQuery);

    save() {
        this.ticketTrackingSystem.trackAndSave(this.item, this.returnPath);
    }

    cancel() {
        this.router.navigateByUrl(this.returnPath);
    }

    viewHistory() {
        this.changes.show({
            SubjectType: ['User'],
            SubjectId: this.item.Id
        });
    };

    switchTab(tab) {
        location.hash = tab;
        this.tab = tab;
    };

    isPermissionEnabled(option) {
        const selected = (this.item.Permissions || '').split(', ');
        return selected.indexOf(option) > -1;
    };

    togglePermission(option) {
        const selected = this.item.Permissions ? this.item.Permissions.split(', ') : [];
        const index = selected.indexOf(option);
        if (index > -1) {
            selected.splice(index, 1);
        } else {
            selected.push(option);
        }
        this.item.Permissions = selected.join(', ');
        this.ngForm.form.markAsDirty();
    }

    getConfigItem = (crud, configData) => {
        return configData.filter(d => d.permission === crud)[0];
    }
}
