import {Component} from "@angular/core";
import {ICellRendererAngularComp} from "ag-grid-angular";
import {ICellRendererParams} from "ag-grid-community";
import {DomSanitizer} from "@angular/platform-browser";
import {Dialog} from "@angular/cdk/dialog";
import {RawDataViewDialogComponent } from "swx.front-end-lib";

@Component({
    template: `<div><a (click)="showRawData()" class="mdi mdi-eye"></a></div>`,
    standalone: true
})
export class StationDataRawCellRendererComponent implements ICellRendererAngularComp {
    rawData: string = '';
    constructor(private dialog: Dialog,
                private sanitizer: DomSanitizer) {
    }

    agInit(params: ICellRendererParams): void {
        this.rawData = params.value;
    }

    refresh(params: ICellRendererParams): boolean {
        return false;
    }

    showRawData() {
        let rawData = this.rawData;
        let html: any;
        if (rawData.indexOf('<!DOCTYPE') === 0) {
            const el = document.createElement('html');
            el.innerHTML = rawData;

            html = this.sanitizer.bypassSecurityTrustHtml(el.querySelector('table.jdt').innerHTML);
        }
        if (rawData.indexOf('{') === 0) {
            try {
                const json = JSON.parse(rawData);
                rawData = JSON.stringify(json, null, 4);
            } catch (e) {
            }
        }
        this.dialog.open(RawDataViewDialogComponent, {
            width: '1030px',
            height: '680px',
            data: {
                rawData: rawData,
                html: html,
            }
        });
    }
}
