import {Component, OnInit, ViewChild, ViewContainerRef} from "@angular/core";
import { ApiService } from "../shared/Api.service";
import { AuthenticationService, ConfirmService, GridComponent, IAgGridColGroupDef, NotificationService, StorageService, ValueFormatters, yesNo, SwxModule, GridModule } from "swx.front-end-lib";
import { ReportDeicingErrorCellRendererComponent } from "./ReportDeicingErrorCellRenderer.component";
import { TicketTrackingSystemService } from "../shared/TicketTrackingSystem.service";
import { GridOptions } from "ag-grid-community";
import { HasPermissionService, HasPermissionPipe } from "../shared/HasPermission.pipe";
import { ReportDeicingSummaryComponent } from "./ReportDeicingSummary.component";
import { NgFor, NgIf } from "@angular/common";
import { FormsModule } from "@angular/forms";

@Component({
    templateUrl: 'ReportDeicing.component.html',
    standalone: true,
    imports: [SwxModule, FormsModule, NgFor, GridModule, NgIf, ReportDeicingSummaryComponent, HasPermissionPipe]
})
export class ReportDeicingComponent implements OnInit {
    @ViewChild('grid', { static: true }) grid: GridComponent;
    storageKey = 'deicingReportQuery';
    query: any = {};
    datePickerOptions: any;
    clients: any;
    airports: any;
    deicingUploads: any;
    hotRequestTypes: any;
    hotResponseTypes: any;
    fluids: any;
    resource = this.api.DeicingHot;
    exportResourcePost = this.api.DeicingHotExport;
    summaryVisible = false;
    components = {
        reportDeicingErrorCellRenderer: ReportDeicingErrorCellRendererComponent
    };
    gridOptions: GridOptions = {
        suppressRowClickSelection: true,
        isRowSelectable: (rowNode) => this.checkCanDeleteRecords(rowNode.data)
    };
    deicingRecordError = {};
    deicingRecordFormat = {};

    constructor(
        private api: ApiService,
        private viewContainerRef: ViewContainerRef,
        private formatters: ValueFormatters,
        private storage: StorageService,
        private confirm: ConfirmService,
        private authentication: AuthenticationService,
        private ticketTracking: TicketTrackingSystemService,
        private notification: NotificationService,
        private hasPermission: HasPermissionService) {
    }

    ngOnInit() {
        Promise.all([
            this.api.Client.query().$promise,
            this.api.Airport.query().$promise,
            this.api.DeicingRecordDataUpload.query().$promise,
            this.api.HotRequestType.query(),
            this.api.HotResponseType.query(),
            this.api.Fluid.query().$promise,
            this.api.DeicingRecordError.query(),
            this.api.DeicingRecordFormat.query(),
        ]).then(([clients, airports, deicingUploads, hotRequestTypes, hotResponseTypes, fluids, deicingRecordError, deicingRecordFormat]) => {
            this.clients = clients;
            this.airports = airports;
            this.deicingUploads = deicingUploads;
            this.hotRequestTypes = hotRequestTypes;
            this.hotResponseTypes = hotResponseTypes;
            this.fluids = fluids;
            this.deicingRecordError = deicingRecordError;
            this.deicingRecordFormat = deicingRecordFormat;

            this.query = this.storage.load(this.storageKey) || {
                HotNeededMargin: 0,
                Filters: [],
                ClientId: null
            }

            this.datePickerOptions = {
                maxDate: new Date(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate(), new Date().getUTCHours(), new Date().getUTCMinutes(), new Date().getUTCSeconds()),
                dateFormat: 'yy-mm-dd',
            };
        })
    }

    baseColumnDefs: IAgGridColGroupDef[] = [
        {
            headerName: 'Customer data',
            children: [
                { colId: "DeicingRecord.Date", field: "DeicingRecord.HoldoverStartTime", headerName: "Deicing date",  width: 90, valueFormatter: this.formatters.utcDateFormatter(), pinned: 'left', filterType: "date", checkboxSelection: true },
                { colId: "DeicingRecord.Time", field: "DeicingRecord.HoldoverStartTime", headerName: "Deicing time",  width: 60, valueFormatter: this.formatters.utcTimeFormatter(), excelIgnore: true, pinned: 'left', searchable: false },
                { colId: "DeicingRecord.Id", field: "DeicingRecord.Id", headerName: "#",  width: 60, pinned: 'left', filterType: "integer" },
                { colId: "DeicingRecord.Identifier", field: "DeicingRecord.Identifier", headerName: "Identifier",  width: 80, pinned: 'left' },
                { colId: "DeicingRecord.Error", field: "DeicingRecord.Error", headerName: "Error",  width: 100, filterType: "enum", source: "DeicingRecordError", valueFormatter: (params) => params.value ? this.deicingRecordError[params.value] : '' },
                { colId: "DeicingRecord.Format", field: "DeicingRecord.Format", headerName: "Source",  width: 100, filterType: "enum", source: "DeicingRecordFormat", valueFormatter: (params) => params.value? this.deicingRecordFormat[params.value] : '' },
                { colId: "ClientName", field: "ClientName", headerName: "Client",  width: 100 },
                { colId: "AirportCode", field: "AirportCode", headerName: "Airport",  width: 80 },
                { colId: "FlightIdentifier", field: "FlightIdentifier", headerName: "Flight #",  width: 100 },
                { colId: "DeicingRecord.EquipmentTypeIdentifier", field: "DeicingRecord.EquipmentTypeIdentifier", headerName: "Aircraft type",  width: 100 },
                { colId: "DeicingRecord.RegistrationNumber", field: "DeicingRecord.RegistrationNumber", headerName: "Registration #",  width: 100 },
            ]
        },
        {
            headerName: 'Deicing time stamps',
            children: [
                { colId: "DeicingDecisionDate", field: "DeicingDecisionDateTime", headerName: "DI decision date", width: 90, valueFormatter: this.formatters.utcDateFormatter(), excelIgnore: true, filterType: "date" },
                { colId: "DeicingDecisionTime", field: "DeicingDecisionDateTime", headerName: "DI decision time", width: 60, valueFormatter: this.formatters.utcTimeFormatter(), searchable: false },
            ]
        },
        {
            headerName: 'Customer data',
            children: [
                { colId: "DeicingRecord.DeicingStartDate", field: "DeicingRecord.DeicingStartTime", headerName: "Deicing start date",  width: 90, valueFormatter: this.formatters.utcDateFormatter(), excelIgnore: true, filterType: "date" },
                { colId: "DeicingRecord.DeicingStartTime", field: "DeicingRecord.DeicingStartTime", headerName: "Deicing start time",  width: 60, valueFormatter: this.formatters.utcTimeFormatter(), searchable: false },
            ]
        },
        {
            headerName: 'Deicing timestamps',
            children: [
                { colId: "DeicingRecord.HoldoverStartDate", field: "DeicingRecord.HoldoverStartTime", headerName: "Holdover/Anti-icing start date", width: 90, valueFormatter: this.formatters.utcDateFormatter(), excelIgnore: true, filterType: "date" },
                { colId: "DeicingRecord.HoldoverStartTime", field: "DeicingRecord.HoldoverStartTime", headerName: "Holdover/Anti-icing start time", width: 60, valueFormatter: this.formatters.utcTimeFormatter(), searchable: false },
            ]
        },
        {
            headerName: 'Customer data',
            children: [
                { colId: "DeicingRecord.DeicingCompleteDate", field: "DeicingRecord.DeicingCompleteTime", headerName: "De/Anti-ice finish date",  width: 90, valueFormatter: this.formatters.utcDateFormatter(), excelIgnore: true, filterType: "date" },
                { colId: "DeicingRecord.DeicingCompleteTime", field: "DeicingRecord.DeicingCompleteTime", headerName: "De/Anti-ice finish time",  width: 60, valueFormatter: this.formatters.utcTimeFormatter(), searchable: false },
                { colId: "DeicingRecord.CancelledDate", field: "DeicingRecord.CancelledTime", headerName: "Deicing cancelled date",  width: 90, valueFormatter: this.formatters.utcDateFormatter(), excelIgnore: true, filterType: "date" },
                { colId: "DeicingRecord.CancelledTime", field: "DeicingRecord.CancelledTime", headerName: "Deicing cancelled time",  width: 60, valueFormatter: this.formatters.utcTimeFormatter(), searchable: false },

                { colId: "DeicingRecord.ConfirmationSlip", field: "DeicingRecord.ConfirmationSlip", headerName: "Confirmation slip",  width: 100 },
                { colId: "DeicingRecord.InitialCallDate", field: "DeicingRecord.InitialCallTime", headerName: "Initial call date",  width: 90, valueFormatter: this.formatters.utcDateFormatter(), excelIgnore: true, filterType: "date" },
                { colId: "DeicingRecord.InitialCallTime", field: "DeicingRecord.InitialCallTime", headerName: "Initial call time",  width: 60, valueFormatter: this.formatters.utcTimeFormatter(), searchable: false },
                { colId: "DeicingRecord.TaxiInDate", field: "DeicingRecord.TaxiInTime", headerName: "Taxi in date",  width: 90, valueFormatter: this.formatters.utcDateFormatter(), excelIgnore: true, filterType: "date" },
                { colId: "DeicingRecord.TaxiInTime", field: "DeicingRecord.TaxiInTime", headerName: "Taxi in time",  width: 60, valueFormatter: this.formatters.utcTimeFormatter(), searchable: false },
                { colId: "DeicingRecord.BayAssignedInDate", field: "DeicingRecord.BayAssignedInTime", headerName: "Bay assigned in date",  width: 90, valueFormatter: this.formatters.utcDateFormatter(), excelIgnore: true, filterType: "date" },
                { colId: "DeicingRecord.BayAssignedInTime", field: "DeicingRecord.BayAssignedInTime", headerName: "Bay assigned in time",  width: 60, valueFormatter: this.formatters.utcTimeFormatter(), searchable: false },
                { colId: "DeicingRecord.ParkInInDate", field: "DeicingRecord.ParkInInTime", headerName: "Park in date",  width: 90, valueFormatter: this.formatters.utcDateFormatter(), excelIgnore: true, filterType: "date" },
                { colId: "DeicingRecord.ParkInInTime", field: "DeicingRecord.ParkInInTime", headerName: "Park in time",  width: 60, valueFormatter: this.formatters.utcTimeFormatter(), searchable: false },
                { colId: "DeicingRecord.IcemanAcceptInDate", field: "DeicingRecord.IcemanAcceptInTime", headerName: "Iceman accept in date",  width: 90, valueFormatter: this.formatters.utcDateFormatter(), excelIgnore: true, filterType: "date" },
                { colId: "DeicingRecord.IcemanAcceptInTime", field: "DeicingRecord.IcemanAcceptInTime", headerName: "Iceman accept in time",  width: 60, valueFormatter: this.formatters.utcTimeFormatter(), searchable: false },
                { colId: "DeicingRecord.IcehouseAcceptInDate", field: "DeicingRecord.IcehouseAcceptInTime", headerName: "Icehouse accept in date",  width: 90, valueFormatter: this.formatters.utcDateFormatter(), excelIgnore: true, filterType: "date" },
                { colId: "DeicingRecord.IcehouseAcceptInTime", field: "DeicingRecord.IcehouseAcceptInTime", headerName: "Icehouse accept in time",  width: 60, valueFormatter: this.formatters.utcTimeFormatter(), searchable: false },
                { colId: "DeicingRecord.Type1StartDate", field: "DeicingRecord.Type1StartTime", headerName: "Type 1 start date",  width: 90, valueFormatter: this.formatters.utcDateFormatter(), excelIgnore: true, filterType: "date" },
                { colId: "DeicingRecord.Type1StartTime", field: "DeicingRecord.Type1StartTime", headerName: "Type 1 start time",  width: 60, valueFormatter: this.formatters.utcTimeFormatter(), searchable: false },
                { colId: "DeicingRecord.Type1FinishDate", field: "DeicingRecord.Type1FinishTime", headerName: "Type 1 finish date",  width: 90, valueFormatter: this.formatters.utcDateFormatter(), excelIgnore: true, filterType: "date" },
                { colId: "DeicingRecord.Type1FinishTime", field: "DeicingRecord.Type1FinishTime", headerName: "Type 1 finish time",  width: 60, valueFormatter: this.formatters.utcTimeFormatter(), searchable: false },
                { colId: "DeicingRecord.Type4StartDate", field: "DeicingRecord.Type4StartTime", headerName: "Type 4 start date",  width: 90, valueFormatter: this.formatters.utcDateFormatter(), excelIgnore: true, filterType: "date" },
                { colId: "DeicingRecord.Type4StartTime", field: "DeicingRecord.Type4StartTime", headerName: "Type 4 start time",  width: 60, valueFormatter: this.formatters.utcTimeFormatter(), searchable: false },
                { colId: "DeicingRecord.Type4FinishDate", field: "DeicingRecord.Type4FinishTime", headerName: "Type 4 finish date",  width: 90, valueFormatter: this.formatters.utcDateFormatter(), excelIgnore: true, filterType: "date" },
                { colId: "DeicingRecord.Type4FinishTime", field: "DeicingRecord.Type4FinishTime", headerName: "Type 4 finish time",  width: 60, valueFormatter: this.formatters.utcTimeFormatter(), searchable: false },
                { colId: "DeicingRecord.IcehouseAcceptOutDate", field: "DeicingRecord.IcehouseAcceptOutTime", headerName: "Icehouse accept out date",  width: 90, valueFormatter: this.formatters.utcDateFormatter(), excelIgnore: true, filterType: "date" },
                { colId: "DeicingRecord.IcehouseAcceptOutTime", field: "DeicingRecord.IcehouseAcceptOutTime", headerName: "Icehouse accept out time",  width: 60, valueFormatter: this.formatters.utcTimeFormatter(), searchable: false },
                { colId: "DeicingRecord.IcemanOutDate", field: "DeicingRecord.IcemanOutTime", headerName: "Iceman accept out date",  width: 90, valueFormatter: this.formatters.utcDateFormatter(), excelIgnore: true, filterType: "date" },
                { colId: "DeicingRecord.IcemanOutTime", field: "DeicingRecord.IcemanOutTime", headerName: "Iceman accept out time",  width: 60, valueFormatter: this.formatters.utcTimeFormatter(), searchable: false },
                { colId: "DeicingRecord.CTMCallDate", field: "DeicingRecord.CTMCallTime", headerName: "CTM call date",  width: 90, valueFormatter: this.formatters.utcDateFormatter(), excelIgnore: true, filterType: "date" },
                { colId: "DeicingRecord.CTMCallTime", field: "DeicingRecord.CTMCallTime", headerName: "CTM call time",  width: 60, valueFormatter: this.formatters.utcTimeFormatter(), searchable: false },
                { colId: "DeicingRecord.BayOutDate", field: "DeicingRecord.BayOutTime", headerName: "Bay out date",  width: 90, valueFormatter: this.formatters.utcDateFormatter(), excelIgnore: true, filterType: "date" },
                { colId: "DeicingRecord.BayOutTime", field: "DeicingRecord.BayOutTime", headerName: "Bay out time",  width: 60, valueFormatter: this.formatters.utcTimeFormatter(), searchable: false },
                { colId: "DeicingRecord.TaxiOutDate", field: "DeicingRecord.TaxiOutTime", headerName: "Taxi out date",  width: 90, valueFormatter: this.formatters.utcDateFormatter(), excelIgnore: true, filterType: "date" },
                { colId: "DeicingRecord.TaxiOutTime", field: "DeicingRecord.TaxiOutTime", headerName: "Taxi out time",  width: 60, valueFormatter: this.formatters.utcTimeFormatter(), searchable: false },
                { colId: "DeicingRecord.IsHOTDeicing", field: "DeicingRecord.IsHOTDeicing", headerName: "HOT required?",  width: 70, cellRenderer: yesNo },
                { colId: "DeicingRecord.Completed", field: "DeicingRecord.Completed", headerName: "Completed?",  width: 70, cellRenderer: yesNo },
            ]
        },
        {
            headerName: 'OOOI / Customer data',
            children: [
                { colId: "WheelsUpDate", field: "WheelsUpTime", headerName: "Wheels up date", width: 90, valueFormatter: this.formatters.utcDateFormatter(), excelIgnore: true, filterType: "date" },
                { colId: "WheelsUpTime", field: "WheelsUpTime", headerName: "Wheels up time", width: 60, valueFormatter: this.formatters.utcTimeFormatter(), searchable: false },
            ]
        },
        {
            headerName: 'Customer data',
            children: [
                { colId: "DeicingTime", field: "DeicingTime", headerName: "Deicing duration (minutes)",  width: 90, filterType: "integer" },
            ]
        },
        {
            headerName: 'Deicing record',
            children: [
                { colId: "TaxiTime", field: "TaxiTime", headerName: "Taxi time (minutes)",  width: 60, filterType: "integer" },
                { colId: "DeicingRecordMissingData", field: "DeicingRecordMissingData", headerName: "Missing data?",  width: 80 },
                { colId: "DeicingRecord.Type1MixPercent", field: "DeicingRecord.Type1MixPercent", headerName: "Type 1 concentration used (%)",  width: 100 },
                { colId: "DeicingRecord.Type1MixPercentRequired", field: "DeicingRecord.Type1MixPercentRequired", headerName: "Type 1 concentration req'd (%)",  width: 100 },
                { colId: "Type1VolumeLitres", field: "Type1VolumeLitres", headerName: "Type 1 volume used (L)",  width: 80 },
                { colId: "DeicingRecord.Type4MixPercent", field: "DeicingRecord.Type4MixPercent", headerName: "Type 2/3/4 concentration (%)",  width: 100 },
                { colId: "Type4VolumeLitres", field: "Type4VolumeLitres", headerName: "Type 2/3/4 volume used (L)",  width: 80 },
                { colId: "DeicingRecord.AircraftCondition", field: "DeicingRecord.AircraftCondition", headerName: "Aircraft condition",  width: 100 },
            ]
        },
        {
            headerName: 'HOT comparison',
            children: [
                { colId: "Type1HotHot", field: "Type1HotHot", headerName: "Type 1 HOT (minutes)",  width: 250 },
                { colId: "Type1Hot.HotResponseHot.ReferenceMetarMinHot", field: "Type1Hot.HotResponseHot.ReferenceMetarMinHot", headerName: "Type 1 METAR table HOT (minimum)",  width: 90 },
                { colId: "Type1Hot.HotResponseHot.ReferenceMetarMaxHot", field: "Type1Hot.HotResponseHot.ReferenceMetarMaxHot", headerName: "Type 1 METAR table HOT (maximum)",  width: 90 },

                { colId: "Type4Hot.HotResponseHot.FluidDilution", field: "Type4Hot.HotResponseHot.FluidDilution", headerName: "Type 4 dilution (%)",  width: 100 },
                { colId: "Type4HotHot", field: "Type4HotHot", headerName: "Type 4 HOT (minutes)",  width: 250 },
                { colId: "Type4Hot.HotResponseHot.ReferenceMetarMinHot", field: "Type4Hot.HotResponseHot.ReferenceMetarMinHot", headerName: "Type 4 METAR table HOT (minimum)",  width: 90 },
                { colId: "Type4Hot.HotResponseHot.ReferenceMetarMaxHot", field: "Type4Hot.HotResponseHot.ReferenceMetarMaxHot", headerName: "Type 4 METAR table HOT (maximum)",  width: 90 },
            ]
        },
        {
            headerName: 'Request/Response',
            children: [
                { colId: "HotRequest.Id", field: "HotRequest.Id", headerName: "#",  width: 100, filterType: "integer" },
                { colId: "HotRequest.Type", field: "HotRequest.Type", headerName: "Type",  width: 100, filterType: "enum", source: "HotRequestType" },
                { colId: "HotResponse.Date", field: "HotResponse.DateTime", headerName: "Weather time stamp",  width: 90, valueFormatter: this.formatters.utcDateFormatter(), excelIgnore: true, filterType: "date" },
                { colId: "HotResponse.Time", field: "HotResponse.DateTime", headerName: "Weather time stamp",  width: 60, valueFormatter: this.formatters.utcTimeFormatter(), searchable: false },
                { colId: "HotResponse.Type", field: "HotResponse.Type", headerName: "Type",  width: 100 },
                { colId: "HotResponse.WingMaterialType", field: "HotResponse.WingMaterialType", headerName: "Wing Surface Type",  width: 170 },
                { colId: "HotResponse.Message", field: "HotResponse.Message", headerName: "Warning", width: 250, cellRenderer: 'reportDeicingErrorCellRenderer' },
                { colId: "HotResponse.Error", field: "HotResponse.Error", headerName: "Error",  width: 200, initialHide: true },
            ]
        },
        {
            headerName: 'Weather profile',
            children: [
                { colId: "StationName", field: "StationName", headerName: "Station",  width: 80 },
                { colId: "StationType", field: "StationType", headerName: "Station type",  width: 80, filterType: "integer" },
            ]
        },
        {
            headerName: 'Weather',
            children: [
                { colId: "WeatherProfile.WeatherCategory", field: "WeatherProfile.WeatherCategory", headerName: "Weather category",  width: 80, filterType: "enum", source: "WeatherCategory" },
                { colId: "WeatherProfile.Temperature", field: "WeatherProfile.Temperature", headerName: "Temperature (°C)",  width: 110, filterType: "float" },
                { colId: "MetarReadingWxString", field: "MetarReadingWxString", headerName: "METAR weather string",  width: 80 },
                { colId: "HotResponse.WeatherReport", field: "HotResponse.WeatherReport", headerName: "Weather report",  width: 150 },
                { colId: "WeatherProfile.LWE", field: "WeatherProfile.LWE", headerName: "LWE (g/dm²/h)",  width: 60, filterType: "float" },
                { colId: "WeatherProfile.WindSpeed", field: "WeatherProfile.WindSpeed", headerName: "Wind speed (m/s)",  width: 60, filterType: "float" },
            ]
        },
        {
            headerName: 'Conclusions',
            children: [
                { colId: "NoAntiIcingRequired", field: "NoAntiIcingRequired", headerName: "No anti-icing required",  width: 100 },
                { colId: "OnlyType1Needed", field: "OnlyType1Needed", headerName: "Only Type 1 needed",  width: 100 },
                { colId: "Type4NeededLwe", field: "Type4NeededLwe", headerName: "Type 4 required",  width: 90 },
                { colId: "DilutionOfType4Required", field: "DilutionOfType4Required", headerName: "Dilution of Type 4 req'd (%)",  width: 90, filterType: "float" },
                { colId: "Type4NeededMetar", field: "Type4NeededMetar", headerName: "Type 4 needed according to METAR",  width: 100 },
                { colId: "Type4SavedVsMetar", field: "Type4SavedVsMetar", headerName: "Actual type 4 saved?",  width: 90 },
                { colId: "Type4PotentialVolumeSavedLitres", field: "Type4PotentialVolumeSavedLitres", headerName: "Potential type 4 volume saved (L)",  width: 120, filterType: "float" },
                { colId: "TimeSavings", field: "TimeSavings", headerName: "Time savings",  width: 90, searchable: false },
                { colId: "FuelSavings", field: "FuelSavings", headerName: "Fuel savings",  width: 90, searchable: false },
                { colId: "EnvironmentalSavings", field: "EnvironmentalSavings", headerName: "Environmental savings",  width: 90, searchable: false },
            ]
        },
        {
            headerName: 'Usage',
            children: [
                { colId: "Usage", field: "Usage", headerName: "# of requests from this aircraft",  width: 90, searchable: false },
            ]
        },
        {
            headerName: 'Request',
            children: [
                { colId: "FlightIdentifier", field: "FlightIdentifier",  headerName: "Flight identifer", width: 100 },
                { colId: "PortalUserEmail", field: "PortalUserEmail",  headerName: "Portal user", width: 200 },
                { colId: "HotRequest.IPAddress", field: "HotRequest.IPAddress",  headerName: "IP address", width: 130 },
                { colId: "HotRequest.Uuid", field: "HotRequest.Uuid",  headerName: "Device UUID", width: 300 },
                { colId: "HotRequest.Latitude", field: "HotRequest.Latitude",  headerName: "Latitude", width: 100 },
                { colId: "HotRequest.Longitude", field: "HotRequest.Longitude",  headerName: "Longitude", width: 100 },
                { colId: "ClosestClientAirportCode", field: "ClosestClientAirportCode",  headerName: "Closest client airport", width: 80 },
                { colId: "ClosestAirportCode", field: "ClosestAirportCode",  headerName: "Closest airport", width: 80 },
            ]
        },
    ]
    columnDefs = [...this.baseColumnDefs];

    resetQuery() {
        this.query = {
            HotNeededMargin: 0,
            Filters: []
        };
    }

    apply() {
        this.storage.save(this.storageKey, this.query);
        this.grid.refresh();
    }

    deleteSelected() {
        this.confirm.confirm('Are you sure?').then(() => {
            const selectedIds = this.grid.gridApi.getSelectedRows().map(row => row.DeicingRecord ? row.DeicingRecord.Id : null);

            this.authentication.getCurrentUser().then(currentUser => {
                if (currentUser.TicketTrackingRequired) {
                    this.ticketTracking.getComment().then(result => {
                        this.api.DeicingRecordDataUpload.delete({
                            ...result,
                            Ids: selectedIds
                        }).then(() => {
                            this.notification.show('The data has been deleted.');
                            this.grid.refresh();
                        })
                    })
                }
                else {
                    this.api.DeicingRecordDataUpload.delete({
                        Ids: selectedIds
                    }).then(() => {
                        this.notification.show('The data has been deleted.');
                        this.grid.refresh();
                    })
                }
            })
        })
    }

    applyFluidSelect() {
        var columnDefs = this.baseColumnDefs.slice(0);

        if (this.query.FluidId && this.query.FluidId.length > 0) {
            var sortedFluidIds = [...this.query.FluidId].sort((a, b) => a - b);

            sortedFluidIds
                .forEach((fid, index) => {
                    var fluid = this.fluids.find(f => f.Id === fid);
                    var fluidName = fluid.FluidProfileName + ' - ' + fluid.Type + ' - ' + fluid.Name + ' (' + fluid.Dilution + ')';

                    columnDefs = columnDefs.concat([{
                        headerName: fluidName,
                        children: [
                            { colId: "HoldoverStartTime_Message_" + fid, field: "HoldoverStartTimeReferenceHots[" + index + "].Message", valueGetter: c => c.data?.HoldoverStartTimeReferenceHots[index]?.Message, headerName: "Deicing HOT (Minutes)", width: 250, sortable: false, searchable: false },
                            { colId: "BeforeWheelsUp_Message_" + fid, field: "BeforeWheelsUpReferenceHots[" + index + "].Message", valueGetter: c => c.data?.BeforeWheelsUpReferenceHots[index]?.Message, headerName: "Wheels up HOT (Minutes)", width: 250, sortable: false, searchable: false },
                            { colId: "HoldoverStartTime_MetarMinHot_" + fid, field: "HoldoverStartTimeReferenceHots[" + index + "].ReferenceMetarMinHot", valueGetter: c => c.data?.HoldoverStartTimeReferenceHots[index]?.ReferenceMetarMinHot, headerName: "METAR Min. HOT (minutes)", width: 60, sortable: false, searchable: false },
                            { colId: "HoldoverStartTime_MetarMaxHot_" + fid, field: "HoldoverStartTimeReferenceHots[" + index + "].ReferenceMetarMaxHot", valueGetter: c => c.data?.HoldoverStartTimeReferenceHots[index]?.ReferenceMetarMaxHot, headerName: "METAR Max. HOT (minutes)", width: 60, sortable: false, searchable: false },
                        ]
                    }])
                });

            this.columnDefs = columnDefs;
        }
    }

    onFluidIdChange() {
        this.applyFluidSelect();
    }

    checkCanDeleteRecords(data): boolean {
        const canDeleteRecords = this.hasPermission.hasPermission('DeicingDataDelete');
        return canDeleteRecords && data?.DeicingRecord?.Id;
    }
}
