import {Component, ViewChild} from "@angular/core";
import {GridModule, IAgGridColumnDef, StorageService, SwxModule, GridComponent} from "swx.front-end-lib";
import {ApiService} from "../shared/Api.service";
import {FormsModule} from "@angular/forms";
import {NgFor} from "@angular/common";

@Component({
    templateUrl: './AlgorithmPerformance.component.html',
    standalone: true,
    imports: [
        SwxModule,
        FormsModule,
        NgFor,
        GridModule,
    ]
})
export class AlgorithmPerformanceComponent {
    @ViewChild('grid', { static: true }) grid: GridComponent;
    storeKey = 'algorithmPerformanceQuery';
    datePickerOptions = {
        maxDate: new Date(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate(), new Date().getUTCHours(), new Date().getUTCMinutes(), new Date().getUTCSeconds()),
        dateFormat: 'yy-mm-dd',
        useUtc: true
    };
    query: any;
    stations = this.api.Station.query();
    sensorSelectMatrices = this.api.SensorSelectMatrix.query();
    metarWeatherMappingProfiles = this.api.MetarWeatherMappingProfile.query();
    stationLoggerTypes = this.api.StationLoggerType.query();
    weatherCategories = this.api.WeatherCategory.query();

    constructor(
        private storage: StorageService, 
        public api: ApiService,
    ) {
       const storedQuery = this.storage.load(this.storeKey);
       if (storedQuery){
           this.query = storedQuery;
       } else {
           this.resetQuery();
       }
    }

    resetQuery() {
        this.query = {
            Filters: [],
            WeatherCategory: 'SN',
            MaxTemp: 7,
            MetarWeatherMappingProfileId: 48,
        };
    };
    
    refresh() {
        this.storage.save(this.storeKey, this.query);
        this.grid.refresh();
    }

    columnDefs: IAgGridColumnDef[] = [
        { colId: "StationName", field: "StationName", headerName: "Station", width: 100, pinned: 'left', cellStyle: r => ({ backgroundColor: '#ffffff', borderTop: r.data?.GroupRowNumber == 1 ? '1px solid #cccccc' : '' }), },
        { colId: "SensorSelectMatrixName", field: "SensorSelectMatrixName", headerName: "Sensor select matrix", width: 250, pinned: 'left', cellStyle: r => ({ backgroundColor: '#ffffff', borderTop: r.data?.GroupRowNumber == 1 ? '1px solid #cccccc' : '' }), },
        { colId: "SensorSelectMatrixIntensitySensorMappingOrder", field: "SensorSelectMatrixIntensitySensorMappingOrder", headerName: "Intensity sensor mapping order", width: 120, filterType: "integer", pinned: 'left', cellStyle: r => ({ backgroundColor: '#ffffff', borderTop: r.data?.GroupRowNumber == 1 ? '1px solid #cccccc' : '' }), },
        { colId: "StationType", field: "StationType", headerName: "Type", width: 50, filterType: 'enum', source: 'StationType', cellStyle: r => ({ backgroundColor: '#ffffff', borderTop: r.data?.GroupRowNumber == 1 ? '1px solid #cccccc' : '' }), },
        { colId: "StationLoggerType", field: "StationLoggerType", headerName: "Logger", width: 120, filterType: 'enum', source: 'StationLoggerType', valueFormatter: (params) => params.data?.StationLoggerType ? this.stationLoggerTypes[params.data.StationLoggerType] : '', cellStyle: r => ({ backgroundColor: '#ffffff', borderTop: r.data?.GroupRowNumber == 1 ? '1px solid #cccccc' : '' }), },
        { colId: "RemoteEndpointName", field: "RemoteEndpointName", headerName: "Remote endpoint", width: 100, cellStyle: r => ({ backgroundColor: '#ffffff', borderTop: r.data?.GroupRowNumber == 1 ? '1px solid #cccccc' : '' }), },
        { colId: "RequiredWeatherSensors", field: "RequiredWeatherSensors", headerName: "Sensors", width: 300, filterType: 'enum', source: 'WeatherSensorType', cellStyle: r => ({ backgroundColor: '#ffffff', borderTop: r.data?.GroupRowNumber == 1 ? '1px solid #cccccc' : '' }), },
        { colId: "Count", field: "Count", headerName: "Weather profile (count)", width: 100, filterType: "integer", cellStyle: r => ({ backgroundColor: '#ffffff', borderTop: r.data?.GroupRowNumber == 1 ? '1px solid #cccccc' : '' }), },
        { colId: "Percent", field: "Percent", headerName: "Weather profiles (%)", width: 100, filterType: "float", cellStyle: r => ({ backgroundColor: '#ffffff', borderTop: r.data?.GroupRowNumber == 1 ? '1px solid #cccccc' : '', color: r.data?.SensorSelectMatrixIntensitySensorMappingOrder == 1 && r.data?.Percent < 99.5 ? '#ff8c00' : '#000000' }), },
        { colId: "HotResponseCount", field: "HotResponseCount", headerName: "HOT requests (count)", width: 80, filterType: "integer", cellStyle: r => ({ backgroundColor: '#ffffff', borderTop: r.data?.GroupRowNumber == 1 ? '1px solid #cccccc' : '' }), },
        { colId: "HotResponsePercent", field: "HotResponsePercent", headerName: "HOT requests (%)", width: 80, filterType: "float", cellStyle: r => ({ backgroundColor: '#ffffff', borderTop: r.data?.GroupRowNumber == 1 ? '1px solid #cccccc' : '' }), },
    ];
} 
