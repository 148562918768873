<swx-dialog cdkDrag>
    <swx-dialog-header cdkDragHandle>
        Manage Tableau user
    </swx-dialog-header>
    <swx-dialog-content>
        <form #ngForm="ngForm" class="form">
            <label>
                <span class="caption">{{'SureWx user'}}</span>
                <span>{{portalUser.Email}}</span>
            </label>

            <label>
                <span class="caption">{{'Manually assign Tableau user'}}</span>
                <input type="radio" name="tableauUserProvisioning" [(ngModel)]="tableauUserProvisioning" [value]="'manual'" />
            </label>
            <fieldset [disabled]="tableauUserProvisioning !== 'manual'">
                <label>
                    <span class="caption">{{'Tableau user'}}</span>
                    <input type="text" [(ngModel)]="portalUser.TableauTrustedAuthenticationUsername" name="portalUser.TableauTrustedAuthenticationUsername" />
                </label>
            </fieldset>

            <label>
                <span class="caption">{{'Automatically assign Tableau user'}}</span>
                <input type="radio" name="tableauUserProvisioning" [(ngModel)]="tableauUserProvisioning" [value]="'automatic'" />
            </label>
            <fieldset [disabled]="tableauUserProvisioning !== 'automatic'">
                <label>
                    <span class="caption">{{'Tableau group'}}</span>
                    <span>{{portalUser.TableauTrustedAuthenticationGroupName}}</span>
                </label>
                <label>
                    <span class="caption">{{'Tableau user'}}</span>
                    <span>{{portalUser.TableauTrustedAuthenticationUsername}}</span>
                </label>
                @if (portalUser.TableauTrustedAuthenticationUserId === null) {
                    <label>
                        <span class="caption"></span>
                        <button (click)="createTableauUser()" type="button">{{'Provision Tableau user now'|translate}}</button>
                    </label>
                }
                @if(portalUser.TableauTrustedAuthenticationUserId !== null) {
                    <label>
                        <span class="caption"></span>
                        <button (click)="deleteTableauUser()" type="button">{{'Delete Tableau user now'|translate}}</button>
                    </label>
                }
            </fieldset>
        </form>
    </swx-dialog-content>
</swx-dialog>

