<form #form #ngForm="ngForm" (submit)="save()">
    <page-title>{{('Mobile user'|translate) + ': ' + (item.Name || 'new')}}</page-title>
	<div id="mobileUserEditPage" class="noMargins">
		<div class="toolbar">
			<a class="tab" (click)="switchTab('basicInfo')" [class.active]="tab == 'basicInfo'" [class.invalid]="!ngForm.form.controls.basicInfoForm?.valid">{{'Basic info'|translate}}</a>
            <input type="submit" [disabled]="!ngForm.form.valid || ngForm.form.pristine" value="{{'Save'|translate}}" />
            <input type="button" (click)="cancel()" value="{{'Cancel'|translate}}" />
            <input type="button" *ngIf="('DataChanges'|hasPermission)" (click)="viewHistory()" [disabled]="!item.Id" value="{{'View history'|translate}}" />
		</div>

		<fieldset class="contentMargins form" [disabled]="!('ConfigEditMobileUsers'|hasPermission)" [style.display]="tab == 'basicInfo' ? '' : 'none'" ngModelGroup="basicInfoForm">
			<label>
				<span class="caption">{{'Name'|translate}}</span>
				<input type="text" required [(ngModel)]="item.Name" name="Name" />
			</label>
			<label>
				<span class="caption">{{'Active'|translate}}</span>
				<input type="checkbox" [(ngModel)]="item.Active" name="Active" />
			</label>
			<label>
				<span class="caption">{{'Email'|translate}}</span>
				<input type="email" required [(ngModel)]="item.Email" name="Email" autocomplete="off" />
			</label>
			<label>
				<span class="caption">{{'Password'|translate}}</span>
				<input type="password" required [(ngModel)]="item.Password" name="Password" autocomplete="off" minlength="6" />
			</label>
			<label>
				<span class="caption">{{'Repeat password'|translate}}</span>
				<input type="password" required [(ngModel)]="RepeatPassword" name="RepeatPassword" autocomplete="off" [password-match]="item.Password" />
			</label>
            <label>
                <span class="caption">{{'Access Token Expiration Time in Seconds'|translate}}</span>
                <input type="number" [(ngModel)]="item.AccessTokenExpireTimeSeconds" name="item.AccessTokenExpireTimeSeconds" />
            </label>
            <label>
                <span class="caption">{{'Mobile app configuration'|translate}}</span>
                <select [(ngModel)]="item.ClientApiTokenId" name="item.ClientApiTokenId" required>
                    <option *ngFor="let option of clientApiTokens|orderBy:'APIClientCode'" [ngValue]="option.Id">
                        {{option.APIClientCode}}
                    </option>
                </select>
            </label>

            <h3>{{'Allow switching mobile app configurations'|translate}}</h3>

            <span class="field">
                <select [(ngModel)]="clientApiToken" name="clientApiToken">
                    <option *ngFor="let clientApiToken of clientApiTokensNotEnabled(clientApiTokens, item.MobileUserClientApiTokens)|orderBy:'APIClientCode'" [ngValue]="clientApiToken">
                        {{clientApiToken.APIClientCode}}
                    </option>
                </select>
                <input type="button" class="green" (click)="addMobileUserClientApiToken()" value="{{'Add configuration'|translate}}" [disabled]="clientApiToken == null" />
            </span>
            
            <br />
    
            <table class="table">
                <tr>
                    <th>{{'#'|translate}}</th>
                    <th>{{'Name'|translate}}</th>
                    <th></th>
                </tr>
                <tr *ngFor="let mobileUserClientApiToken of item.MobileUserClientApiTokens; let i = index">
                    <td>
                        {{mobileUserClientApiToken.ClientApiTokenId}}
                    </td>
                    <td>
                        {{(clientApiTokens|findById:mobileUserClientApiToken.ClientApiTokenId)?.APIClientCode}}
                    </td>
                    <td>
                        <a *ngIf="('ConfigEditMETARWeatherMappingProfiles'|hasPermission)" (click)="removeMobileUserClientApiToken(mobileUserClientApiToken)" class="mdi mdi-delete"></a>
                    </td>
                </tr>
            </table>
		</fieldset>
	</div>
</form>
