import {Component, OnInit, ViewChild, ViewContainerRef} from "@angular/core";
import {ApiService} from "../shared/Api.service";
import { ConfirmService, GridComponent, IAgGridColumnDef, IResourceItem, TranslateService, ValueFormatters, SwxModule, GridModule } from "swx.front-end-lib";
import {TicketTrackingSystemService} from "../shared/TicketTrackingSystem.service";
import { HasPermissionService, HasPermissionPipe } from "../shared/HasPermission.pipe";
import { Router, RouterLink } from "@angular/router";
import {yesNo} from "swx.front-end-lib";
import {ICellRendererParams} from "ag-grid-community";
import { NgIf } from "@angular/common";
import { FormsModule } from "@angular/forms";

@Component({
    templateUrl: './ClientList.component.html',
    standalone: true,
    imports: [
        SwxModule,
        FormsModule,
        GridModule,
        NgIf,
        RouterLink,
        HasPermissionPipe,
    ],
})
export class ClientListComponent implements OnInit {
    @ViewChild('grid', { static: true }) grid: GridComponent;
    searchQuery;
    query: any;
    includeInactive = false;
    columnDefs: IAgGridColumnDef[] = [
        { colId: "Id", field: "Id", headerName: "#", width: 50, filterType: 'integer', pinned: 'left' },
        { colId: "Name", field: "Name", headerName: "Name", width: 300, pinned: 'left', cellTemplate: `<a [routerLink]="['/clients', params.data?.Id]">{{params.data?.Name}}</a>` },
        { colId: 'ServiceLevel', field: 'ServiceLevel', headerName: 'Service Lvl', width: 120 },
        { 
            colId: "Active", 
            field: "Active", 
            headerName: "Active?", 
            width: 70, 
            filterType: 'boolean',
            cellRenderer: yesNo,
        },
        { colId: "ContactsCount", field: "ContactsCount", headerName: "Contacts", width: 70, cellClass: "actions", cellRenderer: c => {
                if (c.data == null) return '';
                var container = document.createElement('div');
                container.innerHTML = '<a href="/clientContacts/' + c.data.Id + '" title="' + this.translateService.translate('Edit') + '" onclick="event.preventDefault();">' + c.value + ' <span class="mdi mdi-pencil"></span></a>';
                Array.from(container.querySelectorAll('a[href]')).forEach(n => n.addEventListener('click', e => this.router.navigateByUrl(n.getAttribute('href'))));
                return container;
            } },
        { colId: "PortalUsersCount", field: "PortalUsersCount", headerName: "Portal users", width: 90, cellClass: "actions", cellRenderer: c => {
                if (c.data == null) return '';
                var container = document.createElement('div');
                container.innerHTML = '<a href="/portalUsers/' + c.data.Id + '" title="' + this.translateService.translate('Edit') + '" onclick="event.preventDefault();">' + c.value + ' <span class="mdi mdi-pencil"></span></a>';
                Array.from(container.querySelectorAll('a[href]')).forEach(n => n.addEventListener('click', e => this.router.navigateByUrl(n.getAttribute('href'))));
                return container;
            } },
        { 
            colId: "UseAcars", 
            field: "UseAcars", 
            headerName: "ACARS?", 
            width: 70, 
            filterType: 'boolean', 
            cellRenderer: yesNo,
        },
        { 
            colId: "UseAPI", 
            field: "UseAPI", 
            headerName: "API?", 
            width: 70, 
            filterType: 'boolean', 
            cellRenderer: yesNo,
        },
        { 
            colId: "UseClientPortal", 
            field: "UseClientPortal",
            headerName: "Portal?", 
            width: 70, 
            filterType: 'boolean', 
            cellRenderer: yesNo,
        },
        { colId: "IATACode", field: "IATACode", headerName: "IATA code", width: 80 },
        { colId: "ICAOCode", field: "ICAOCode", headerName: "ICAO code", width: 80 },
        { colId: "LWEMessageMappingProfileName", field: "LWEMessageMappingProfileName", headerName: "LWE message mappings", width: 200, cellTemplate: `<a [routerLink]="['/lweMessageMappingProfiles', params.data?.LWEMessageMappingProfileId]">{{params.data?.LWEMessageMappingProfileName}}</a>` },
        { colId: "MetarMessageMappingProfileName", field: "MetarMessageMappingProfileName", headerName: "METAR message mappings", width: 200, cellTemplate: `<a [routerLink]="['/metarMessageMappingProfiles', params.data?.MetarMessageMappingProfileId]">{{params.data?.MetarMessageMappingProfileName}}</a>` },
        { colId: "MetarWeatherMappingProfileName", field: "MetarWeatherMappingProfileName", headerName: "METAR weather configuration", width: 300, cellTemplate: `<a [routerLink]="['/metarWeatherMappingProfiles', params.data?.MetarWeatherMappingProfileId]">{{params.data?.MetarWeatherMappingProfileName}}</a>` },
        { colId: "CustomerRepresentative", field: "CustomerRepresentative", headerName: "Customer representative", width: 150 },
        { colId: "LWEStations", field: "LWEStations", headerName: "LWE stations", width: 200 },

        { colId: "ActiveFrostProfileNames", field: "ActiveFrostProfileNames", headerName: "Active frost warnings", width: 150 },
        { colId: "BarrelIcingProfileNames", field: "BarrelIcingProfileNames", headerName: "Barrel icing warnings", width: 150 },
        { colId: "HeatFlapsAlertProfileNames", field: "HeatFlapsAlertProfileNames", headerName: "Heat flaps alerts", width: 150 },
        { colId: "FlapContaminationAlertProfileNames", field: "FlapContaminationAlertProfileNames", headerName: "Flap contamination alerts", width: 150 },
        { colId: "FreezingFogAlertProfileNames", field: "FreezingFogAlertProfileNames", headerName: "FZFG alerts", width: 150 },
        { colId: "EngineCoverAlertProfileNames", field: "EngineCoverAlertProfileNames", headerName: "Engine cover alerts", width: 150 },
        { colId: "WeatherAlertProfileNames", field: "WeatherAlertProfileNames", headerName: "Weather alerts", width: 150 },

        { colId: "LastModified", field: "LastModified", headerName: "Modified date", width: 150, filterType: 'date', valueFormatter: this.formatters.utcDateTimeFormatter() },
        { colId: "LastModifiedByUserName", field: "LastModifiedByUserName", headerName: "Modified by", width: 100 },
        { colId: "CreatedDate", field: "CreatedDate", headerName: "Created date", width: 150, filterType: 'date', valueFormatter: this.formatters.utcDateTimeFormatter() },
        { colId: "CreatedByUserName", field: "CreatedByUserName", headerName: "Created by", width: 100 },
        { colId: "Actions", field: "Id", headerName: "", width: 80, cellClass: "actions", cellRenderer: c => {
                if (c.data == null) return '';
                var container = document.createElement('div');
                container.innerHTML = '<a href="/clients/' + c.data.Id + '" class="mdi mdi-pencil" title="' + this.translateService.translate('Edit') + '" onclick="event.preventDefault();"></a>'
                    + (this.hasPermission.hasPermission('ConfigEditClients') ? '<a class="mdi mdi-delete" title="' + this.translateService.translate('Delete') + '" onclick="event.preventDefault();"></a>' : '')
                    + (this.hasPermission.hasPermission('ConfigEditClients') ? '<a href="/clients/new?copy=' + c.data.Id + '" class="mdi mdi-content-copy" title="' + this.translateService.translate('Copy') + '" onclick="event.preventDefault();"></a>' : '');
                Array.from(container.querySelectorAll('a[href]')).forEach(n => n.addEventListener('click', e => this.router.navigateByUrl(n.getAttribute('href'))));
                Array.from(container.querySelectorAll('.mdi-delete')).forEach(n => n.addEventListener('click', () => this.delete(c.data)));
                return container;
            }, pinned: "right", searchable: false, excelIgnore: true },
    ];
    
    constructor(public api: ApiService,
                private viewContainerRef: ViewContainerRef,
                private confirmService: ConfirmService,
                private translateService: TranslateService,
                private hasPermission: HasPermissionService,
                private router: Router,
                private ticketTrackingSystem: TicketTrackingSystemService,
                private formatters: ValueFormatters) {
    }

    ngOnInit() {
        if (window.localStorage['clientQuery']) {
            this.query = JSON.parse(window.localStorage['clientQuery']);
            this.refresh();
        } else {
            this.resetQuery();
        }
    }

    resetQuery() {
        this.searchQuery = '';
        this.query = {
            Filters: []
        };
        this.refresh();
    };

    refresh() {
        window.localStorage['clientQuery'] = JSON.stringify(this.query);
        this.grid.refresh();
    }

    delete(item: IResourceItem) {
        this.confirmService.confirm(this.translateService.translate('Are you sure?')).then(() => {
            this.ticketTrackingSystem.trackAndDelete(item, () => this.refresh());
        }).catch(() => {});
    }
}
