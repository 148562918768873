import {Component, OnInit, ViewContainerRef} from "@angular/core";
import { ApiService } from "../shared/Api.service";
import { AuthenticationService, SwxModule } from "swx.front-end-lib";
import { TicketTrackingSystemService } from "../shared/TicketTrackingSystem.service";
import { FormsModule } from "@angular/forms";
import { NgIf, NgFor } from "@angular/common";

@Component({
    templateUrl: 'HumanObservationDataUpload.component.html',
    standalone: true,
    imports: [NgIf, FormsModule, NgFor, SwxModule]
})
export class HumanObservationDataUploadComponent implements OnInit {
    airportId: number;
    data: string;
    skipLines: number;
    result: any;
    airports: any;

    constructor(
        private api: ApiService,
        private viewContainerRef: ViewContainerRef,
        private auth: AuthenticationService,
        private ticketTracker: TicketTrackingSystemService) {
    }

    ngOnInit() {
        this.api.Airport.query({ IncludeInactive: true }).$promise.then(airports => {
            this.airports = airports;
        })
    }

    upload() {
        const query = {
            AirportId: this.airportId,
            Data: this.data,
            SkipLines: this.skipLines
        }

        this.auth.getCurrentUser().then(user => {
            if (user.TicketTrackingRequired) {
                this.ticketTracker.getComment().then(result => {
                    this.api.HumanObservationDataUpload.post({
                        ...result,
                        ...query
                    }).then(result => {
                        this.result = result;
                    })
                })
            }
            else {
                this.api.HumanObservationDataUpload.post(query).then(result => {
                    this.result = result;
                })
            }
        });
    }
}
