import {Component, ViewChild, ViewContainerRef} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from '../shared/Api.service';
import { ChangesService, SwxModule } from 'swx.front-end-lib';
import { TicketTrackingSystemService } from '../shared/TicketTrackingSystem.service';
import { HasPermissionService, HasPermissionPipe } from '../shared/HasPermission.pipe';
import { NgIf, NgFor } from '@angular/common';
import { FormsModule } from '@angular/forms';

@Component({
    templateUrl: 'HeatFlapsAlertProfileDetail.component.html',
    standalone: true,
    imports: [SwxModule, FormsModule, NgIf, NgFor, HasPermissionPipe]
})
export class HeatFlapsAlertProfileDetailComponent {
    item: any;
    returnPath;
    tab;
    equipmentTypes = this.api.EquipmentType.query();
    clients = this.api.Client.query();
    ibmMqServerConfigurations = this.api.IBMMQServerConfiguration.query();
    @ViewChild('ngForm', {static: true}) ngForm;
    heatFlapsAlertProfileEquipmentTypeOption;

    constructor(
        private router: Router,
        private viewContainerRef: ViewContainerRef,
        private route: ActivatedRoute,
        private api: ApiService,
        private changes: ChangesService,
        private ticketTrackingSystem: TicketTrackingSystemService,
        public hasPermissionService: HasPermissionService,
    ) {
        this.tab = location.hash ? location.hash.substring(1) : 'basicInfo';

        this.returnPath = this.route.snapshot.url[0].path.replace('/:id', '');
        const id = this.route.snapshot.params['id'];
        const copyId = this.route.snapshot.queryParams['copy'];
        const isNew = id === 'new';

        if (copyId) {
            this.item = this.api.HeatFlapsAlertProfile.get({ id: copyId });
            this.item.$promise.then(() => {
                delete this.item.Id;

                if (this.item.HeatFlapsAlertProfileEquipmentTypes) {
                    this.item.HeatFlapsAlertProfileEquipmentTypes.forEach(related => {
                        delete related.HeatFlapsAlertProfileId;
                    });
                }
            });
        } else if (isNew) {
            this.item = this.api.HeatFlapsAlertProfile.create({
                Active: true,
            });
        } else {
            this.item = this.api.HeatFlapsAlertProfile.get({ id: id });
        }
    }

    save() {
        this.item.$promise.then(item => {
            this.ticketTrackingSystem.trackAndSave(this.item, this.returnPath);
        });
    }

    cancel() {
        this.router.navigateByUrl(this.returnPath);
    }

    viewHistory() {
        this.changes.show({
            SubjectType: ['HeatFlapsAlertProfile'],
            SubjectId: this.item.Id
        });
    }

    switchTab(tab) {
        location.hash = tab;
        this.tab = tab;
    }

    equipmentTypesNotEnabled() {
        return this.equipmentTypes.filter(r => !this.item.HeatFlapsAlertProfileEquipmentTypes.some(x => x.EquipmentTypeId === r.Id));
    }

    addHeatFlapsAlertProfileEquipmentType(token, equipmentType) {
        token.HeatFlapsAlertProfileEquipmentTypes = token.HeatFlapsAlertProfileEquipmentTypes || [];
        token.HeatFlapsAlertProfileEquipmentTypes.push({
            EquipmentTypeId: equipmentType.Id,
            Name: ((equipmentType.IATACode + '/' + (equipmentType.ICAOCode || 'N/A')) + ' ' + equipmentType.ManufacturerAndModelDescription)
        });
        this.ngForm.form.markAsDirty();
    };

    removeHeatFlapsAlertProfileEquipmentType(token, heatFlapsAlertProfileEquipmentType) {
        token.HeatFlapsAlertProfileEquipmentTypes.splice(token.HeatFlapsAlertProfileEquipmentTypes.indexOf(heatFlapsAlertProfileEquipmentType), 1);
        this.ngForm.form.markAsDirty();
    };
}
