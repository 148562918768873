<swx-dialog cdkDrag>
    <swx-dialog-header cdkDragHandle>{{title}}</swx-dialog-header>
    <swx-dialog-content>
        <div class="options contentMargins">
            <form (ngSubmit)="submitForm()" novalidate>
                <div class="ui-form-container">
                    <h3>{{'Assignment Groups'|translate}}</h3>
                    <div *ngFor="let data of groups; let i=index">
                        <label>
                            <input type="checkbox" [value]="data.SmartGroupID" [name]="data.Name"
                                   [(ngModel)]="form.smartGroups[data.SmartGroupID]" />
                            {{data.Name}}
                        </label>
                    </div>
                    <h3>{{'App Delivery Method'|translate}}</h3>
                    <label class="ui-radio-label">
                        <input type="radio" name="pushMode" value="auto" [(ngModel)]="form.pushMode" checked>
                        Auto
                    </label>
                    <label class="ui-radio-label">
                        <input type="radio" name="pushMode" value="on demand" [(ngModel)]="form.pushMode">
                        On Demand
                    </label>
                </div>
                <div class="ui-dialog-buttonpane ui-widget-content ui-helper-clearfix">
                    <div class="ui-dialog-buttonset">
                        <label class="inline" *ngIf="numGroupsSelected() === 0">
                            {{'Please select at least one group.'|translate}}
                        </label>
                        <label class="inline">
                            <button type="submit" [disabled]="numGroupsSelected() === 0"><span class="mdi mdi-upload"></span>{{'Upload'|translate}}</button>
                        </label>
                    </div>
                </div>
            </form>
        </div>
    </swx-dialog-content>
</swx-dialog>

