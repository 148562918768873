<ng-container *ngIf="stationInfo?.stationState.WeatherProfile?.WeatherType === 'CLR'">
    <span class="weatherIcon" style="background-image: url('/images/MAm-WeatherIcons-MS02e/wsymbol_0001_sunny.svg');"></span>
</ng-container>
<ng-container *ngIf="stationInfo?.stationState.WeatherProfile?.WeatherType && stationInfo.stationState.WeatherProfile.WeatherType !== 'CLR'" [ngSwitch]="stationInfo.stationState.WeatherProfile.WeatherCategory">
    <span class="weatherIcon withPrecip" style="background-image: url('/images/MAm-WeatherIcons-MS02e/wsymbol_0050_freezing_rain.svg');" title="{{stationInfo.stationState.WeatherProfile.WeatherCategory}} {{stationInfo.stationState.WeatherProfile.LWE|number:'1.0-0'}} g/dm²/h" *ngSwitchCase="'SLD'"></span>
    <span class="weatherIcon withPrecip" style="background-image: url('/images/MAm-WeatherIcons-MS02e/wsymbol_0050_freezing_rain.svg');" title="{{stationInfo.stationState.WeatherProfile.WeatherCategory}} {{stationInfo.stationState.WeatherProfile.LWE|number:'1.0-0'}} g/dm²/h" *ngSwitchCase="'FZDZ'"></span>
    <span class="weatherIcon withPrecip" style="background-image: url('/images/MAm-WeatherIcons-MS02e/wsymbol_0050_freezing_rain.svg');" title="{{stationInfo.stationState.WeatherProfile.WeatherCategory}} {{stationInfo.stationState.WeatherProfile.LWE|number:'1.0-0'}} g/dm²/h" *ngSwitchCase="'FZRA'"></span>
    <span class="weatherIcon withPrecip" style="background-image: url('/images/MAm-WeatherIcons-MS02e/wsymbol_0020_cloudy_with_heavy_snow.svg');" title="{{stationInfo.stationState.WeatherProfile.WeatherCategory}} {{stationInfo.stationState.WeatherProfile.LWE|number:'1.0-0'}} g/dm²/h" *ngSwitchCase="'SN'"></span>
    <span class="weatherIcon withPrecip" style="background-image: url('/images/MAm-WeatherIcons-MS02e/wsymbol_0017_cloudy_with_light_rain.svg');" title="{{stationInfo.stationState.WeatherProfile.WeatherCategory}} {{stationInfo.stationState.WeatherProfile.LWE|number:'1.0-0'}} g/dm²/h" *ngSwitchCase="'RA'"></span>
    <span class="weatherIcon withPrecip" style="background-image: url('/images/MAm-WeatherIcons-MS02e/wsymbol_0022_cloudy_with_light_hail.svg');" title="{{stationInfo.stationState.WeatherProfile.WeatherCategory}} {{stationInfo.stationState.WeatherProfile.LWE|number:'1.0-0'}} g/dm²/h" *ngSwitchCase="'PL'"></span>
    <span class="weatherIcon withPrecip" style="background-image: url('/images/MAm-WeatherIcons-MS02e/wsymbol_0022_cloudy_with_light_hail.svg');" title="{{stationInfo.stationState.WeatherProfile.WeatherCategory}} {{stationInfo.stationState.WeatherProfile.LWE|number:'1.0-0'}} g/dm²/h" *ngSwitchCase="'GR'"></span>
    <span class="weatherIcon withPrecip" style="background-image: url('/images/MAm-WeatherIcons-MS02e/wsymbol_0047_freezing_fog.svg');" title="{{stationInfo.stationState.WeatherProfile.WeatherCategory}} {{stationInfo.stationState.WeatherProfile.LWE|number:'1.0-0'}} g/dm²/h" *ngSwitchCase="'FZFG'"></span>
    <span class="weatherIcon withPrecip" style="background-image: url('/images/MAm-WeatherIcons-MS02e/wsymbol_0047_freezing_fog.svg');" title="{{stationInfo.stationState.WeatherProfile.WeatherCategory}} {{stationInfo.stationState.WeatherProfile.LWE|number:'1.0-0'}} g/dm²/h" *ngSwitchCase="'FZBR'"></span>
    <span class="weatherIcon withPrecip" style="background-image: url('/images/MAm-WeatherIcons-MS02e/wsymbol_0007_fog.svg');" title="{{stationInfo.stationState.WeatherProfile.WeatherCategory}} {{stationInfo.stationState.WeatherProfile.LWE|number:'1.0-0'}} g/dm²/h" *ngSwitchCase="'FG'"></span>
    <span class="weatherIcon withPrecip" style="background-image: url('/images/MAm-WeatherIcons-MS02e/wsymbol_0007_fog.svg');" title="{{stationInfo.stationState.WeatherProfile.WeatherCategory}} {{stationInfo.stationState.WeatherProfile.LWE|number:'1.0-0'}} g/dm²/h" *ngSwitchCase="'BR'"></span>
    <span *ngSwitchDefault>
        {{stationInfo?.stationState.WeatherTypeCode}}
    </span>
</ng-container>
