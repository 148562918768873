import {ICellRendererAngularComp} from "ag-grid-angular";
import {ICellRendererParams} from "ag-grid-community";
import {Component, ViewContainerRef} from "@angular/core";
import {AcarsPreviewDialogComponent} from "./AcarsPreviewDialog.component";
import {RawDataDialogComponent} from "./RawDataDialog.component";
import { NgIf } from "@angular/common";
import {Dialog} from "@angular/cdk/dialog";

@Component({
    template: `
     <div>
         <a *ngIf="hotRequestType.indexOf('ACARS') === -1" (click)='showRawData()'>{{hotRequestType}}</a> 
         <span *ngIf="rawAcarsResponse">
             {{(hotRequestType.indexOf('ACARS') === -1 ? ' + ' : '')}}
             <a (click)='showAcars()'> 
                 {{(hotRequestType.indexOf('ACARS') === -1 ? 'ACARS copy' : hotRequestType)}} 
             </a>
         </span> 
     </div>
    `,
    standalone: true,
    imports: [NgIf]
})
export class RawDataLinkCellRenderer implements ICellRendererAngularComp {
    data: any;
    hotRequestType: string;
    rawAcarsResponse: string;
    
    constructor(private dialog: Dialog,
                ) {
    }
    
    agInit(params: ICellRendererParams): void {
        this.data = params.data;
        this.hotRequestType = params.data?.HotRequest?.Type || '';
        this.rawAcarsResponse = params.data?.RawAcarsResponse || '';
    }

    refresh(params: ICellRendererParams): boolean {
        return false;
    }
    
    showAcars() {
        this.dialog.open(AcarsPreviewDialogComponent, {
            data: {
                hot: this.data,
                title: this.hotRequestType,
            },
            width: '530px',
            height: '540px',
        })
    }
    
    showRawData() {
        if (this.hotRequestType.indexOf('API') !== -1 && this.data?.HotResponse?.RawData && this.data.HotResponse.RawData.substring(0, 1) !== '<') {
            this.data.HotResponse.RawData = JSON.stringify(JSON.parse(this.data.HotResponse.RawData), null, 4);
        }
        
        this.dialog.open(RawDataDialogComponent, {
            data: {
                hot: this.data,
                title: this.hotRequestType,
            },
            width: '530px',
            height: '540px',
        })
    }
}
