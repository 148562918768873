import {Component, OnInit, ViewChild, ViewContainerRef} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {ApiService} from "../shared/Api.service";
import { ChangesService, SwxModule } from "swx.front-end-lib";
import {TicketTrackingSystemService} from "../shared/TicketTrackingSystem.service";
import { HasPermissionService, HasPermissionPipe } from "../shared/HasPermission.pipe";
import { SFTPServerServiceProviderNotEnabledPipe } from "./SFTPServerServiceProviderNotEnabled.pipe";
import { NgIf, NgFor } from "@angular/common";
import { FormsModule } from "@angular/forms";
@Component({
    templateUrl: './SFTPServerConfigurationEdit.component.html',
    standalone: true,
    imports: [FormsModule, SwxModule, NgIf, NgFor, HasPermissionPipe, SFTPServerServiceProviderNotEnabledPipe]
})
export class SFTPServerConfigurationEditComponent implements OnInit {
    item;
    returnPath;
    @ViewChild('ngForm', { static: true }) ngForm;
    tab;
    configurationTypes = this.api.SFTPServerConfigurationType.query();
    serviceProvider;
    serviceProviders;
    clients = this.api.Client.query();

    ngOnInit() {
        this.api.DeicingServiceProvider.query().$promise.then(providers => {
            this.serviceProviders = providers;
        })
    }

    constructor(
        private router: Router,
        private viewContainerRef: ViewContainerRef,
        private route: ActivatedRoute,
        private api: ApiService,
        private changes: ChangesService,
        private ticketTrackingSystem: TicketTrackingSystemService,
        public hasPermissionService: HasPermissionService,
    ) {
        this.returnPath = this.route.snapshot.url[0].path.replace('/:id', '');
        const id = this.route.snapshot.params['id'];
        const copyId = this.route.snapshot.queryParams['copy'];
        const isNew = id === 'new';

        if (copyId) {
            this.item = this.api.SFTPServerConfiguration.get({ id: copyId });
            this.item.$promise.then(() => {
                delete this.item.Id;
            });
        } else if (isNew) {
            this.item = this.api.SFTPServerConfiguration.create();
        } else {
            this.item = this.api.SFTPServerConfiguration.get({ id: id });
        }

        this.tab = location.hash ? location.hash.substring(1) : 'basicInfo';
    }

    save() {
        this.ticketTrackingSystem.trackAndSave(this.item, this.returnPath);
    }

    cancel() {
        this.router.navigateByUrl(this.returnPath);
    }

    viewHistory() {
        this.changes.show({
            SubjectType: 'SFTPServerConfiguration',
            SubjectId: this.item.Id
        });
    };

    switchTab(tab) {
        location.hash = tab;
        this.tab = tab;
    };

    addServiceProvider() {
        this.item.SFTPServerConfigurationDeicingServiceProviders = this.item.SFTPServerConfigurationDeicingServiceProviders || [];
        this.item.SFTPServerConfigurationDeicingServiceProviders.push({
            DeicingServiceProviderId: this.serviceProvider.Id,
        });
        this.serviceProvider = null;
        this.ngForm.setDirty();
    };

    removeServiceProvider(sftpServerConfigurationDeicingServiceProvider) {
        this.item.SFTPServerConfigurationDeicingServiceProviders.splice(this.item.SFTPServerConfigurationDeicingServiceProviders.indexOf(sftpServerConfigurationDeicingServiceProvider), 1);
        this.ngForm.setDirty();
    };
}
