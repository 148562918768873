import {Component, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import moment from 'moment';
import { RootScope } from '../shared/RootScope.service';
import { ApiService } from '../shared/Api.service';
import { IResourceItem, IResourceItemList, GridComponent, TranslateService, ConfirmService, SwxModule, GridModule } from 'swx.front-end-lib';
import { TicketTrackingSystemService } from '../shared/TicketTrackingSystem.service';
import { HasPermissionService, HasPermissionPipe } from '../shared/HasPermission.pipe';
import { NgIf } from '@angular/common';
import { FormsModule } from '@angular/forms';

@Component({
    templateUrl: 'StationList.component.html',
    standalone: true,
    imports: [
        SwxModule,
        FormsModule,
        GridModule,
        NgIf,
        RouterLink,
        HasPermissionPipe,
    ],
})
export class StationListComponent implements OnInit {
    items: IResourceItemList<any>;
    searchQuery;
    query: any;
    includeInactive = false;
    @ViewChild('grid', { static: true }) grid: GridComponent;
    stationTypes = this.api.StationType.query();
    stationLoggerTypes = this.api.StationLoggerType.query();
    columnDefs = [
        {
            headerName: 'Station',
            children: [
                { colId: "Id", field: "Id", headerName: "#", width: 60, pinned: "left", filterType: "integer" },
                { colId: "Name", field: "Name", headerName: "Name", width: 200, pinned: "left", cellRenderer: c => {
                    if (c.data == null) return '';
                    var container = document.createElement('div');
                    container.innerHTML = '<a href="/stations/' + c.data.Id + '" onclick="event.preventDefault();">' + c.value + '</a>';
                    container.querySelector('a').addEventListener('click', e => this.router.navigateByUrl(e.target['getAttribute']('href')));
                    return container;
                } },
                { colId: "StationType", field: "StationType", headerName: "Type", width: 100, pinned: "left", valueFormatter: c => this.stationTypes[c.value], filterType: 'enum', source: 'StationType' },
                { colId: "UnderMaintenance", field: "UnderMaintenance", headerName: "Under maintenance?", width: 110, pinned: "left", filterType: "boolean", valueFormatter: c => c.value == null ? '' : c.value ? 'Yes' : 'No' },
                { colId: "ShowOnStationStatus", field: "ShowOnStationStatus", headerName: "Show on station status?", width: 110, pinned: "left", filterType: "boolean", valueFormatter: c => c.value == null ? '' : c.value ? 'Yes' : 'No' },
                { colId: "Active", field: "Active", headerName: "Active?", width: 70, filterType: "boolean", valueFormatter: c => c.value == null ? '' : c.value ? 'Yes' : 'No' },
                { colId: "AirportName", field: "AirportName", headerName: "Airport", width: 300 },
                { colId: "MessageSent", field: "MessageSent", headerName: "Message sent?", width: 100, filterType: "boolean", valueFormatter: c => c.value == null ? '' : c.value ? 'Yes' : 'No' },
                { colId: "StationProblemConfigurationProfileName", field: "StationProblemConfigurationProfileName", headerName: "Station alert profile", width: 300 },
                { colId: "Connections", field: "Connections", headerName: "Connections", width: 300 },
                { colId: "ForwardStationReadingsToMqttTopic", field: "ForwardStationReadingsToMqttTopic", headerName: "Forward MQTT topic", width: 180 },
                { colId: "StationLoggerType", field: "StationLoggerType", headerName: "Logger type", width: 110, valueFormatter: c => this.stationLoggerTypes[c.value], filterType: 'enum', source: 'StationLoggerType' },
                { colId: "Clients", field: "Clients", headerName: "Clients", width: 200 },
                { colId: "CreatedDate", field: "CreatedDate", headerName: "Created date", width: 120, valueFormatter: c => c.value == null ? '' : moment.utc(c.value).format(this.$root.dateTimeFormat), filterType: "date" },
                { colId: "CreatedByUserName", field: "CreatedByUserName", headerName: "Created by", width: 120 },
                { colId: "LastModified", field: "LastModified", headerName: "Modified date", width: 120, valueFormatter: c => c.value == null ? '' : moment.utc(c.value).format(this.$root.dateTimeFormat), filterType: "date" },
                { colId: "LastModifiedByUserName", field: "LastModifiedByUserName", headerName: "Modified by", width: 120 },
            ]
        },
        {
            headerName: 'Sensors',
            children: [
                { colId: "HasThiesLPM", field: "HasThiesLPM", headerName: "Thies LPM", width: 60, filterType: "boolean", valueFormatter: c => c.value == null ? '' : c.value ? '✓' : '' },
                { colId: "HasCampbellPWS100", field: "HasCampbellPWS100", headerName: "Campbell PWS100", width: 70, filterType: "boolean", valueFormatter: c => c.value == null ? '' : c.value ? '✓' : '' },
                { colId: "HasVaisalaFD71P", field: "HasVaisalaFD71P", headerName: "Vaisala FD71P", width: 70, filterType: "boolean", valueFormatter: c => c.value == null ? '' : c.value ? '✓' : '' },
                { colId: "HasOTTParsivel2", field: "HasOTTParsivel2", headerName: "OTT Parsivel2", width: 70, filterType: "boolean", valueFormatter: c => c.value == null ? '' : c.value ? '✓' : '' },
                { colId: "HasOTTPluvio2", field: "HasOTTPluvio2", headerName: "OTT Pluvio2", width: 70, filterType: "boolean", valueFormatter: c => c.value == null ? '' : c.value ? '✓' : '' },
                { colId: "HasOTTPluvio2DFAR", field: "HasOTTPluvio2DFAR", headerName: "OTT Pluvio2 DFAR", width: 70, filterType: "boolean", valueFormatter: c => c.value == null ? '' : c.value ? '✓' : '' },
                { colId: "HasVaisalaFD12P", field: "HasVaisalaFD12P", headerName: "Vaisala FD12P/PWD22", width: 110, filterType: "boolean", valueFormatter: c => c.value == null ? '' : c.value ? '✓' : '' },
                { colId: "HasPondHotPlate", field: "HasPondHotPlate", headerName: "Pond Hot Plate", width: 80, filterType: "boolean", valueFormatter: c => c.value == null ? '' : c.value ? '✓' : '' },
                { colId: "HasMiniOFS", field: "HasMiniOFS", headerName: "MiniOFS", width: 70, filterType: "boolean", valueFormatter: c => c.value == null ? '' : c.value ? '✓' : '' },
                { colId: "HasBiralSWS200", field: "HasBiralSWS200", headerName: "Biral SWS200", width: 70, filterType: "boolean", valueFormatter: c => c.value == null ? '' : c.value ? '✓' : '' },
                { colId: "HasThies2DCompact", field: "HasThies2DCompact", headerName: "Thies 2D Compact", width: 70, filterType: "boolean", valueFormatter: c => c.value == null ? '' : c.value ? '✓' : '' },
                { colId: "HasThiesWP", field: "HasThiesWP", headerName: "Thies WP", width: 70, filterType: "boolean", valueFormatter: c => c.value == null ? '' : c.value ? '✓' : '' },
                { colId: "HasVaisalaWS425", field: "HasVaisalaWS425", headerName: "Vaisala WS425", width: 70, filterType: "boolean", valueFormatter: c => c.value == null ? '' : c.value ? '✓' : '' },
                { colId: "HasVaisalaWMT700", field: "HasVaisalaWMT700", headerName: "Vaisala WMT700", width: 70, filterType: "boolean", valueFormatter: c => c.value == null ? '' : c.value ? '✓' : '' },
                { colId: "HasActiveFrostTemp", field: "HasActiveFrostTemp", headerName: "Active frost", width: 60, filterType: "boolean", valueFormatter: c => c.value == null ? '' : c.value ? '✓' : '' },
                { colId: "HasDigitalTemp", field: "HasDigitalTemp", headerName: "Digital temp", width: 70, filterType: "boolean", valueFormatter: c => c.value == null ? '' : c.value ? '✓' : '' },
                { colId: "HasThiesFZRA", field: "HasThiesFZRA", headerName: "Thies FZRA", width: 60, filterType: "boolean", valueFormatter: c => c.value == null ? '' : c.value ? '✓' : '' },
            ]
        },
        {
            headerName: '',
            children: [
                { colId: "_spacer", "flex": 1, excelIgnore: true },
                { colId: "Actions", field: "Id", headerName: "", width: 80, cellClass: "actions", cellRenderer: c => {
                    if (c.data == null) return '';
                    var container = document.createElement('div');
                    container.innerHTML = '<a href="/stations/' + c.data.Id + '" class="mdi mdi-pencil" title="' + this.translateService.translate('Edit') + '" onclick="event.preventDefault();"></a>'
                        + (this.hasPermission.hasPermission('ConfigEditStations') ? '<a class="mdi mdi-delete" title="' + this.translateService.translate('Delete') + '" onclick="event.preventDefault();"></a>' : '')
                        + (this.hasPermission.hasPermission('ConfigEditStations') ? '<a href="/stations/new?copy=' + c.data.Id + '" class="mdi mdi-content-copy" title="' + this.translateService.translate('Copy') + '" onclick="event.preventDefault();"></a>' : '');
                    Array.from(container.querySelectorAll('a[href]')).forEach(n => n.addEventListener('click', e => this.router.navigateByUrl(e.target['getAttribute']('href'))));
                    Array.from(container.querySelectorAll('.mdi-delete')).forEach(n => n.addEventListener('click', () => this.delete(c.data)));
                    return container;
                }, pinned: "right", "searchable": false, excelIgnore: true },
            ]
        }
    ];

    constructor(
        private router: Router,
        private viewContainerRef: ViewContainerRef,
        private $root: RootScope,
        public api: ApiService,
        private translateService: TranslateService,
        private ticketTrackingSystem: TicketTrackingSystemService,
        private confirmService: ConfirmService,
        private hasPermission: HasPermissionService,
    ) {
    }

    ngOnInit() {
        if (window.localStorage['stationQuery']) {
            this.query = JSON.parse(window.localStorage['stationQuery']);
            this.refresh();
        } else {
            this.resetQuery();
        }
    }

    resetQuery() {
        this.searchQuery = '';
        this.query = {
            Filters: []
        };
        this.refresh();
    };

    refresh() {
        window.localStorage['stationQuery'] = JSON.stringify(this.query);
        this.grid.refresh();
    }
    
    delete(item: IResourceItem) {
        this.confirmService.confirm(this.translateService.translate('Are you sure?')).then(() => {
            this.ticketTrackingSystem.trackAndDelete(item, () => this.refresh());
        }).catch(() => {});
    }
}
