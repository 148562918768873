import {Component, ViewContainerRef} from "@angular/core";
import {ICellRendererAngularComp} from "ag-grid-angular";
import {ICellRendererParams} from "ag-grid-community";
import {WeatherOverrideDeicingReportsDialogComponent} from "./WeatherOverrideDeicingReportsDialog.component";
import {ApiService} from "../shared/Api.service";
import { NgIf } from "@angular/common";
import {Dialog} from "@angular/cdk/dialog";

@Component({
    template: `<a  *ngIf='data?.HasWeatherOverrideDeicingReports' (click)='showWeatherOverrideDeicingReports()' class='mdi mdi-eye'></a>`,
    standalone: true,
    imports: [NgIf]
})
export class WeatherOverrideDeicingReportCellRenderer implements ICellRendererAngularComp {
    data: any;
    
    constructor(private dialog: Dialog,
                private api: ApiService) {
    }

    agInit(params: ICellRendererParams): void {
        this.data = params.data;
    }

    refresh(params: ICellRendererParams): boolean {
        return false;
    }
    
    showWeatherOverrideDeicingReports() {
        Promise.all([
            this.api.DeicingReport.query({
                WeatherOverride: true,
                HotResponseId: this.data.HotResponse.Id,
            }).$promise,
            this.api.MetarWeatherType.query()
        ]).then(([deicingReports, metarWeatherTypes]) => {
            this.dialog.open(WeatherOverrideDeicingReportsDialogComponent, {
                data: {
                    deicingReports: deicingReports,
                    metarWeatherTypes: metarWeatherTypes,
                },
                width: '700px',
                height: 'auto',
            })
        })
    }
}
