import {Component, ViewChild, ViewContainerRef, ViewEncapsulation} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from '../shared/Api.service';
import { ChangesService, SwxModule } from 'swx.front-end-lib';
import { TicketTrackingSystemService } from '../shared/TicketTrackingSystem.service';
import { HasPermissionService, HasPermissionPipe } from '../shared/HasPermission.pipe';
import {OrderByPipe, Base64UploadAdapterPlugin} from "swx.front-end-lib";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {StationMonitoringConditionListComponent} from "../stationMonitoringProfile/StationMonitoringConditionList.component";
import { AirportsNotEnabledPipe } from '../client/AirportsNotEnabled.pipe';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { WeatherAlertConditionList } from './WeatherAlertConditionList.component';
import { NgIf, NgFor } from '@angular/common';
import { FormsModule } from '@angular/forms';

@Component({
    templateUrl: 'WeatherAlertProfileDetail.component.html',
    styleUrls: ['WeatherAlertProfileDetail.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [
        SwxModule,
        FormsModule,
        NgIf,
        NgFor,
        WeatherAlertConditionList,
        CKEditorModule,
        AirportsNotEnabledPipe,
        HasPermissionPipe,
    ],
})
export class WeatherAlertProfileDetailComponent {
    item: any;
    returnPath;
    @ViewChild('ngForm', { static: true }) ngForm;
    tab;
    clients = this.api.Client.query();
    airports = this.api.Airport.query();
    airport;
    emailFroms = this.api.EmailFrom.query();
    Editor = ClassicEditor;
    ckConfig = {
        extraPlugins: [ Base64UploadAdapterPlugin ],
        toolbar: { items: [ 'bold', 'italic','|', 'link', 'imageUpload', 'heading', 'insertTable', 'bulletedList', 'numberedList', 'outdent', 'indent', '|', 'undo', 'redo' ]}
    };
    private initPromise: Promise<any>;
    private airportsDict: any;
    
    constructor(
        private router: Router,
        private viewContainerRef: ViewContainerRef,
        private route: ActivatedRoute,
        private api: ApiService,
        private changes: ChangesService,
        private ticketTrackingSystem: TicketTrackingSystemService,
        public hasPermissionService: HasPermissionService,
        public orderByPipe: OrderByPipe,
    ) {
        this.tab = location.hash ? location.hash.substring(1) : 'basicInfo';
        
        this.returnPath = this.route.snapshot.url[0].path.replace('/:id', '');
        const id = this.route.snapshot.params['id'];
        const copyId = this.route.snapshot.queryParams['copy'];
        const isNew = id === 'new';

        if (copyId) {
            this.item = this.api.WeatherAlertProfile.get({ id: copyId });
            this.item.$promise.then(() => {
                delete this.item.Id;

                if (this.item.WeatherAlertLevels) {
                    this.item.WeatherAlertLevels.forEach(related => {
                        delete related.WeatherAlertProfileId;

                        var newId = StationMonitoringConditionListComponent.uuidv4();
                        
                        related.WeatherAlertEnteringConditions.forEach(child => {
                            child.Id = StationMonitoringConditionListComponent.uuidv4();
                            
                            if (child.ParentConditionId == related.Id) {
                                child.ParentConditionId = newId;
                            }
                        });

                        related.WeatherAlertExitingConditions.forEach(child => {
                            child.Id = StationMonitoringConditionListComponent.uuidv4();

                            if (child.ParentConditionId == related.Id) {
                                child.ParentConditionId = newId;
                            }
                        });

                        related.Id = newId;
                    });
                }

                if (this.item.WeatherAlertAirports) {
                    this.item.WeatherAlertAirports.forEach(related => {
                        delete related.WeatherAlertProfileId;
                    });
                }
            });
        } else if (isNew) {
            this.item = this.api.WeatherAlertProfile.create({
                Active: true,
            });
        } else {
            this.item = this.api.WeatherAlertProfile.get({ id: id });
        }

        this.initPromise = Promise.all([this.item.$promise, this.airports.$promise]).then(() => {
            this.airportsDict = {};
            this.airports.forEach(airport => {
                this.airportsDict[airport.Id] = airport;
            });
            
            return this.item.$promise.then(_ => {
                this.item.WeatherAlertLevels = this.item.WeatherAlertLevels || [];
                this.item.WeatherAlertLevels = this.orderByPipe.transform(this.item.WeatherAlertLevels, 'Order');
                
                this.item.WeatherAlertLevels.forEach(level => {
                    level.WeatherAlertEnteringConditions = level.WeatherAlertEnteringConditions || [];
                    level.WeatherAlertEnteringConditions = this.orderByPipe.transform(level.WeatherAlertEnteringConditions, 'Order');
                    level.WeatherAlertExitingConditions = level.WeatherAlertExitingConditions || [];
                    level.WeatherAlertExitingConditions = this.orderByPipe.transform(level.WeatherAlertExitingConditions, 'Order');
                });

                this.item.WeatherAlertAirports = this.item.WeatherAlertAirports || [];

                this.item.WeatherAlertAirports.sort((a, b) => {
                    const airportA = this.airports.find(r => r.Id === a.AirportId);
                    const airportB = this.airports.find(r => r.Id === b.AirportId);
                    if (airportA && airportB && airportA.ICAOCode < airportB.ICAOCode) return -1;
                    if (airportA && airportB && airportA.ICAOCode > airportB.ICAOCode) return 1;
                    return 0;
                });
                
                this.switchTab(this.tab);
            });
        });
    }
    
    save() {
        this.ticketTrackingSystem.trackAndSave(this.item, this.returnPath);
    }

    cancel() {
        this.router.navigateByUrl(this.returnPath);
    }

    viewHistory() {
        this.changes.show({
            SubjectType: ['WeatherAlertProfile'],
            SubjectId: this.item.Id
        });
    };

    switchTab(tab) {
        location.hash = tab;
        this.tab = tab;
    };

    addLevel() {
        this.item.WeatherAlertLevels = this.item.WeatherAlertLevels || [];
        var maxOrder = Math.max.apply(null, this.item.WeatherAlertLevels
            .map(h => h.Order));
        this.item.WeatherAlertLevels.push({
            Order: Math.max(1, maxOrder + 1),
        });
        this.ngForm.form.markAsDirty();
    }

    removeLevel(level) {
        this.item.WeatherAlertLevels.splice(this.item.WeatherAlertLevels.indexOf(level), 1);
        this.ngForm.form.markAsDirty();
    }

    addWeatherAlertAirport(airport) {
        this.item.WeatherAlertAirports = this.item.WeatherAlertAirports || [];
        this.item.WeatherAlertAirports.push({
            AirportId: airport.Id,
        });
        this.ngForm.form.markAsDirty();
    }

    removeWeatherAlertAirport(weatherAlertAirport) {
        this.item.WeatherAlertAirports.splice(this.item.WeatherAlertAirports.indexOf(weatherAlertAirport), 1);
        this.ngForm.form.markAsDirty();
    }
}
