import {Component, ViewChild, ViewContainerRef} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from '../shared/Api.service';
import { CellRendererFactories, ChangesService, ConfigGridComponent, SwxModule, GridModule } from 'swx.front-end-lib';
import { TicketTrackingSystemService } from '../shared/TicketTrackingSystem.service';
import { HasPermissionService, HasPermissionPipe } from '../shared/HasPermission.pipe';
import { NgIf, NgClass } from '@angular/common';
import { FormsModule } from '@angular/forms';

@Component({
    templateUrl: 'ThiesLPMWMOCodeMappingProfileDetail.component.html',
    standalone: true,
    imports: [
        SwxModule,
        FormsModule,
        NgIf,
        NgClass,
        GridModule,
        HasPermissionPipe,
    ],
})
export class ThiesLPMWMOCodeMappingProfileDetailComponent {
    item: any;
    returnPath;
    @ViewChild('ngForm', { static: true }) ngForm;
    tab;
    @ViewChild('mappingsGrid', { static: true }) mappingsGrid: ConfigGridComponent;
    columnDefs = [
        {
            headerName: "",
            children: [
                {
                    colId: `OriginalWMOCode`,
                    field: `OriginalWMOCode`,
                    headerName: "Original WMO code",
                    width: 100,
                    cellRenderer: params => this.cellRenderers.input('number', {
                        required: true,
                        value: params.value,
                        min: '0',
                        max: '100',
                        step: '1',
                        onchange: e => {
                            this.ngForm.form.updateValueAndValidity();
                            this.ngForm.form.markAsDirty();
                            params.data.OriginalWMOCode = (<HTMLInputElement>e.target).value;
                        },
                    }),
                },
                {
                    colId: `MaxIntensity`,
                    field: `MaxIntensity`,
                    headerName: "Max. intensity (mm/h)",
                    width: 100,
                    cellRenderer: params => this.cellRenderers.input('number', {
                        value: params.value,
                        min: '0',
                        step: 'any',
                        onchange: e => {
                            this.ngForm.form.updateValueAndValidity();
                            this.ngForm.form.markAsDirty();
                            params.data.MaxIntensity = (<HTMLInputElement>e.target).value;
                        },
                    }),
                },
                {
                    colId: `MaxMeasuringQuality`,
                    field: `MaxMeasuringQuality`,
                    headerName: "Max. measuring quality (%)",
                    width: 100,
                    cellRenderer: params => this.cellRenderers.input('number', {
                        required: true,
                        value: params.value,
                        min: '0',
                        max: '100',
                        step: 'any',
                        onchange: e => {
                            this.ngForm.form.updateValueAndValidity();
                            this.ngForm.form.markAsDirty();
                            params.data.MaxMeasuringQuality = (<HTMLInputElement>e.target).value;
                        },
                    }),
                },
                {
                    colId: `ResultingWMOCode`,
                    field: `ResultingWMOCode`,
                    headerName: "Resulting WMO code",
                    width: 100,
                    cellRenderer: params => this.cellRenderers.input('number', {
                        required: true,
                        value: params.value,
                        min: '0',
                        max: '100',
                        step: '1',
                        onchange: e => {
                            this.ngForm.form.updateValueAndValidity();
                            this.ngForm.form.markAsDirty();
                            params.data.ResultingWMOCode = (<HTMLInputElement>e.target).value;
                        },
                    }),
                },
                {
                    colId: "_spacer",
                    flex: 1,
                    excelIgnore: true,
                },
                {
                    colId: "Actions",
                    field: "Id",
                    headerName: "",
                    width: 80,
                    pinned: 'right',
                    excelIgnore: true,
                    cellTemplate: `<a *ngIf="parent.hasPermissionService.hasPermission('ConfigEditThiesLPMWMOCodeMappingProfiles')" (click)="parent.removeMapping(params.data)" class="mdi mdi-delete" title="{{'Delete'|translate}}"></a>`,
                },
            ],
        },
    ];
    
    constructor(
        private router: Router,
        private viewContainerRef: ViewContainerRef,
        private route: ActivatedRoute,
        private api: ApiService,
        private changes: ChangesService,
        private ticketTrackingSystem: TicketTrackingSystemService,
        public hasPermissionService: HasPermissionService,
        private cellRenderers: CellRendererFactories,
    ) {
        this.tab = location.hash ? location.hash.substring(1) : 'basicInfo';
        
        this.returnPath = this.route.snapshot.url[0].path.replace('/:id', '');
        const id = this.route.snapshot.params['id'];
        const copyId = this.route.snapshot.queryParams['copy'];
        const isNew = id === 'new';

        if (copyId) {
            this.item = this.api.ThiesLPMWMOCodeMappingProfile.get({ id: copyId });
            this.item.$promise.then(() => {
                delete this.item.Id;

                if (this.item.ThiesLPMWMOCodeMappings) {
                    this.item.ThiesLPMWMOCodeMappings.forEach(related => {
                        delete related.RemoteEndpointId;
                    });
                }
            });
        } else if (isNew) {
            this.item = this.api.ThiesLPMWMOCodeMappingProfile.create({
                Active: true,
            });
        } else {
            this.item = this.api.ThiesLPMWMOCodeMappingProfile.get({ id: id });
        }
    }
    
    save() {
        this.ticketTrackingSystem.trackAndSave(this.item, this.returnPath);
    }

    cancel() {
        this.router.navigateByUrl(this.returnPath);
    }

    viewHistory() {
        this.changes.show({
            SubjectType: ['ThiesLPMWMOCodeMappingProfile'],
            SubjectId: this.item.Id
        });
    }

    switchTab(tab) {
        location.hash = tab;
        this.tab = tab;
    }

    addMapping() {
        this.item.ThiesLPMWMOCodeMappings = this.item.ThiesLPMWMOCodeMappings || [];
        this.item.ThiesLPMWMOCodeMappings.unshift({});
        this.mappingsGrid.gridApi.applyTransaction({ addIndex: 0, add: this.item.ThiesLPMWMOCodeMappings.slice(0, 1) })
        this.ngForm.form.updateValueAndValidity();
        this.ngForm.form.markAsDirty();
    }

    removeMapping(item) {
        this.item.ThiesLPMWMOCodeMappings.splice(this.item.ThiesLPMWMOCodeMappings.indexOf(item), 1);

        this.ngForm.form.updateValueAndValidity();
        this.ngForm.form.markAsDirty();

        this.mappingsGrid.updateFilteredRows();
    }
}
