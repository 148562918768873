import {Component, ViewChild} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from '../shared/Api.service';
import { ChangesService, SwxModule } from 'swx.front-end-lib';
import { TicketTrackingSystemService } from '../shared/TicketTrackingSystem.service';
import { FilterPipe } from 'swx.front-end-lib';
import { HasPermissionService, HasPermissionPipe } from '../shared/HasPermission.pipe';
import { NgIf, NgFor } from '@angular/common';
import { FormsModule } from '@angular/forms';

@Component({
    templateUrl: 'MobileUserDetail.component.html',
    standalone: true,
    imports: [FormsModule, SwxModule, NgIf, NgFor, HasPermissionPipe]
})
export class MobileUserDetailComponent {
    item;
    returnPath;
    RepeatPassword;
    @ViewChild('ngForm', { static: true }) ngForm;
    tab;
    clientApiTokens = this.api.ClientApiToken.query();
    clientApiToken;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private api: ApiService,
        private changes: ChangesService,
        private ticketTrackingSystem: TicketTrackingSystemService,
        public hasPermissionService: HasPermissionService,
    ) {
        this.returnPath = this.route.snapshot.url[0].path.replace('/:id', '');
        const id = this.route.snapshot.params['id'];
        const copyId = this.route.snapshot.queryParams['copy'];
        const isNew = id === 'new';

        if (copyId) {
            this.item = this.api.MobileUser.get({ id: copyId });
            this.item.$promise.then(() => {
                delete this.item.Id;
                delete this.item.Name;
                delete this.item.Email;
                delete this.item.Password;
                delete this.RepeatPassword;
            });
            this.RepeatPassword = '__PASSWORD__';
        } else if (isNew) {
            this.item = this.api.MobileUser.create({
                Active: true,
                AccessTokenExpireTimeSeconds: 604800,
            });
        } else {
            this.item = this.api.MobileUser.get({ id: id });
            this.RepeatPassword = '__PASSWORD__';
        }

        this.tab = location.hash ? location.hash.substring(1) : 'basicInfo';
    }

    filter = (items, searchQuery) => FilterPipe.instance.transform(items, searchQuery);

    save() {
        this.ticketTrackingSystem.trackAndSave(this.item, this.returnPath);
    }

    cancel() {
        this.router.navigateByUrl(this.returnPath);
    }

    viewHistory() {
        this.changes.show({
            SubjectType: ['MobileUser'],
            SubjectId: this.item.Id
        });
    }

    switchTab(tab) {
        location.hash = tab;
        this.tab = tab;
    }

    clientApiTokensNotEnabled(clientApiTokens, mobileUserClientApiTokens) {
        if (mobileUserClientApiTokens == null) return clientApiTokens;
        return clientApiTokens.filter(p => !mobileUserClientApiTokens.some(mp => mp.ClientApiTokenId === p.Id));
    }

    addMobileUserClientApiToken() {
        this.item.MobileUserClientApiTokens = this.item.MobileUserClientApiTokens || [];

        this.item.MobileUserClientApiTokens.push({
            ClientApiTokenId: this.clientApiToken.Id,
        });

        this.clientApiToken = null;
        this.ngForm.form.updateValueAndValidity();
        this.ngForm.form.markAsDirty();
    }

    removeMobileUserClientApiToken(mobileUserClientApiToken) {
        this.item.MobileUserClientApiTokens.splice(this.item.MobileUserClientApiTokens.indexOf(mobileUserClientApiToken), 1);
        this.ngForm.form.updateValueAndValidity();
        this.ngForm.form.markAsDirty();
    }
}
