import {Component, Inject, OnInit} from "@angular/core";
import {RootScope} from "../shared/RootScope.service";
import {DialogComponent, DialogContentComponent, DialogHeaderComponent, SwxModule} from "swx.front-end-lib";
import { NgFor } from "@angular/common";
import {DIALOG_DATA} from "@angular/cdk/dialog";
import {CdkDrag, CdkDragHandle} from "@angular/cdk/drag-drop";

@Component({
    templateUrl: 'WeatherOverrideDeicingReportsDialog.component.html',
    standalone: true,
    imports: [NgFor, SwxModule, DialogComponent, DialogHeaderComponent, DialogContentComponent, CdkDrag, CdkDragHandle]
})
export class WeatherOverrideDeicingReportsDialogComponent implements OnInit {
    dateTimeFormat = this.root.dateTimeFormat;
    metarWeatherTypes: any;
    deicingReports: any;
    
    constructor(
        @Inject(DIALOG_DATA) private data: { deicingReports: any, metarWeatherTypes: any},
        private root: RootScope,
    ) {
    }
    
    ngOnInit() {
        this.deicingReports = this.data.deicingReports;
        this.metarWeatherTypes = this.data.metarWeatherTypes;
    }
}
