import {Component, ViewChild, ViewContainerRef} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from '../shared/Api.service';
import { ChangesService, SwxModule } from 'swx.front-end-lib';
import { TicketTrackingSystemService } from '../shared/TicketTrackingSystem.service';
import { HasPermissionService, HasPermissionPipe } from '../shared/HasPermission.pipe';
import { FormsModule } from '@angular/forms';
import { NgIf, NgFor } from '@angular/common';

@Component({
    templateUrl: 'MobileWeatherMappingProfileDetail.component.html',
    standalone: true,
    imports: [SwxModule, NgIf, FormsModule, NgFor, HasPermissionPipe]
})
export class MobileWeatherMappingProfileDetailComponent {
    item: any;
    returnPath;
    @ViewChild('ngForm', { static: true }) ngForm;
    tab;
    metarWeatherTypes = this.api.MetarWeatherType.query();
    mobileSnowVisibilityMenus = {
        SNEXFAA20222023: 'SNEX FAA',
        SNEXFAA20222023DayAsNight: 'SNEX FAA (always daytime)',
        SNEXTC20222023: 'SNEX TC',
        SNEXFFFAA20222023: 'SNEXFF FAA',
        SNEXFFFAA20222023DayAsNight: 'SNEXFF FAA (always daytime)',
        SNEXFFTC20222023: 'SNEXFF TC',
        SNEXFRFAA20222023: 'SNEXFR FAA',
        SNEXFRFAA20222023DayAsNight: 'SNEXFR FAA (always daytime)',
        SNEXFRTC20222023: 'SNEXFR TC',
        SNEXFRRAFAA20252026: 'SNEXFR with RA FAA',
    }; //this.api.MobileSnowVisibilityMenu.query();
    
    constructor(
        private router: Router,
        private viewContainerRef: ViewContainerRef,
        private route: ActivatedRoute,
        private api: ApiService,
        private changes: ChangesService,
        private ticketTrackingSystem: TicketTrackingSystemService,
        public hasPermissionService: HasPermissionService,
    ) {
        this.tab = location.hash ? location.hash.substring(1) : 'basicInfo';
        
        this.returnPath = this.route.snapshot.url[0].path.replace('/:id', '');
        const id = this.route.snapshot.params['id'];
        const copyId = this.route.snapshot.queryParams['copy'];
        const isNew = id === 'new';

        if (copyId) {
            this.item = this.api.MobileWeatherMappingProfile.get({ id: copyId });
            this.item.$promise.then(() => {
                delete this.item.Id;

                if (this.item.MobileWeatherTypeMappings) {
                    this.item.MobileWeatherTypeMappings.forEach(related => {
                        delete related.MobileWeatherMappingProfileId;
                    });
                }
            });
        } else if (isNew) {
            this.item = this.api.MobileWeatherMappingProfile.create({
                Active: true,
            });
        } else {
            this.item = this.api.MobileWeatherMappingProfile.get({ id: id });
        }

        if (this.item.$promise) {
            this.item.$promise.then(_ => {
                this.item.MobileWeatherTypeMappings = (this.item.MobileWeatherTypeMappings || []).sort((r1, r2) => {
                    return r1.Order > r2.Order
                        ? 1
                        : -1;
                });

                this.item.MobileWeatherTypeMappings.forEach(mwtm => {
                    mwtm.MobileWeatherTypeSubMappings = (mwtm.MobileWeatherTypeSubMappings || []).sort((r1, r2) => {
                        return r1.Order > r2.Order
                            ? 1
                            : -1;
                    });
                });
            });
        }
    }
    
    save() {
        this.ticketTrackingSystem.trackAndSave(this.item, this.returnPath);
    }

    cancel() {
        this.router.navigateByUrl(this.returnPath);
    }

    viewHistory() {
        this.changes.show({
            SubjectType: ['MobileWeatherMappingProfile'],
            SubjectId: this.item.Id
        });
    };

    switchTab(tab) {
        location.hash = tab;
        this.tab = tab;
    };

    addMobileWeatherTypeMapping() {
        this.item.MobileWeatherTypeMappings = this.item.MobileWeatherTypeMappings || [];

        var maxOrder = Math.max.apply(null, this.item.MobileWeatherTypeMappings
            .map(h => h.Order));

        this.item.MobileWeatherTypeMappings.push({
            Order: Math.max(1, maxOrder + 1)
        });

        this.ngForm.form.markAsDirty();
    }

    removeMobileWeatherTypeMapping(mobileWeatherTypeMapping) {
        this.item.MobileWeatherTypeMappings.splice(this.item.MobileWeatherTypeMappings.indexOf(mobileWeatherTypeMapping), 1);
        this.ngForm.form.markAsDirty();
    }

    addMobileWeatherTypeSubMapping(mobileWeatherTypeMapping) {
        mobileWeatherTypeMapping.MobileWeatherTypeSubMappings = mobileWeatherTypeMapping.MobileWeatherTypeSubMappings || [];

        var maxOrder = Math.max.apply(null, mobileWeatherTypeMapping.MobileWeatherTypeSubMappings
            .map(h => h.Order));

        mobileWeatherTypeMapping.MobileWeatherTypeSubMappings.push({
            Order: Math.max(1, maxOrder + 1)
        });

        this.ngForm.form.markAsDirty();
    }

    removeMobileWeatherTypeSubMapping(mobileWeatherTypeMapping, mobileWeatherTypeSubMapping) {
        mobileWeatherTypeMapping.MobileWeatherTypeSubMappings.splice(mobileWeatherTypeMapping.MobileWeatherTypeSubMappings.indexOf(mobileWeatherTypeSubMapping), 1);
        this.ngForm.form.markAsDirty();
    }
}
