import {Component, ViewContainerRef} from "@angular/core";
import {ApiService} from "../shared/Api.service";
import {TicketTrackingSystemService} from "../shared/TicketTrackingSystem.service";
import { ApiFactoryService, ChangesService, Options, QueryParams, SwxModule } from "swx.front-end-lib";
import { HasPermissionPipe } from "../shared/HasPermission.pipe";
import { FormsModule } from "@angular/forms";
import { NgIf } from "@angular/common";

@Component({
    templateUrl: 'Sartorius.component.html',
    standalone: true,
    imports: [SwxModule, NgIf, FormsModule, HasPermissionPipe]
})
export class SartoriusComponent {
    item: any = this.api.SartoriusSetting.queryObject();
    
    constructor(private api: ApiService,
                private viewContainerRef: ViewContainerRef,
                private apiFactory: ApiFactoryService,
                private ticketTrackingSystem: TicketTrackingSystemService,
                private changes: ChangesService) {

        Object.defineProperty(this.item, "$save", {
            enumerable: false,
            writable: true,
            value: (options?: Options, params?: QueryParams): Promise<Response> => {
                if (options == null || !options.silent) this.apiFactory.busyIndicator.setBusy(true);

                return this.apiFactory.http.post(this.apiFactory.environment.apiUrl + '/Sartorius',
                    this.item,
                    {
                        headers: (options && options.anonymous) ? null : this.apiFactory.authentication.getHeaders(),
                        params: params as any
                    }).toPromise()
                    .then(response => {
                        if (options == null || !options.silent) this.apiFactory.busyIndicator.setBusy(false);
                        return response;
                    }) as any;
            },
        });
    }
    
    save() {
        this.ticketTrackingSystem.trackAndSave(this.item, 'sartorius');
    }
    
    viewHistory() {
        this.changes.show({
            SubjectType: ['Setting'],
            SubjectName: 'Sartorius'
        })
    }
}
