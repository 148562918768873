import {Component, ViewChild, ViewContainerRef, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ApiService} from '../shared/Api.service';
import { CellRendererFactories, ChangesService, ConfigGridComponent, FileDownloadService, IAgGridColGroupDef, IAgGridColumnDef, NotificationService, TranslateService, SwxModule, GridModule } from 'swx.front-end-lib';
import {TicketTrackingSystemService} from '../shared/TicketTrackingSystem.service';
import { HasPermissionService, HasPermissionPipe } from '../shared/HasPermission.pipe';
import {GridOptions} from "ag-grid-community";
import { NgIf } from '@angular/common';
import { FormsModule } from '@angular/forms';

@Component({
    templateUrl: 'ClientContactEdit.component.html',
    encapsulation: ViewEncapsulation.None,
    styles: [`
        .clientContagsGrid .ag-header-group-cell-with-group.airportCode {
            border: 1px solid #ffffff;
        }

        .clientContagsGrid .ag-header-cell.first  {
            border-left: 1px solid #ffffff !important;
        }

        .clientContagsGrid .ag-header-cell.last  {
            border-right: 1px solid #ffffff !important;
        }
    `],
    standalone: true,
    imports: [SwxModule, FormsModule, NgIf, GridModule, HasPermissionPipe]
})
export class ClientContactEditComponent {
    item: any;
    client: any;
    returnPath;
    @ViewChild('ngForm', {static: true}) ngForm;
    @ViewChild('grid', { static: true }) grid: ConfigGridComponent;
    tab;
    airports = this.api.Airport.query();
    weatherAlertProfiles = this.api.WeatherAlertProfile.query();
    contactTypes = this.api.ClientContactType.query();
    contactAirportWarningTypes = this.api.ClientContactAirportWarningType.query();
    columnDefs: IAgGridColGroupDef[];
    selectedContactEmails = '';
    gridOptions: GridOptions = {
        suppressRowClickSelection: true,
        onSelectionChanged: (e) => {
            this.selectedContactEmails = e.api.getSelectedRows()
                .map(c => c.Email)
                .join(';');
        },
    };
    
    constructor(
        private router: Router,
        private viewContainerRef: ViewContainerRef,
        private route: ActivatedRoute,
        private api: ApiService,
        private changes: ChangesService,
        private ticketTrackingSystem: TicketTrackingSystemService,
        public hasPermissionService: HasPermissionService,
        private notificationService: NotificationService,
        private translateService: TranslateService,
        private fileDownloadService: FileDownloadService,
        private cellRenderers: CellRendererFactories,
    ) {
        this.tab = location.hash ? location.hash.substring(1) : 'contacts';

        this.returnPath = '/clients';
        const id = this.route.snapshot.params['id'];

        this.item = this.api.ClientContact.get({ id: id });
        this.client = this.api.Client.get({ id: id });

        Promise.all([this.item.$promise, this.client.$promise, this.contactTypes.$promise, this.contactAirportWarningTypes.$promise, this.airports.$promise, this.weatherAlertProfiles.$promise]).then(() => {
            var mainColumnDefs: IAgGridColumnDef[] = [
                { colId: "Id", field: "Id", headerName: "#", width: 80, filterType: 'integer', pinned: 'left', checkboxSelection: true },
                { colId: "Message", field: "Message", headerName: "Company/Division", width: 300, cellClass: "select", cellTemplate: `<input type="text" [(ngModel)]="params.data.Division" [name]="'params.data[' + params.node.id + '].Division'" style="width: 99%;" />` },
                { colId: "Name", field: "Name", headerName: "Name", width: 130, pinned: 'left', cellTemplate: `<input type="text" [(ngModel)]="params.data.Name" [name]="'params.data[' + params.node.id + '].Name'" style="width: 99%;" />` },
                { colId: "Email", field: "Email", headerName: "Email", width: 210, pinned: 'left', cellTemplate: `<input type="email" required [(ngModel)]="params.data.Email" [name]="'params.data[' + params.node.id + '].Email'" style="width: 99%;" />` },
                { colId: "Phone", field: "Phone", headerName: "Phone", width: 110, pinned: 'left', cellTemplate: `<input type="tel" [(ngModel)]="params.data.Phone" [name]="'params.data[' + params.node.id + '].Phone'" style="width: 99%;" />` },
                { colId: "Active", field: "Active", headerName: 'Active?', width: 70, pinned: 'left', cellTemplate: `<input type="checkbox" [(ngModel)]="params.data.Active" [name]="'params.data[' + params.node.id + '].Active'" />` },
                { colId: "RequestConfigChangesAllowed", field: "RequestConfigChangesAllowed", headerName: 'Allowed to request config changes?', width: 120, cellTemplate: `<input type="checkbox" [(ngModel)]="params.data.RequestConfigChangesAllowed" [name]="'params.data[' + params.node.id + '].RequestConfigChangesAllowed'" />` },
            ];
            
            var columnDefs: IAgGridColGroupDef[] = [
                {
                    children: mainColumnDefs
                }
            ];

            Object.keys(this.contactTypes).forEach(option => {
                mainColumnDefs.push({ colId: "isContactTypeEnabled_" + option, field: "isContactTypeEnabled_" + option, headerName: option + '?', width: 50, pinned: 'left', cellTemplate: `<input type="checkbox" [checked]="parent.isContactTypeEnabled(params.data, '${option}')" (click)="parent.toggleContactType(params.data, '${option}')"/>`, sortable: false });
            });

            mainColumnDefs.push({ colId: "IsOfflineContact", field: "IsOfflineContact", headerName: 'Receive station offline emails?', width: 70, pinned: 'left', cellTemplate: `<input type="checkbox" [(ngModel)]="params.data.IsOfflineContact" [name]="'params.data[' + params.node.id + '].IsOfflineContact'"/>` });
            mainColumnDefs.push({ colId: "IsOperationManager", field: "IsOperationManager", headerName: 'Is contact manager?', width: 90, pinned: 'left', cellTemplate: `<input type="checkbox" [(ngModel)]="params.data.IsOperationManager" [name]="'params.data[' + params.node.id + '].IsOperationManager'" (click)="parent.updateBarrelIcingManager(params.data)"/>` });

            var clientWeatherAlertProfiles = this.weatherAlertProfiles.filter(p => p.ClientId === this.item.Id);
            var activeAirports = this.client.ClientAirports.filter(clientAirport => this.isClientAirportLwe(clientAirport, clientWeatherAlertProfiles));
            
            activeAirports.forEach(clientAirport => {
                var airport = this.airports.find(a => a.Id === clientAirport.AirportId);
                var airportCode = airport ? (airport.ICAOCode + '/' + airport.IATACode) : clientAirport.AirportId;

                var airportColumnDefs = new Array<IAgGridColumnDef>();

                airportColumnDefs.push({ colId: `isClientContactAirportEnabled_${clientAirport.AirportId}_BarrelIcing`, field: `isClientContactAirportEnabled_${clientAirport.AirportId}_BarrelIcing`, headerName: "BI", headerClass: "first", width: 40, sortable: false,
                    cellRenderer: params => this.cellRenderers.input('checkbox', {
                        checked: this.isClientContactAirportEnabled(params.data, clientAirport.AirportId, 'BarrelIcing', null),
                        onclick: () => this.toggleClientContactAirport(params.data, clientAirport.AirportId, 'BarrelIcing', null),
                    }) });
                
                airportColumnDefs.push({ colId: `isClientContactAirportEnabled_${clientAirport.AirportId}_ActiveFrost`, field: `isClientContactAirportEnabled_${clientAirport.AirportId}_ActiveFrost`, headerName: "AF", width: 40, sortable: false,
                    cellRenderer: params => this.cellRenderers.input('checkbox', {
                        checked: this.isClientContactAirportEnabled(params.data, clientAirport.AirportId, 'ActiveFrost', null),
                        onclick: () => this.toggleClientContactAirport(params.data, clientAirport.AirportId, 'ActiveFrost', null),
                    }) });

                clientWeatherAlertProfiles.forEach(weatherAlertProfile => {
                    airportColumnDefs.push({ colId: `isClientContactAirportEnabled_${clientAirport.AirportId}_WeatherAlert_${weatherAlertProfile.Id}`, field: `isClientContactAirportEnabled_${clientAirport.AirportId}_WeatherAlert_${weatherAlertProfile.Id}`, headerName: weatherAlertProfile.Name, width: 70, sortable: false,
                        cellRenderer: params => this.cellRenderers.input('checkbox', {
                            checked: this.isClientContactAirportEnabled(params.data, clientAirport.AirportId, 'WeatherAlert', weatherAlertProfile.Id),
                            onclick: () => this.toggleClientContactAirport(params.data, clientAirport.AirportId, 'WeatherAlert', weatherAlertProfile.Id),
                        }) });
                });

                airportColumnDefs.push({ colId: `isClientContactAirportEnabled_${clientAirport.AirportId}_StationAlertLevel1`, field: `isClientContactAirportEnabled_${clientAirport.AirportId}_StationAlertLevel1`, headerName: "LVL 1", width: 40, sortable: false,
                    cellRenderer: params => this.cellRenderers.input('checkbox', {
                        checked: this.isClientContactAirportEnabled(params.data, clientAirport.AirportId, 'StationAlertLevel1', null),
                        onclick: () => this.toggleClientContactAirport(params.data, clientAirport.AirportId, 'StationAlertLevel1', null),
                        disabled: this.isClientContactAirportEnabled(params.data, clientAirport.AirportId, 'StationAlertLevel2', null),
                    }) });
                
                airportColumnDefs.push({ colId: `isClientContactAirportEnabled_${clientAirport.AirportId}_StationAlertLevel2`, field: `isClientContactAirportEnabled_${clientAirport.AirportId}_StationAlertLevel2`, headerName: "LVL 2", width: 40, sortable: false,
                    cellRenderer: params => this.cellRenderers.input('checkbox', {
                        checked: this.isClientContactAirportEnabled(params.data, clientAirport.AirportId, 'StationAlertLevel2', null),
                        onclick: () => this.toggleClientContactAirport(params.data, clientAirport.AirportId, 'StationAlertLevel2', null),
                        disabled: this.isClientContactAirportEnabled(params.data, clientAirport.AirportId, 'StationAlertLevel1', null),
                    }) });

                airportColumnDefs.push({ colId: `isClientContactAirportEnabled_${clientAirport.AirportId}_EngineCoverAlert`, field: `isClientContactAirportEnabled_${clientAirport.AirportId}_EngineCoverAlert`, headerName: "Engine cover", width: 60, sortable: false,
                    cellRenderer: params => this.cellRenderers.input('checkbox', {
                        checked: this.isClientContactAirportEnabled(params.data, clientAirport.AirportId, 'EngineCoverAlert', null),
                        onclick: () => this.toggleClientContactAirport(params.data, clientAirport.AirportId, 'EngineCoverAlert', null),
                    }) });

                columnDefs.push({
                    headerName: airportCode,
                    headerClass: "airportCode",
                    children: airportColumnDefs,
                });
            });

            columnDefs.push({
                children: [
                    { colId: "Delete", field: "Delete", headerName: "", width: 40, cellTemplate: `<a *ngIf="!params.data.HasEmails" (click)="parent.removeClientContact(params.data)" class="mdi mdi-delete" title="Delete"></a><a *ngIf="params.data.HasEmails" (click)="parent.clientContactInUse(params.data)" class="mdi mdi-block-helper" title="In use"></a>`, sortable: false }
                ]
            });

            this.columnDefs = columnDefs;
        });
    }

    save() {
        this.item.$promise.then(_ => {
            this.ticketTrackingSystem.trackAndSave(this.item, this.returnPath);
        });
    }

    cancel() {
        this.router.navigateByUrl(this.returnPath);
    }

    viewHistory() {
        this.changes.show({
            SubjectType: ['Client'],
            SubjectId: this.item.Id
        });
    };

    switchTab(tab) {
        location.hash = tab;
        this.tab = tab;
    };

    addClientContact() {
        this.item.ClientContacts = this.item.ClientContacts || [];
        this.item.ClientContacts.unshift({
            ClientId: this.item.Id,
            Active: true,
        });
        this.grid.gridApi.applyTransaction({ addIndex: 0, add: this.item.ClientContacts.slice(0, 1) })
        this.ngForm.form.updateValueAndValidity();
        this.ngForm.form.markAsDirty();
    }

    removeClientContact(clientContact) {
        this.item.ClientContacts.splice(this.item.ClientContacts.indexOf(clientContact), 1);
        this.grid.updateFilteredRows();
        this.ngForm.form.updateValueAndValidity();
        this.ngForm.form.markAsDirty();
    }

    isClientContactAirportEnabled(clientContact, airportId, warningType, weatherAlertProfileId) {
        return clientContact.ClientContactAirports
            && clientContact.ClientContactAirports.some(clientContactAirport => clientContactAirport.AirportId === airportId && clientContactAirport.WarningType === warningType && clientContactAirport.WeatherAlertProfileId == weatherAlertProfileId);
    };

    toggleClientContactAirport(clientContact, airportId, warningType, weatherAlertProfileId) {
        clientContact.ClientContactAirports = clientContact.ClientContactAirports || [];
        var found = false;

        clientContact.ClientContactAirports.every((clientContactAirport, index) => {
            if (clientContactAirport.AirportId === airportId && clientContactAirport.WarningType === warningType && clientContactAirport.WeatherAlertProfileId == weatherAlertProfileId) {
                clientContact.ClientContactAirports.splice(index, 1);
                found = true;
                return false;
            }
            
            return true;
        });

        if (!found) {
            clientContact.ClientContactAirports.push({
                AirportId: airportId,
                WarningType: warningType,
                WeatherAlertProfileId: weatherAlertProfileId,
            });
        }

        this.ngForm.form.markAsDirty();
    }

    clientContactInUse(item) {
        this.notificationService.show(this.translateService.translate('Emails found for user ' + item.Name), { type: 'error' });
    }

    updateBarrelIcingManager(clientContact) {
        this.item.ClientContacts.forEach(item => {
            if (item !== clientContact) {
                item.IsOperationManager = false;
            }
        });
    }

    isContactTypeEnabled(clientContact, option) {
        var selected = clientContact._type || (clientContact.Type || '').split(', ');
        return selected.indexOf(option) > -1;
    }

    toggleContactType(clientContact, option) {
        var selected = clientContact._type || (clientContact.Type ? clientContact.Type.split(', ') : []);
        var index = selected.indexOf(option);
        if (index > -1) {
            selected.splice(index, 1);
        } else {
            selected.push(option);
        }
        clientContact.Type = selected.join(', ');
        this.ngForm.form.markAsDirty();
    }

    exportContacts() {
        var selectedContacts = this.grid.gridApi.getSelectedRows()
            .map(c => c.Id);

        var query = {
            id: this.item.Id,
            contacts: selectedContacts.length === 0 ? null : selectedContacts
        };
        this.api.ClientContactExport
            .exportPost(query)
            .then((response) => this.fileDownloadService.download(response.body, (header) => response.headers.get(header)));
    }
    
    isClientAirportLwe(clientAirport, clientWeatherAlertProfiles) {
        return clientAirport.StationSimulationId
            || clientAirport.StationGen2Id
            || clientAirport.StationGen3AId
            || clientAirport.StationGen3BId
            || clientAirport.StationGenVId
            || clientWeatherAlertProfiles.some(p => p.Airports.some(aid => aid === clientAirport.AirportId));
    }
}
