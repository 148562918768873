<page-title>{{('App configuration'|translate) + ': ' + (item.Name || 'new')}}</page-title>
<div class="noMargins">
    <div class="toolbar">
        <a class="tab" (click)="switchTab('basicInfo')" [class.active]="tab == 'basicInfo'" [class.invalid]="!ngForm.form.controls.basicInfoForm?.valid">{{'Basic info'|translate}}</a>
        <a class="tab" (click)="switchTab('deicingRequestProviders')" [class.active]="tab == 'deicingRequestProviders'" [class.invalid]="!ngForm.form.controls.deicingRequestProvidersForm?.valid">{{'Deicng service providers'|translate}}</a>
        <input type="submit" [disabled]="!ngForm.form.valid || ngForm.form.pristine" value="{{'Save'|translate}}" (click)="save()" />
        <input type="button" (click)="cancel()" value="{{'Cancel'|translate}}" />
        <input type="button" *ngIf="('DataChanges'|hasPermission)" (click)="viewHistory()" [disabled]="!item.Id" value="{{'View history'|translate}}" />
        <button type="button" class="green" *ngIf="('ConfigEditClientApiTokens'|hasPermission)" (click)="addMobileDeicingRequestProvider()">{{'Add provider'|translate}}</button>
    </div>
    
    <form #ngForm="ngForm" (submit)="save()" class="form">
        <fieldset class="contentMargins" [disabled]="!('ConfigEditClientApiTokens'|hasPermission)" [hidden]="tab != 'basicInfo'" ngModelGroup="basicInfoForm">
            <label>
                <span class="caption">{{'Client'|translate}}</span>
                <select [(ngModel)]="item.ClientId" name="item.ClientId" required (ngModelChange)="updateClient()">
                    <option *ngFor="let option of clients|orderBy:'Name'" [ngValue]="option.Id">
                        {{option.Name}}
                    </option>
                </select>
            </label>
            <label>
                <span class="caption">{{'Active'|translate}}</span>
                <input type="checkbox" [(ngModel)]="item.Active" name="item.Active" />
            </label>
            <label>
                <span class="caption">{{'API client code'|translate}}</span>
                <input type="text" required [(ngModel)]="item.APIClientCode" name="item.APIClientCode" maxlength="4" style="width: 70px;" />
            </label>
            <label>
                <span class="caption">{{'Private hash'|translate}}</span>
                <input type="text" required pattern="^[0-9a-fA-F]+$" [(ngModel)]="item.APIPrivateHash" name="item.APIPrivateHash" style="width: 500px;" />
            </label>
            <label>
                <span class="caption">{{'Public hash'|translate}}</span>
                <input type="text" required pattern="^[0-9a-fA-F]+$" [(ngModel)]="item.APIPublicHash" name="item.APIPublicHash" style="width: 500px;" />
            </label>
            <label>
                <span class="caption">{{'Access Token Expiration Time in Seconds'|translate}}</span>
                <input type="number" [(ngModel)]="item.AccessTokenExpireTimeSeconds" name="item.AccessTokenExpireTimeSeconds" />
            </label>
            <label>
                <span class="caption">{{'Disable Over the Air Deployment'|translate}}</span>
                <input type="checkbox" [(ngModel)]="item.MobileUseManualDeployment" name="item.MobileUseManualDeployment" />
            </label>
            <div class="section">
                <label>
                    <span class="caption">{{'SureWx notes about this mobile profile'|translate}}</span>
                    <textarea [(ngModel)]="item.InternalNote" name="item.InternalNote" style="width: 500px; height: 100px;"></textarea>
                </label>
                <h3>{{'General'|translate}}</h3>
                <label>
                    <span class="caption">{{'Service Level Logo'|translate}}</span>
                    <select [(ngModel)]="item.ServiceLevelLogo" name="item.ServiceLevelLogo" [selectedIndex]="-1">
                        <option [ngValue]="null">{{'None'|translate}}</option>
                        <option *ngFor="let option of serviceLevelLogos|keys" [ngValue]="option">
                            {{serviceLevelLogos[option]}}
                        </option>
                    </select>
                </label>
                <label>
                    <span class="caption">{{'EULA required?'|translate}}</span>
                    <input type="checkbox" [(ngModel)]="item.MobileLicenceAgreementRequired" name="item.MobileLicenceAgreementRequired" />
                </label>
                <label>
                    <span class="caption">{{'EULA HTML file'|translate}}</span>
                    <file [required]="item.MobileLicenceAgreementRequired" accept="text/html" [(ngModel)]="item.EulaTextData" name="ChecklistsData" [filename]="item.EulaTextFilename" (filenameChange)="item.EulaTextFilename = $event" [contentType]="item.EulaTextContentType" (contentTypeChange)="item.EulaTextContentType = $event"></file>
                </label>
                <label>
                    <span class="caption">{{'Mobile in manual mode only'|translate}}</span>
                    <input type="checkbox" [disabled]="item.MobileAlwaysShowWeatherOverride" [(ngModel)]="item.MobileOnlyOffline" name="item.MobileOnlyOffline" (ngModelChange)="item.MobileAlwaysShowWeatherOverride = item.MobileOnlyOffline ? false : item.MobileAlwaysShowWeatherOverride" />
                </label>
                <label>
                    <span class="caption">{{'LWE message mappings'|translate}}</span>
                    <select [(ngModel)]="item.LWEMessageMappingProfileId" name="item.LWEMessageMappingProfileId">
                        <option [ngValue]="null">{{'Use client default'|translate}}</option>
                        <option *ngFor="let option of lweMessageMappingProfiles|orderBy:'Name'" [ngValue]="option.Id">
                            {{option.Name}}
                        </option>
                    </select>
                </label>
                <label>
                    <span class="caption">{{'METAR message mappings'|translate}}</span>
                    <select [(ngModel)]="item.MetarMessageMappingProfileId" name="item.MetarMessageMappingProfileId">
                        <option [ngValue]="null">{{'Use client default'|translate}}</option>
                        <option *ngFor="let option of metarMessageMappingProfiles|orderBy:'Name'" [ngValue]="option.Id">
                            {{option.Name}}
                        </option>
                    </select>
                </label>
                <label>
                    <span class="caption">{{'METAR weather mappings'|translate}}</span>
                    <select [(ngModel)]="item.MetarWeatherMappingProfileId" name="item.MetarWeatherMappingProfileId">
                        <option [ngValue]="null">{{'Use client default'|translate}}</option>
                        <option *ngFor="let option of metarWeatherMappingProfiles|orderBy:'Name'" [ngValue]="option.Id">
                            {{option.Name}}
                        </option>
                    </select>
                </label>
                <label>
                    <span class="caption">{{'Always show weather override with live weather if available'|translate}}</span>
                    <input type="checkbox" [disabled]="item.MobileOnlyOffline" [(ngModel)]="item.MobileAlwaysShowWeatherOverride" name="item.MobileAlwaysShowWeatherOverride" />
                </label>
                <label>
                    <span class="caption">{{'Hide offline icon'|translate}}</span>
                    <input type="checkbox" [(ngModel)]="item.HideOfflineIcon" name="item.HideOfflineIcon" />
                </label>
                <label>
                    <span class="caption">{{'Hide offline popup'|translate}}</span>
                    <input type="checkbox" [(ngModel)]="item.HideOfflinePopup" name="item.HideOfflinePopup" />
                </label>
                <label>
                    <span class="caption">{{'Document profile'|translate}}</span>
                    <select [(ngModel)]="item.MobileDocumentProfileId" name="item.MobileDocumentProfileId" required>
                        <option *ngFor="let option of mobileDocumentProfiles|orderBy:'Name'" [ngValue]="option.Id">
                            {{option.Name}}
                        </option>
                    </select>
                </label>
                <label>
                    <span class="caption">{{'Path to the config file'|translate}}</span>
                    <input type="text" [(ngModel)]="item.MobileSettingsFilePath" name="item.MobileSettingsFilePath" pattern="^[a-zA-Z]:((\/|(\\?))[\w .]+)+" size="55" />
                    <input *ngIf="item.MobileSettingsFilePath || item.MobileFileTemplateSettings" type="button" (click)="showFileTemplateMapping = !showFileTemplateMapping" value="{{ (showFileTemplateMapping ? 'Hide ' : 'Edit ') + 'template'|translate}}" />
                </label>
                <div *ngIf="(item.MobileSettingsFilePath || item.MobileFileTemplateSettings) && showFileTemplateMapping">
                    <table class="table">
                        <tr>
                            <th style="text-align: center;">{{'Data'|translate}}</th>
                            <th style="text-align: center;">{{'Mappings'|translate}}</th>
                        </tr>
                        <tr>
                            <td>
                                <file-as-data-uri required [(ngModel)]="item.MobileFileTemplateSettings" name="item.MobileFileTemplateSettings"></file-as-data-uri>
                                <br /><br />
                                <textarea [required]="item.MobileSettingsFilePath  || item.MobileFileTemplateSettings" [(ngModel)]="item.MobileFileTemplateSettings" name="item.MobileFileTemplateSettings" (ngModelChange)="getFileTemplateMapping()" style="width: 500px; height: 240px;"></textarea>
                            </td>
                            <td style="vertical-align:top;">
                                <input type="button" (click)="getFileTemplateMapping()" [disabled]="!item.MobileFileTemplateSettings" value="{{'Set mappings'|translate}}" />
                                <br /><br />
                                <div style="max-height: 250px; overflow: auto">
                                    <table class="table" *ngIf="templateInfos">
                                        <tr>
                                            <th>{{'Map to'|translate}}</th>
                                            <th>{{'Origin'|translate}}</th>
                                            <th>{{'Received data'|translate}}</th>
                                        </tr>
                                        <tr *ngFor="let mapping of mobileFileTemplateMappings; let i = index">
                                            <td>{{mapping.FieldValue}}</td>
                                            <td>
                                                <select [(ngModel)]="item.FieldPath" [name]="'mapping[' + i + '].FieldPath'">
                                                    <option *ngFor="let templateInfo of templateInfos" [ngValue]="templateInfo.FieldPath">
                                                        {{templateInfo.FieldName}}
                                                    </option>
                                                </select>
                                            </td>
                                            <td>{{mapping.FieldPath ? ((templateInfos|filter:{FieldPath:mapping.FieldPath})[0].FieldValue) : ""}}</td>
                                        </tr>
                                    </table>
                                </div>
                            </td>
                        </tr>
                    </table><br />
                </div>
                <label>
                    <span class="caption">{{'Use the airport code from config file'|translate}}</span>
                    <input type="checkbox" [(ngModel)]="item.MobileUseAirportFromConfigFile" name="item.MobileUseAirportFromConfigFile" />
                </label>
                <label>
                    <span class="caption">{{'Aircraft information from init'|translate}}</span>
                    <input type="checkbox" [(ngModel)]="item.MobileAircraftInformationFromInit" name="item.MobileAircraftInformationFromInit" />
                </label>
                <label *ngIf="item.MobileAircraftInformationFromInit">
                    <span class="caption">{{'Use IATA for airline codes'|translate}}</span>
                    <input type="checkbox" [(ngModel)]="item.MobileUseAirlineIataCode" name="item.MobileUseAirlineIataCode" />
                </label>
                <label *ngIf="item.MobileAircraftInformationFromInit">
                    <span class="caption">{{'Time interval for the inits to be sent to the EFB, in minutes'|translate}}</span>
                    <input required type="text" [(ngModel)]="item.MobileTimeIntervalForInitsToBeSentToEfb" name="item.MobileTimeIntervalForInitsToBeSentToEfb" style="width: 60px;" />
                </label>
                <label>
                    <span class="caption">{{'Mobile weather menu'|translate}}</span>
                    <select [(ngModel)]="item.MobileWeatherMappingProfileId" name="item.MobileWeatherMappingProfileId">
                        <option [ngValue]="null">{{'None'|translate}}</option>
                        <option *ngFor="let option of mobileWeatherMappingProfiles|orderBy:'Name'" [ngValue]="option.Id">
                            {{option.Name}}
                        </option>
                    </select>
                </label>
                <label>
                    <span class="caption">{{'Mobile weather menu (for apps < V 4.10.0)'|translate}}</span>
                    <select [(ngModel)]="item.LegacyMobileWeatherMappingProfileId" name="item.LegacyMobileWeatherMappingProfileId">
                        <option [ngValue]="null">{{'None'|translate}}</option>
                        <option *ngFor="let option of mobileWeatherMappingProfiles|orderBy:'Name'" [ngValue]="option.Id">
                            {{option.Name}}
                        </option>
                    </select>
                </label>
                <label>
                    <span class="caption">{{'Failsafe airport'|translate}}</span>
                    <select [(ngModel)]="item.FailsafeAirportId" name="item.FailsafeAirportId">
                        <option [ngValue]="null">{{'None'|translate}}</option>
                        <option *ngFor="let clientAirport of availableFailsafeAirports()" [ngValue]="clientAirport.AirportId">
                            {{airportsDict[clientAirport.AirportId].ICAOCode + '/' + airportsDict[clientAirport.AirportId].IATACode + ' - ' + airportsDict[clientAirport.AirportId].Name}}
                        </option>
                    </select>
                </label>
                <label *ngIf="item.FailsafeAirportId">
                    <span class="caption">{{'Use failsafe airport by default and hide airport selection'|translate}}</span>
                    <input type="checkbox" [(ngModel)]="item.HideAirportSelection" name="item.HideAirportSelection" />
                </label>
                <label *ngIf="item.FailsafeAirportId && !item.HideAirportSelection">
                    <span class="caption">{{'Failsafe airport label'|translate}}</span>
                    <input type="text" [required]="item.FailsafeAirportId && !item.HideAirportSelection" [(ngModel)]="item.FailsafeAirportLabel" name="item.FailsafeAirportLabel" />
                </label>
                <label>
                    <span class="caption">{{'Allow any airport'|translate}}</span>
                    <input type="checkbox" [(ngModel)]="item.AllowAnyAirport" name="item.AllowAnyAirport" />
                </label>
                <label>
                    <span class="caption">{{'LWE/TWA temperature rounding method'|translate}}</span>
                    <select [(ngModel)]="item.MobileTemperatureRoundingMethod" name="item.MobileTemperatureRoundingMethod">
                        <option [ngValue]="null">{{'None'|translate}}</option>
                        <option *ngFor="let option of roundingMethods|keys" [ngValue]="option">
                            {{roundingMethods[option]}}
                        </option>
                    </select>
                </label>
                <label>
                    <span class="caption">{{'Show degree symbol (°)?'|translate}}</span>
                    <input type="checkbox" [(ngModel)]="item.MobileShowDegreeSymbol" name="item.MobileShowDegreeSymbol" />
                </label>
                <label>
                    <span class="caption">{{'Show METAR weather description instead of code'|translate}}</span>
                    <input type="checkbox" [(ngModel)]="item.MobileShowMetarWeatherDescription" name="item.MobileShowMetarWeatherDescription" />
                </label>
                <div *ngIf="item.MobileAircraftInformationFromInit">
                    <h3>{{'Include inits from extra airlines'|translate}}</h3>
                    <table class="table">
                        <tr>
                            <th>{{'Airline'|translate}}</th>
                            <th></th>
                        </tr>
                        <tr *ngFor="let clientApiTokenMobileInitFlightDeicingAirline of item.ClientApiTokenMobileInitFlightDeicingAirlines|orderBy:'Order'; let i = index">
                            <td>{{(airlines|findById:clientApiTokenMobileInitFlightDeicingAirline.DeicingAirlineId).Name}}</td>
                            <td>
                                <a *ngIf="('ConfigEditClientApiTokens'|hasPermission)" (click)="removeClientApiTokenMobileInitFlightDeicingAirline(clientApiTokenMobileInitFlightDeicingAirline)" class="mdi mdi-delete"></a>
                            </td>
                        </tr>
                    </table>
                    <br />
                    <select [(ngModel)]="airlineOption" [name]="'airlineOption'">
                        <option *ngFor="let option of airlines|airlineNotEnabled:item.ClientApiTokenMobileInitFlightDeicingAirlines|orderBy:['Name']" [ngValue]="option">
                            {{option.Name}}
                        </option>
                    </select>
                    <input type="button" *ngIf="('ConfigEditClientApiTokens'|hasPermission)" (click)="addClientApiTokenMobileInitFlightDeicingAirline(airlineOption); airlineOption = null;" value="{{'Add airline'|translate}}" [disabled]="airlineOption == null" />
                    <br />
                    <br />
                </div>
                <label>
                    <span class="caption">{{'iOS: Use external reader software'|translate}}</span>
                    <input type="checkbox" [(ngModel)]="item.MobileUseExternalReader" name="item.MobileUseExternalReader" />
                </label>
                <label>
                    <span class="caption">{{'Windows: path to reader software (.exe)'|translate}}</span>
                    <input type="text" [(ngModel)]="item.MobileReaderSoftwarePath" name="item.MobileReaderSoftwarePath" pattern="^(?:[\w]\:|\\)(\\[a-zA-Z_\-\s0-9\.\(\)]+)+\.(exe)$" size="55" />
                </label>
                <label>
                    <span class="caption">{{ 'Disable toolbar when viewing pdfs'|translate}}</span>
                    <input type="checkbox" [(ngModel)]="item.MobileHidePdfViewerToolbar" name="item.MobileHidePdfViewerToolbar" />
                </label>
                <label>
                    <span class="caption">{{'Use hardware GPS if available'|translate}}</span>
                    <input type="checkbox" [(ngModel)]="item.MobileUseHardwareGps" name="item.MobileUseHardwareGps" />
                </label>
                <label>
                    <span class="caption">{{'Disable IP geolocation'|translate}}</span>
                    <input type="checkbox" [(ngModel)]="item.MobileDisableIpGeolocation" name="item.MobileDisableIpGeolocation" />
                </label>
                <label>
                    <span class="caption">{{'Use night mode when app launches'|translate}}</span>
                    <input type="checkbox" [(ngModel)]="item.MobileUseNightModeAtLaunch" name="item.MobileUseNightModeAtLaunch" />
                </label>
                <label>
                    <span class="caption">{{'Use OS light/dark mode setting'|translate}}</span>
                    <input type="checkbox" [(ngModel)]="item.MobileUseOSTheme" name="item.MobileUseOSTheme" />
                </label>
                <label>
                    <span class="caption">{{'Show clear indicator for LWE clear'|translate}}</span>
                    <input type="checkbox" [(ngModel)]="item.ShowClearIndicatorForLWEClear" name="item.ShowClearIndicatorForLWEClear" />
                </label>
                <label>
                    <span class="caption">{{'Include forecast message?'|translate}}</span>
                    <input type="checkbox" [(ngModel)]="item.MobileIncludeForecastMessage" name="item.MobileIncludeForecastMessage" />
                </label>
            </div>

            <div class="section">
                <h3>{{'Airport override menu'|translate}}</h3>
                <label>
                    <span class="caption">{{'Confirm airport entry'|translate}}</span>
                    <input type="checkbox" [(ngModel)]="item.MobileConfirmAirport" name="item.MobileConfirmAirport" />
                </label>
            </div>

            <div class="section">
                <h3>{{'Notes'|translate}}</h3>
                <label>
                    <span class="caption">{{'Show notes & cautions from regulatory agency fluid tables'|translate}}</span>
                    <input type="checkbox" [(ngModel)]="item.MobileFluidNotesIsVisible" name="item.MobileFluidNotesIsVisible" />
                </label>
                <label>
                    <span class="caption">{{'Mobile version'|translate}}</span>
                    <input type="text" [(ngModel)]="item.MobileVersion" name="item.MobileVersion" maxlength="8" style="width: 60px;" />
                </label>
                <label>
                    <span class="caption">{{'General note'|translate}}</span>
                    <textarea [(ngModel)]="item.MobileGeneralNote" name="item.MobileGeneralNote" style="width: 400px; height: 75px;"></textarea>
                </label>
            </div>

            <div class="section">
                <h3>{{'Aircraft identification input (aircraft registration, nose number, or tail number)'|translate}}</h3>
                <label>
                    <span class="caption">{{'Prompt for aircraft identification'|translate}}</span>
                    <input type="checkbox" [(ngModel)]="item.MobilePromptForAircraftIdentification" name="item.MobilePromptForAircraftIdentification" />
                </label>
                <label *ngIf="item.MobilePromptForAircraftIdentification">
                    <span class="caption">{{'Use input for aircraft lookup'|translate}}</span>
                    <input type="checkbox" [(ngModel)]="item.MobileLookUpAircraft" name="item.MobileLookUpAircraft" />
                </label>
                <label *ngIf="item.MobilePromptForAircraftIdentification">
                    <span class="caption">{{'Prompt for aircraft identification when app launches'|translate}} <a title="{{'If aircraft identification is read from another source (file, init etc), this prompt will not be shown'|translate}}" class="help mdi mdi-help-circle"></a></span>
                    <input type="checkbox" [(ngModel)]="item.MobilePromptForAircraftIdentificationAtLaunch" name="item.MobilePromptForAircraftIdentificationAtLaunch" />
                </label>
                <label *ngIf="item.MobilePromptForAircraftIdentification">
                    <span class="caption">{{'Prompt for aircraft identification when airport changes'|translate}}</span>
                    <input [disabled]="item.MobileSettingsFilePath || item.MobileAircraftInformationFromInit" type="checkbox" [(ngModel)]="item.MobilePromptForAircraftIdentificationAtAirportChange" name="item.MobilePromptForAircraftIdentificationAtAirportChange" />
                </label>
                <label *ngIf="item.MobilePromptForAircraftIdentification">
                    <span class="caption">{{'Prompt for aircraft identification at refresh'|translate}}</span>
                    <input [disabled]="item.MobileSettingsFilePath || item.MobileAircraftInformationFromInit" type="checkbox" [(ngModel)]="item.MobilePromptForAircraftIdentificationAtRefresh" name="item.MobilePromptForAircraftIdentificationAtRefresh" />
                </label>
                <label *ngIf="item.MobilePromptForAircraftIdentification">
                    <span class="caption">{{'Prompt for aircraft identification re-entry after duration (minutes)'|translate}}</span>
                    <input [disabled]="item.MobileSettingsFilePath || item.MobileAircraftInformationFromInit" type="number" [(ngModel)]="item.MobilePromptForAircraftIdentificationReEntryDuration" name="item.MobilePromptForAircraftIdentificationReEntryDuration" min="1" step="1" style="width: 60px;" />
                </label>
                <label *ngIf="item.MobilePromptForAircraftIdentification">
                    <span class="caption">{{'Use numeric keyboard'|translate}}</span>
                    <input [disabled]="item.MobileSettingsFilePath || item.MobileAircraftInformationFromInit" type="checkbox" [(ngModel)]="item.UseNumericKeyboardForAircraftRegistration" name="item.UseNumericKeyboardForAircraftRegistration" />
                </label>
                <label *ngIf="item.MobilePromptForAircraftIdentification">
                    <span class="caption">{{'Aircraft identification label'|translate}}</span>
                    <input type="text" [required]="item.MobilePromptForAircraftIdentification" [(ngModel)]="item.MobileAircraftIdentificationLabel" name="item.MobileAircraftIdentificationLabel" />
                </label>
                <label *ngIf="item.MobilePromptForAircraftIdentification">
                    <span class="caption">{{'Aircraft identification prefix'|translate}}</span>
                    <input type="text" pattern="^[\w -]*$" [(ngModel)]="item.MobileAircraftIdentificationPrefix" name="item.MobileAircraftIdentificationPrefix" />
                </label>
                <label *ngIf="item.MobilePromptForAircraftIdentification">
                    <span class="caption">{{'Uneditable prefix'|translate}}</span>
                    <input [disabled]="!item.MobileAircraftIdentificationPrefix" type="checkbox" [(ngModel)]="item.MobileAircraftIdentificationUneditablePrefix" name="item.MobileAircraftIdentificationUneditablePrefix" />
                </label>
                <label *ngIf="item.MobilePromptForAircraftIdentification">
                    <span class="caption">{{'Aircraft identification placeholder'|translate}}</span>
                    <input type="text" [(ngModel)]="item.MobileAircraftIdentificationPlaceholder" name="item.MobileAircraftIdentificationPlaceholder" />
                </label>
                <label *ngIf="item.MobilePromptForAircraftIdentification">
                    <span class="caption">{{'Aircraft identification regex expression'|translate}}</span>
                    <input type="text" [required]="item.MobilePromptForAircraftIdentification && item.MobileAircraftIdentificationPrefix" [(ngModel)]="item.MobileAircraftIdentificationRegexExpression" name="item.MobileAircraftIdentificationRegexExpression" />
                </label>
            </div>

            <div class="section">
                <h3>{{'Flight identification input (call sign, flight number)'|translate}}</h3>
                <label>
                    <span class="caption">{{'Prompt for flight identification'|translate}}</span>
                    <input type="checkbox" [(ngModel)]="item.MobilePromptForCallSign" name="item.MobilePromptForCallSign" />
                </label>
                <label *ngIf="item.MobilePromptForCallSign">
                    <span class="caption">{{'Prompt for flight identification when app launches'|translate}} <a title="{{'If flight identification is read from another source (file, init etc), this prompt will not be shown'|translate}}" class="help mdi mdi-help-circle"></a></span>
                    <input type="checkbox" [(ngModel)]="item.MobilePromptForCallSignAtLaunch" name="item.MobilePromptForCallSignAtLaunch" />
                </label>
                <label *ngIf="item.MobilePromptForCallSign">
                    <span class="caption">{{'Prompt for flight identification when airport changes'|translate}}</span>
                    <input [disabled]="item.MobileSettingsFilePath || item.MobileAircraftInformationFromInit" type="checkbox" [(ngModel)]="item.MobilePromptForCallSignAtAirportChange" name="item.MobilePromptForCallSignAtAirportChange" />
                </label>
                <label *ngIf="item.MobilePromptForCallSign">
                    <span class="caption">{{'Prompt for flight identification when app refreshes'|translate}}</span>
                    <input [disabled]="item.MobileSettingsFilePath || item.MobileAircraftInformationFromInit" type="checkbox" [(ngModel)]="item.MobilePromptForFlightIdentificationAtRefresh" name="item.MobilePromptForFlightIdentificationAtRefresh" />
                </label>
                <label *ngIf="item.MobilePromptForCallSign">
                    <span class="caption">{{'Prompt for flight identification re-entry after duration (minutes)'|translate}}</span>
                    <input [disabled]="item.MobileSettingsFilePath || item.MobileAircraftInformationFromInit" type="number" [(ngModel)]="item.MobilePromptForFlightIdentificationReEntryDuration" name="item.MobilePromptForFlightIdentificationReEntryDuration" min="1" step="1" style="width: 60px;" />
                </label>
                <label *ngIf="item.MobilePromptForCallSign">
                    <span class="caption">{{'Use numeric keyboard'|translate}}</span>
                    <input [disabled]="item.MobileSettingsFilePath || item.MobileAircraftInformationFromInit" type="checkbox" [(ngModel)]="item.UseNumericKeyboardForCallSign" name="item.UseNumericKeyboardForCallSign" />
                </label>
                <label *ngIf="item.MobilePromptForCallSign">
                    <span class="caption">{{'Flight identification label'|translate}}</span>
                    <input type="text" [required]="item.MobilePromptForCallSign" [(ngModel)]="item.MobileCallSignLabel" name="item.MobileCallSignLabel" />
                </label>
                <label *ngIf="item.MobilePromptForCallSign">
                    <span class="caption">{{'Flight identification prefix'|translate}}</span>
                    <input type="text" pattern="^[\w -]*$" [(ngModel)]="item.MobileCallSignPrefix" name="item.MobileCallSignPrefix" />
                </label>
                <label *ngIf="item.MobilePromptForCallSign">
                    <span class="caption">{{'Uneditable prefix'|translate}}</span>
                    <input [disabled]="!item.MobileCallSignPrefix" type="checkbox" [(ngModel)]="item.MobileCallSignUneditablePrefix" name="item.MobileCallSignUneditablePrefix" />
                </label>
                <label *ngIf="item.MobilePromptForCallSign">
                    <span class="caption">{{'Flight identification placeholder'|translate}}</span>
                    <input type="text" [(ngModel)]="item.MobileCallSignPlaceholder" name="item.MobileCallSignPlaceholder" />
                </label>
                <label *ngIf="item.MobilePromptForCallSign">
                    <span class="caption">{{'Flight identification regex expression'|translate}}</span>
                    <input type="text" [required]="item.MobilePromptForCallSign && item.MobileCallSignPrefix" [(ngModel)]="item.MobileCallSignRegexExpression" name="item.MobileCallSignRegexExpression" />
                </label>
            </div>

            <div class="section">
                <h3>{{'Equipment type input'|translate}}</h3>
                <label>
                    <span class="caption">{{'Prompt for equipment type'|translate}}</span>
                    <input type="checkbox" [(ngModel)]="item.MobilePromptForEquipmentType" name="item.MobilePromptForEquipmentType" />
                </label>
                <div *ngIf="item.MobilePromptForEquipmentType">
                    <label>
                        <span class="caption">{{'Persistent equipment type'|translate}}</span>
                        <input type="checkbox" [(ngModel)]="item.MobilePersistentEquipmentType" name="item.MobilePersistentEquipmentType" />
                    </label>
                    <label>
                        <span class="caption">{{'Equipment type prompt label'|translate}}</span>
                        <input type="text" [required]="item.MobilePromptForEquipmentType" [(ngModel)]="item.MobileEquipmentTypeChangeLabel" name="item.MobileEquipmentTypeChangeLabel" />
                    </label>
                    <label>
                        <span class="caption">{{'Equipment type change warning'|translate}}</span>
                        <input type="text" [(ngModel)]="item.MobileEquipmentTypeChangeWarning" name="item.MobileEquipmentTypeChangeWarning" />
                    </label>
                    <h3>{{'Equipment Types'|translate}}</h3>
                    <table class="table">
                        <tr>
                            <th>{{'Equipment type'|translate}}</th>
                            <th>{{'Order'|translate}}</th>
                            <th>{{'Custom short name'|translate}}</th>
                            <th>{{'Custom long name'|translate}}</th>
                            <th></th>
                        </tr>
                        <tr *ngFor="let clientApiTokenEquipmentType of item.ClientApiTokenEquipmentTypes|orderBy:'Order'">
                            <td>{{(equipmentTypes|findById:clientApiTokenEquipmentType.EquipmentTypeId).IATACode + ' / ' + ((equipmentTypes|findById:clientApiTokenEquipmentType.EquipmentTypeId).ICAOCode || ('N/A'|translate))}}</td>
                            <td><input type="number" required min="0" step="1" [(ngModel)]="clientApiTokenEquipmentType.Order" [name]="'clientApiTokenEquipmentType[' + clientApiTokenEquipmentType.EquipmentTypeId + '].Order'" style="width: 50px;" /></td>
                            <td><input type="text" [(ngModel)]="clientApiTokenEquipmentType.ShortName" [name]="'clientApiTokenEquipmentType[' + clientApiTokenEquipmentType.EquipmentTypeId + '].ShortName'" style="width: 150px;" /></td>
                            <td><textarea [(ngModel)]="clientApiTokenEquipmentType.LongName" [name]="'clientApiTokenEquipmentType[' + clientApiTokenEquipmentType.EquipmentTypeId + '].LongName'" style="width: 250px; height: 42px;"></textarea></td>
                            <td>
                                <a *ngIf="('ConfigEditClientApiTokens'|hasPermission)" (click)="removeClientApiTokenEquipmentType(clientApiTokenEquipmentType)" class="mdi mdi-delete"></a>
                            </td>
                        </tr>
                    </table>
                    <br />
                    <select [(ngModel)]="clientApiTokenEquipmentTypeOption" [name]="'clientApiTokenEquipmentTypeOption'">
                        <option *ngFor="let equipmentType of equipmentTypes|equipmentTypeNotEnabled:item.ClientApiTokenEquipmentTypes|orderBy:['IATACode', 'ICAOCode']" [ngValue]="equipmentType">
                            {{(equipmentType.IATACode + '/' + (equipmentType.ICAOCode || ('N/A'|translate))) + ' ' + equipmentType.ManufacturerAndModelDescription}}
                        </option>
                    </select>
                    <input type="button" *ngIf="('ConfigEditClientApiTokens'|hasPermission)" (click)="addClientApiTokenEquipmentType(clientApiTokenEquipmentTypeOption); clientApiTokenEquipmentTypeOption = null;" value="{{'Add equipment type'|translate}}" [disabled]="clientApiTokenEquipmentTypeOption == null" />
                </div>
            </div>

            <div class="section">
                <h3>{{'Display of message 1'|translate}}</h3>
                <label>
                    <span class="caption">{{'Enabled'|translate}}</span>
                    <input type="checkbox" [(ngModel)]="item.MobileAirplaneModeOnMessageEnabled" name="item.MobileAirplaneModeOnMessageEnabled" />
                </label>
                <label *ngIf="item.MobileAirplaneModeOnMessageEnabled">
                    <span class="caption">{{'Show message when app launches'|translate}}</span>
                    <input type="checkbox" [(ngModel)]="item.MobileAirplaneModeOnMessageAtLaunch" name="item.MobileAirplaneModeOnMessageAtLaunch" />
                </label>
                <label *ngIf="item.MobileAirplaneModeOnMessageEnabled">
                    <span class="caption">{{'Show message when airport changes'|translate}}</span>
                    <input type="checkbox" [(ngModel)]="item.MobileAirplaneModeOnMessageAtAirportChange" name="item.MobileAirplaneModeOnMessageAtAirportChange" />
                </label>
                <label *ngIf="item.MobileAirplaneModeOnMessageEnabled">
                    <span class="caption">{{'Show message at refresh'|translate}}</span>
                    <input type="checkbox" [(ngModel)]="item.MobileAirplaneModeOnMessageAtRefresh" name="item.MobileAirplaneModeOnMessageAtRefresh" />
                </label>
                <label *ngIf="item.MobileAirplaneModeOnMessageEnabled">
                    <span class="caption">{{'Show message at timer start'|translate}}</span>
                    <input type="checkbox" [(ngModel)]="item.MobileAirplaneModeOnMessageAtTimerStart" name="item.MobileAirplaneModeOnMessageAtTimerStart" />
                </label>
                <label *ngIf="item.MobileAirplaneModeOnMessageEnabled">
                    <span class="caption">{{'Show message again after duration (minutes)'|translate}}</span>
                    <input type="number" [(ngModel)]="item.MobileAirplaneModeOnMessageReEntryDuration" name="item.MobileAirplaneModeOnMessageReEntryDuration" min="1" step="1" style="width: 60px;" />
                </label>
                <label *ngIf="item.MobileAirplaneModeOnMessageEnabled">
                    <span class="caption">{{'Message'|translate}}</span>
                    <textarea [(ngModel)]="item.MobileAirplaneModeOnMessage" name="item.MobileAirplaneModeOnMessage" style="width: 400px; height: 75px;"></textarea>
                </label>
            </div>

            <div class="section">
                <h3>{{'Display of message 2'|translate}}</h3>
                <label>
                    <span class="caption">{{'Enabled'|translate}}</span>
                    <input type="checkbox" [(ngModel)]="item.MobileAirplaneModeOffMessageEnabled" name="item.MobileAirplaneModeOffMessageEnabled" />
                </label>
                <label *ngIf="item.MobileAirplaneModeOffMessageEnabled">
                    <span class="caption">{{'Show message when app launches'|translate}}</span>
                    <input type="checkbox" [(ngModel)]="item.MobileAirplaneModeOffMessageAtLaunch" name="item.MobileAirplaneModeOffMessageAtLaunch" />
                </label>
                <label *ngIf="item.MobileAirplaneModeOffMessageEnabled">
                    <span class="caption">{{'Show message when airport changes'|translate}}</span>
                    <input type="checkbox" [(ngModel)]="item.MobileAirplaneModeOffMessageAtAirportChange" name="item.MobileAirplaneModeOffMessageAtAirportChange" />
                </label>
                <label *ngIf="item.MobileAirplaneModeOffMessageEnabled">
                    <span class="caption">{{'Show message at refresh'|translate}}</span>
                    <input type="checkbox" [(ngModel)]="item.MobileAirplaneModeOffMessageAtRefresh" name="item.MobileAirplaneModeOffMessageAtRefresh" />
                </label>
                <label *ngIf="item.MobileAirplaneModeOffMessageEnabled">
                    <span class="caption">{{'Show message at timer start'|translate}}</span>
                    <input type="checkbox" [(ngModel)]="item.MobileAirplaneModeOffMessageAtTimerStart" name="item.MobileAirplaneModeOffMessageAtTimerStart" />
                </label>
                <label *ngIf="item.MobileAirplaneModeOffMessageEnabled">
                    <span class="caption">{{'Show message again after duration (minutes)'|translate}}</span>
                    <input type="number" [(ngModel)]="item.MobileAirplaneModeOffMessageReEntryDuration" name="item.MobileAirplaneModeOffMessageReEntryDuration" min="1" step="1" style="width: 60px;" />
                </label>
                <label *ngIf="item.MobileAirplaneModeOffMessageEnabled">
                    <span class="caption">{{'Message'|translate}}</span>
                    <textarea [(ngModel)]="item.MobileAirplaneModeOffMessage" name="item.MobileAirplaneModeOffMessage" style="width: 400px; height: 75px;"></textarea>
                </label>
            </div>

            <div class="section">
                <h3>{{'Weather override menu'|translate}}</h3>
                <label>
                    <span class="caption">{{'Confirm weather entry'|translate}}</span>
                    <input type="checkbox" [(ngModel)]="item.MobileConfirmWeather" name="item.MobileConfirmWeather" />
                </label>
                <label>
                    <span class="caption">{{'Use override temperature'|translate}}</span>
                    <input type="checkbox" [(ngModel)]="item.MobileUseOverrideTemperature" name="item.MobileUseOverrideTemperature" />
                </label>
                <label>
                    <span class="caption">{{'Metar visibility'|translate}}</span>
                    <select required [(ngModel)]="item.MobileMetarVisibilty" name="item.MobileMetarVisibilty">
                        <option *ngFor="let option of mobileMetarVisibilities|keys" [ngValue]="option">
                            {{mobileMetarVisibilities[option]}}
                        </option>
                    </select>
                </label>
                <label>
                    <span class="caption">{{'Hide intensity table?'|translate}}</span>
                    <input type="checkbox" [(ngModel)]="item.HideIntensityTable" name="item.HideIntensityTable" [disabled]="item.HideVisibilityTable" />
                </label>
                <label>
                    <span class="caption">{{'Hide visibility table?'|translate}}</span>
                    <input type="checkbox" [(ngModel)]="item.HideVisibilityTable" name="item.HideVisibilityTable" [disabled]="item.HideIntensityTable" />
                </label>
            </div>

            <div class="section">
                <h3>{{'HOT response'|translate}}</h3>
                <label>
                    <span class="caption">{{'Use TWA'|translate}}</span>
                    <input type="checkbox" [(ngModel)]="item.UseTwa" name="item.UseTwa" />
                </label>
                <label *ngIf="item.UseTwa">
                    <span class="caption">{{'Keep timer running if refresh switches from TWA to METAR'|translate}}</span>
                    <input type="checkbox" [(ngModel)]="item.KeepTimerRunningOnTwaToMetar" name="item.KeepTimerRunningOnTwaToMetar" />
                </label>
                <label>
                    <span class="caption">{{'Allow refresh of weather'|translate}}</span>
                    <input type="checkbox" [(ngModel)]="item.MobileAllowRefresh" name="item.MobileAllowRefresh" />
                </label>
                <label>
                    <span class="caption">{{'Use roman numerals'|translate}}</span>
                    <input type="checkbox" [(ngModel)]="item.UseRomanNumerals" name="item.UseRomanNumerals" />
                </label>
                <label>
                    <span class="caption">{{'Override HOT label'|translate}}</span>
                    <input type="text" [(ngModel)]="item.MobileOverrideHotLabel" name="item.MobileOverrideHotLabel" placeholder="HOT" style="width: 150px;" />
                </label>
                <label>
                    <span class="caption">{{'Override Allowance Time label'|translate}}</span>
                    <input type="text" [(ngModel)]="item.MobileOverrideAllowanceTimeLabel" name="item.MobileOverrideAllowanceTimeLabel" placeholder="Allowance Time" style="width: 150px;" />
                </label>
                <label *ngIf="item.UseTwa">
                    <span class="caption">{{'Override TWA HOT Start Time label'|translate}}</span>
                    <input type="text" [(ngModel)]="item.MobileOverrideTWAStartTimeLabel" name="item.MobileOverrideTWAStartTimeLabel" placeholder="TWA HOT Start Time" style="width: 150px;" />
                </label>
                <label>
                    <span class="caption">{{'Override fluid season'|translate}}</span>
                    <select [(ngModel)]="item.FluidSeasonId" name="item.FluidSeasonId">
                        <option [ngValue]="null">{{'Default'|translate}}</option>
                        <option *ngFor="let fluidSeason of fluidSeasons" [ngValue]="fluidSeason.Id" [disabled]="fluidSeason.Id < 2023">
                            {{fluidSeason.Name}}
                        </option>
                    </select>
                </label>
                <label>
                    <span class="caption">{{'Disable fluid override'|translate}}</span>
                    <input type="checkbox" [(ngModel)]="item.DisableFluidOverride" name="item.DisableFluidOverride" />
                </label>
                <label *ngIf="!item.DisableFluidOverride">
                    <span class="caption">{{'Fluid override button label'|translate}}</span>
                    <input type="text" [(ngModel)]="item.MobileFluidOverrideButtonLabel" name="item.MobileFluidOverrideButtonLabel" style="width: 200px;" />
                </label>
                <label>
                    <span class="caption">{{'Collapse fluids after selection'|translate}}</span>
                    <input type="checkbox" [(ngModel)]="item.CollapseFluidsAfterSelection" name="item.CollapseFluidsAfterSelection" />
                </label>
                <label>
                    <span class="caption">{{'Show HOT end time by fluid'|translate}}</span>
                    <input type="checkbox" [(ngModel)]="item.ShowHotEndTimeByFluid" name="item.ShowHotEndTimeByFluid" />
                </label>
                <label>
                    <span class="caption">{{'Generic fluid type 1 aluminum'|translate}}</span>
                    <select [(ngModel)]="item.MobileGenericFluidType1AluminumId" name="item.MobileGenericFluidType1AluminumId">
                        <option [ngValue]="null">{{'None'|translate}}</option>
                        <option *ngFor="let fluid of enabledFluids|filter:{Type:'Type1'}|orderBy:['FluidProfileName', 'Type', 'Name']" [ngValue]="fluid.Id">
                            {{fluid.FluidProfileName + ' - ' + fluid.Type + ' - ' + fluid.Name + ' (' + fluid.Dilution + ')'}}
                        </option>
                    </select>
                </label>
                <label>
                    <span class="caption">{{'Generic fluid type 1 composite'|translate}}</span>
                    <select [(ngModel)]="item.MobileGenericFluidType1CompositeId" name="item.MobileGenericFluidType1CompositeId">
                        <option [ngValue]="null">{{'None'|translate}}</option>
                        <option *ngFor="let fluid of enabledFluids|filter:{Type:'Type1'}|orderBy:['FluidProfileName', 'Type', 'Name']" [ngValue]="fluid.Id">
                            {{fluid.FluidProfileName + ' - ' + fluid.Type + ' - ' + fluid.Name + ' (' + fluid.Dilution + ')'}}
                        </option>
                    </select>
                </label>
                <label>
                    <span class="caption">{{'Generic fluid type 2'|translate}}</span>
                    <select [(ngModel)]="item.MobileGenericFluidType2Id" name="item.MobileGenericFluidType2Id">
                        <option [ngValue]="null">{{'None'|translate}}</option>
                        <option *ngFor="let fluid of enabledFluids|filter:{Type:'Type2'}|orderBy:['FluidProfileName', 'Type', 'Name']" [ngValue]="fluid.Id">
                            {{fluid.FluidProfileName + ' - ' + fluid.Type + ' - ' + fluid.Name + ' (' + fluid.Dilution + ')'}}
                        </option>
                    </select>
                </label>
                <label>
                    <span class="caption">{{'Generic fluid type 3'|translate}}</span>
                    <select [(ngModel)]="item.MobileGenericFluidType3Id" name="item.MobileGenericFluidType3Id">
                        <option [ngValue]="null">{{'None'|translate}}</option>
                        <option *ngFor="let fluid of enabledFluids|filter:{Type:'Type3'}|orderBy:['FluidProfileName', 'Type', 'Name']" [ngValue]="fluid.Id">
                            {{fluid.FluidProfileName + ' - ' + fluid.Type + ' - ' + fluid.Name + ' (' + fluid.Dilution + ')'}}
                        </option>
                    </select>
                </label>
                <label>
                    <span class="caption">{{'Generic fluid type 4'|translate}}</span>
                    <select [(ngModel)]="item.MobileGenericFluidType4Id" name="item.MobileGenericFluidType4Id">
                        <option [ngValue]="null">{{'None'|translate}}</option>
                        <option *ngFor="let fluid of enabledFluids|filter:{Type:'Type4'}|orderBy:['FluidProfileName', 'Type', 'Name']" [ngValue]="fluid.Id">
                            {{fluid.FluidProfileName + ' - ' + fluid.Type + ' - ' + fluid.Name + ' (' + fluid.Dilution + ')'}}
                        </option>
                    </select>
                </label>
            </div>

            <div class="section">
                <h3>{{'Timer'|translate}}</h3>
                <label>
                    <span class="caption">{{'Use larger timer section'|translate}}</span>
                    <input type="checkbox" [(ngModel)]="item.MobileUseLargerTimerSection" name="item.MobileUseLargerTimerSection" />
                </label>
                <label>
                    <span class="caption">{{'Turn timer section red when expired'|translate}}</span>
                    <input type="checkbox" [(ngModel)]="item.MobileTurnTimerSectionRedWhenExpired" name="item.MobileTurnTimerSectionRedWhenExpired" />
                </label>
                <label>
                    <span class="caption">{{'Show restrictive message in timer row'|translate}}</span>
                    <input type="checkbox" [(ngModel)]="item.MobileShowRestrictiveMessageInTimerRow" name="item.MobileShowRestrictiveMessageInTimerRow" />
                </label>
                <label>
                    <span class="caption">{{'Timer time zone'|translate}}</span>
                    <select required [(ngModel)]="item.MobileTimerTimeZone" name="item.MobileTimerTimeZone">
                        <option *ngFor="let option of mobileTimerTimeZoneOptions|keys" [ngValue]="option">
                            {{mobileTimerTimeZoneOptions[option]}}
                        </option>
                    </select>
                </label>
                <label>
                    <span class="caption">{{'Update timer on weather refresh'|translate}}</span>
                    <input type="checkbox" [(ngModel)]="item.MobileUpdateTimerOnWeatherRefresh" name="item.MobileUpdateTimerOnWeatherRefresh" />
                </label>
                <label *ngIf="item.MobileUpdateTimerOnWeatherRefresh">
                    <span class="caption">{{'Prevent HOT increase'|translate}}</span>
                    <input type="checkbox" [(ngModel)]="item.MobileUpdateTimerPreventHotIncrease" name="item.MobileUpdateTimerPreventHotIncrease" />
                </label>
                <label *ngIf="item.MobileUpdateTimerOnWeatherRefresh">
                    <span class="caption">{{'HOT increase dialog title'|translate}}</span>
                    <input type="text" [(ngModel)]="item.MobileTimerHotIncreaseDialogTitle" name="item.MobileTimerHotIncreaseDialogTitle" style="width: 200px;" />
                </label>
                <label *ngIf="item.MobileUpdateTimerOnWeatherRefresh">
                    <span class="caption">{{'HOT increase dialog message'|translate}}</span>
                    <textarea [(ngModel)]="item.MobileTimerHotIncreaseDialogMessage" name="item.MobileTimerHotIncreaseDialogMessage" style="width: 350px; height: 55px; margin-bottom: 10px;"></textarea>
                </label>
                <label *ngIf="item.MobileUpdateTimerOnWeatherRefresh">
                    <span class="caption">{{'HOT decrease dialog title'|translate}}</span>
                    <input type="text" [(ngModel)]="item.MobileTimerHotDecreaseDialogTitle" name="item.MobileTimerHotDecreaseDialogTitle" style="width: 200px;" />
                </label>
                <label *ngIf="item.MobileUpdateTimerOnWeatherRefresh">
                    <span class="caption">{{'HOT decrease dialog message'|translate}}</span>
                    <textarea [(ngModel)]="item.MobileTimerHotDecreaseDialogMessage" name="item.MobileTimerHotDecreaseDialogMessage" style="width: 350px; height: 55px; margin-bottom: 10px;"></textarea>
                </label>
            </div>

            <div class="section">
                <h3>{{'Deicing reports (for no HOT conditions)'|translate}}</h3>
                <label>
                    <span class="caption">{{'Deicing report ?'|translate}}</span>
                    <input type="checkbox" [(ngModel)]="item.DeicingReportForNoHotCondition" name="item.DeicingReportForNoHotCondition" />
                </label>
                <div *ngIf="item.DeicingReportForNoHotCondition">
                    <label>
                        <span class="caption">{{'Deicing report button name'|translate}}</span>
                        <input type="text" [(ngModel)]="item.DeicingReportConfirmButtonName" name="item.DeicingReportConfirmButtonName" maxlength="10" style="width: 72px;" />
                    </label>
                    <label>
                        <span class="caption">{{'Deicing report message'|translate}}</span>
                        <textarea [(ngModel)]="item.DeicingReportForNoHotConditionMessage" name="item.DeicingReportForNoHotConditionMessage" style="width: 400px; height: 75px;"></textarea>
                    </label>
                </div>
            </div>

            <div class="section">
                <h3>{{'Deicing requests'|translate}}</h3>
                <label>
                    <span class="caption">{{'Send holdover start time to app'|translate}}</span>
                    <input type="checkbox" [(ngModel)]="item.MobilePopulateHoldoverStartTime" name="item.MobilePopulateHoldoverStartTime" />
                </label>
                <label>
                    <span class="caption">{{'Show current request on all matching devices'|translate}}</span>
                    <input type="checkbox" [(ngModel)]="item.MobileShowDeicingRequestOnAllDevices" name="item.MobileShowDeicingRequestOnAllDevices" />
                </label>
                <label *ngIf="item.MobilePopulateHoldoverStartTime">
                    <span class="caption">{{'Warning after receiving holdover start time ?'|translate}}</span>
                    <input type="checkbox" [(ngModel)]="item.MobileWarningAfterPopulateHoldoverStartTime" name="item.MobileWarningAfterPopulateHoldoverStartTime" />
                </label>
                <label *ngIf="item.MobileWarningAfterPopulateHoldoverStartTime">
                    <span class="caption">{{'Warning after receiving holdover start time message'|translate}}</span>
                    <textarea [required]="item.MobileWarningAfterPopulateHoldoverStartTime" [(ngModel)]="item.MobileWarningAfterPopulateHoldoverStartTimeMessage" name="item.MobileWarningAfterPopulateHoldoverStartTimeMessage" style="width: 350px; height: 55px; margin-bottom: 10px;"></textarea>
                </label>
            </div>

            <div class="section">
                <h3>{{'HOT notifications and pop-ups'|translate}}</h3>
                <label>
                    <span class="caption">{{'Available tokens'|translate}}</span>
                    <span class="value">{{'{hotType} ("HOT" or "ALLOWANCE TIME")'}}</span>
                </label>
                <table class="table">
                    <tr>
                        <th></th>
                        <th>{{'Popup?'}}</th>
                        <th>{{'Notification?'}}</th>
                        <th>{{'Label'}}</th>
                        <th>{{'Minutes before'}}</th>
                        <th>{{'Under timer message (HOT)'}}</th>
                        <th>{{'Under timer message (PTOCC not authorized)'}}</th>
                    </tr>
                    <tr>
                        <th>{{'Timer start'}}</th>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td><input type="text" [(ngModel)]="item.TimerStartTimerMessage" name="item.TimerStartTimerMessage" /></td>
                        <td><input type="text" [(ngModel)]="item.TimerStartTimerMessagePTOCCNotAuthorized" name="item.TimerStartTimerMessagePTOCCNotAuthorized" /></td>
                    </tr>
                    <tr>
                        <th>{{'Min METAR HOT'}}</th>
                        <td><input type="checkbox" [(ngModel)]="item.ShowMinHotExpirationPopup" name="item.ShowMinHotExpirationPopup" /></td>
                        <td><input type="checkbox" [(ngModel)]="item.ShowMinHotExpirationNotification" name="item.ShowMinHotExpirationNotification" /></td>
                        <td><input type="text" required *ngIf="item.ShowMinHotExpirationPopup || item.ShowMinHotExpirationNotification" [(ngModel)]="item.MinHotExpirationLabel" name="item.MinHotExpirationLabel" /></td>
                        <td></td>
                        <td><input type="text" [(ngModel)]="item.MinHotExpirationTimerMessage" name="item.MinHotExpirationTimerMessage" /></td>
                        <td><input type="text" [(ngModel)]="item.MinHotExpirationTimerMessagePTOCCNotAuthorized" name="item.MinHotExpirationTimerMessagePTOCCNotAuthorized" /></td>
                    </tr>
                    <tr>
                        <th>{{'Before min METAR HOT'}}</th>
                        <td><input type="checkbox" [(ngModel)]="item.ShowBeforeMinHotExpirationPopup" name="item.ShowBeforeMinHotExpirationPopup" /></td>
                        <td><input type="checkbox" [(ngModel)]="item.ShowBeforeMinHotExpirationNotification" name="item.ShowBeforeMinHotExpirationNotification" /></td>
                        <td><input type="text" required *ngIf="item.ShowBeforeMinHotExpirationPopup || item.ShowBeforeMinHotExpirationNotification" [(ngModel)]="item.BeforeMinHotExpirationLabel" name="item.BeforeMinHotExpirationLabel" /></td>
                        <td><input type="number" required *ngIf="item.ShowBeforeMinHotExpirationPopup || item.ShowBeforeMinHotExpirationNotification" min="0" [(ngModel)]="item.MinutesBeforeMinHotAlert" name="item.MinutesBeforeMinHotAlert" /></td>
                        <td><input type="text" [(ngModel)]="item.BeforeMinHotExpirationTimerMessage" name="item.BeforeMinHotExpirationTimerMessage" /></td>
                        <td><input type="text" [(ngModel)]="item.BeforeMinHotExpirationTimerMessagePTOCCNotAuthorized" name="item.BeforeMinHotExpirationTimerMessagePTOCCNotAuthorized" /></td>
                    </tr>
                    <tr>
                        <th>{{'Max METAR HOT'}} <a title="{{mobileNotificationTooltip}}" class="help mdi mdi-help-circle"></a></th>
                        <td><input type="checkbox" *ngIf="!client?.ShowOnlyMinHot" [(ngModel)]="item.ShowMaxHotExpirationPopup" name="item.ShowMaxHotExpirationPopup" /></td>
                        <td><input type="checkbox" *ngIf="!client?.ShowOnlyMinHot" [(ngModel)]="item.ShowMaxHotExpirationNotification" name="item.ShowMaxHotExpirationNotification" /></td>
                        <td><input type="text" required *ngIf="!client?.ShowOnlyMinHot && (item.ShowMaxHotExpirationPopup || item.ShowMaxHotExpirationNotification)" [(ngModel)]="item.MaxHotExpirationLabel" name="item.MaxHotExpirationLabel" /></td>
                        <td></td>
                        <td><input type="text" *ngIf="!client?.ShowOnlyMinHot" [(ngModel)]="item.MaxHotExpirationTimerMessage" name="item.MaxHotExpirationTimerMessage" /></td>
                        <td><input type="text" *ngIf="!client?.ShowOnlyMinHot" [(ngModel)]="item.MaxHotExpirationTimerMessagePTOCCNotAuthorized" name="item.MaxHotExpirationTimerMessagePTOCCNotAuthorized" /></td>
                    </tr>
                    <tr>
                        <th>{{'Before max METAR HOT'}} <a title="{{mobileNotificationTooltip}}" class="help mdi mdi-help-circle"></a></th>
                        <td><input type="checkbox" *ngIf="!client?.ShowOnlyMinHot" [(ngModel)]="item.ShowBeforeMaxHotExpirationPopup" name="item.ShowBeforeMaxHotExpirationPopup" /></td>
                        <td><input type="checkbox" *ngIf="!client?.ShowOnlyMinHot" [(ngModel)]="item.ShowBeforeMaxHotExpirationNotification" name="item.ShowBeforeMaxHotExpirationNotification" /></td>
                        <td><input type="text" *ngIf="!client?.ShowOnlyMinHot" [required]="item.ShowBeforeMaxHotExpirationPopup || item.ShowBeforeMaxHotExpirationNotification" [(ngModel)]="item.BeforeMaxHotExpirationLabel" name="item.BeforeMaxHotExpirationLabel" /></td>
                        <td><input type="number" *ngIf="!client?.ShowOnlyMinHot" [required]="item.ShowBeforeMaxHotExpirationPopup || item.ShowBeforeMaxHotExpirationNotification" min="0" [(ngModel)]="item.MinutesBeforeMaxHotAlert" name="item.MinutesBeforeMaxHotAlert" /></td>
                        <td><input type="text" *ngIf="!client?.ShowOnlyMinHot" [(ngModel)]="item.BeforeMaxHotExpirationTimerMessage" name="item.BeforeMaxHotExpirationTimerMessage" /></td>
                        <td><input type="text" *ngIf="!client?.ShowOnlyMinHot" [(ngModel)]="item.BeforeMaxHotExpirationTimerMessagePTOCCNotAuthorized" name="item.BeforeMaxHotExpirationTimerMessagePTOCCNotAuthorized" /></td>
                    </tr>
                    <tr>
                        <th>{{'End of LWE HOT'}}</th>
                        <td><input type="checkbox" [(ngModel)]="item.ShowTotalHotExpirationPopup" name="item.ShowTotalHotExpirationPopup" /></td>
                        <td><input type="checkbox" [(ngModel)]="item.ShowTotalHotExpirationNotification" name="item.ShowTotalHotExpirationNotification" /></td>
                        <td><input type="text" required *ngIf="item.ShowTotalHotExpirationPopup || item.ShowTotalHotExpirationNotification" [(ngModel)]="item.TotalHotExpirationLabel" name="item.TotalHotExpirationLabel" /></td>
                        <td></td>
                        <td><input type="text" [(ngModel)]="item.TotalHotExpirationTimerMessage" name="item.TotalHotExpirationTimerMessage" /></td>
                        <td></td>
                    </tr>
                    <tr>
                        <th>{{'Before LWE HOT'}}</th>
                        <td><input type="checkbox" [(ngModel)]="item.ShowBeforeTotalHotExpirationPopup" name="item.ShowBeforeTotalHotExpirationPopup" /></td>
                        <td><input type="checkbox" [(ngModel)]="item.ShowBeforeTotalHotExpirationNotification" name="item.ShowBeforeTotalHotExpirationNotification" /></td>
                        <td><input type="text" required *ngIf="item.ShowBeforeTotalHotExpirationPopup || item.ShowBeforeTotalHotExpirationNotification" [(ngModel)]="item.BeforeTotalHotExpirationLabel" name="item.BeforeTotalHotExpirationLabel" /></td>
                        <td><input type="number" required *ngIf="item.ShowBeforeTotalHotExpirationPopup || item.ShowBeforeTotalHotExpirationNotification" min="0" [(ngModel)]="item.MinutesBeforeTotalHotAlert" name="item.MinutesBeforeTotalHotAlert" /></td>
                        <td><input type="text" [(ngModel)]="item.BeforeTotalHotExpirationTimerMessage" name="item.BeforeTotalHotExpirationTimerMessage" /></td>
                        <td></td>
                    </tr>
                </table>
            </div>

            <div class="section">
                <h3>{{'Custom URL Settings'|translate}}</h3>
                <table class="table">
                    <tr>
                        <th>{{'Client App URL scheme'|translate}}</th>
                        <th>{{'Client App ID'|translate}}</th>
                        <th>{{'Client App URL Validation Regex'}}</th>
                    </tr>
                    <tr>
                        <td><input type="text" [(ngModel)]="item.APIClientUrlScheme" name="item.APIClientUrlScheme" /></td>
                        <td><input type="text" [(ngModel)]="item.APIClientAppId" name="item.APIClientAppId" /></td>
                        <td><input type="text" [(ngModel)]="item.APIClientUrlValidationRegex" name="item.APIClientUrlValidationRegex" /></td>
                    </tr>
                </table>
            </div>

            <div class="section">
                <h3>{{'Adjusted HOTs Settings'|translate}}</h3>
                <label>
                    <span class="caption">{{'Enable Standard/Adjusted HOT toggle'|translate}}</span>
                    <input type="checkbox" [(ngModel)]="item.AllowAdjustedHot" name="item.AllowAdjustedHot" />
                </label>
                <div *ngIf="item.AllowAdjustedHot">
                    <label>
                        <span class="caption">{{'Using Standard HOTs message'|translate}}</span>
                        <input type="text" [(ngModel)]="item.StandardAdjustedHotMessage" name="item.StandardAdjustedHotMessage" [required]="item.AllowAdjustedHot" />
                    </label>
                    <label>
                        <span class="caption">{{'Using Adjusted HOTs message'|translate}}</span>
                        <input type="text" [(ngModel)]="item.AdjustedHotMessage" name="item.AdjustedHotMessage" [required]="item.AllowAdjustedHot" />
                    </label>
                    <label>
                        <span class="caption">{{'Adjusted HOTs warning message'|translate}}</span>
                        <input type="text" [(ngModel)]="item.AdjustedHotWarnMessage" name="item.AdjustedHotWarnMessage" />
                    </label>
                    <label>
                        <span class="caption">{{'Indicate fluid names as adjusted'|translate}}</span>
                        <input type="checkbox" [(ngModel)]="item.UseAdjustedFluidPrefix" name="item.UseAdjustedFluidPrefix" />
                    </label>
                </div>
            </div>
        </fieldset>

        <div [hidden]="tab != 'deicingRequestProviders'" ngModelGroup="deicingRequestProvidersForm">
            <fieldset class="contentMargins" [disabled]="!('ConfigEditClientApiTokens'|hasPermission)" ngModelGroup="deicingRequestProvidersForm">
                <table class="table">
                    <tr>
                        <th>{{'Name'|translate}}</th>
                        <th></th>
                    </tr>
                    <tr *ngFor="let provider of item.MobileDeicingRequestProviders; let i = index">
                        <td>
                            <input type="text" [(ngModel)]="provider.Name" [name]="'provider[' + i +'].Name'" required />
                        </td>
                        <td>
                            <a *ngIf="('ConfigDeleteClientApiTokens'|hasPermission)" (click)="removeMobileDeicingRequestProvider(provider)" class="mdi mdi-delete"></a>
                        </td>
                    </tr>
                </table>
            </fieldset>
            <div class="toolbar">
                <a class="tab" *ngFor="let provider of item.MobileDeicingRequestProviders" (click)="switchMobileDeicingRequestProviderTab(provider)" [class.active]="mobileDeicingRequestProviderTab === provider" [class.invalid]="provider.form && !provider.form.valid">{{provider.Name || 'new'}}</a>
            </div>
            <div *ngFor="let provider of item.MobileDeicingRequestProviders; let i = index">
                <fieldset [disabled]="!('ConfigEditClientApiTokens'|hasPermission)" [hidden]="mobileDeicingRequestProviderTab !== provider" class="tabContent contentMargins" ngModelGroup="provider.form">
                    <div class="section">
                        <label>
                            <span class="caption">{{'Deicing request service type'|translate}}</span>
                            <select required [(ngModel)]="provider.MobileDeicingRequestServiceType" [name]="'provider[' + i + '].MobileDeicingRequestServiceType'">
                                <option *ngFor="let option of deicingRequestServiceTypes|keys" [ngValue]="option">
                                    {{deicingRequestServiceTypes[option]}}
                                </option>
                            </select>
                        </label>
                        <label>
                            <span class="caption">{{'Deicing request service URL'|translate}}</span>
                            <input required type="text" [(ngModel)]="provider.MobileDeicingRequestUrl" [name]="'provider[' + i + '].MobileDeicingRequestUrl'" style="width: 350px;" />
                        </label>
                        <label>
                            <span class="caption">{{'Deicing request service username'|translate}}</span>
                            <input required type="text" [(ngModel)]="provider.MobileDeicingRequestUsername" [name]="'provider[' + i + '].MobileDeicingRequestUsername'" style="width: 90px;" />
                        </label>
                        <label>
                            <span class="caption">{{'Deicing request service password'|translate}}</span>
                            <input required type="text" [(ngModel)]="provider.MobileDeicingRequestPassword" [name]="'provider[' + i + '].MobileDeicingRequestPassword'" style="width: 90px;" />
                        </label>
                        <label>
                            <span class="caption">{{'Send primary aircraft identifier'|translate}}</span>
                            <input type="checkbox" [(ngModel)]="provider.MobileDeicingRequestUsePrimaryAircraftIdentifier" [name]="'provider[' + i + '].MobileDeicingRequestUsePrimaryAircraftIdentifier'" />
                        </label>
                        <label>
                            <span class="caption">{{'Send flight number with ICAO airline code'|translate}}</span>
                            <input type="checkbox" [(ngModel)]="provider.MobileDeicingRequestUseICAOCode" [name]="'provider[' + i + '].MobileDeicingRequestUseICAOCode'" />
                        </label>
                        <label>
                            <span class="caption">{{'Send flight number without leading zeros'|translate}}</span>
                            <input type="checkbox" [(ngModel)]="provider.MobileDeicingRequestRemoveLeadingZeros" [name]="'provider[' + i + '].MobileDeicingRequestRemoveLeadingZeros'" />
                        </label>
                        <h3>{{'Deicing responses'|translate}}</h3>
                        <label>
                            <span class="caption">{{'Override provided TTOT to Unavailable'|translate}}</span>
                            <input type="checkbox" [(ngModel)]="provider.OverrideProvidedTTOTToUnavailable" [name]="'provider[' + i + '].OverrideProvidedTTOTToUnavailable'" />
                        </label>
                        <label>
                            <span class="caption">{{'Override provided frequency to Unavailable'|translate}}</span>
                            <input type="checkbox" [(ngModel)]="provider.OverrideProvidedFrequencyToUnavailable" [name]="'provider[' + i + '].OverrideProvidedFrequencyToUnavailable'" />
                        </label>
                        <label>
                            <span class="caption">{{'Deicing response other message'|translate}} <a title="{{'Will be used to replace a part of deicing response messages : '+'https://surewx.ongemini.com/workspace/0/item/29183'|translate}}" class="help mdi mdi-help-circle"></a></span>
                            <input type="text" [(ngModel)]="provider.MobileDeicingResponseOtherMessage" [name]="'provider[' + i + '].MobileDeicingResponseOtherMessage'" style="width: 350px;" />
                        </label>
                        <ng-container *ngIf="provider.MobileDeicingRequestServiceType === 'AeroMag' || provider.MobileDeicingRequestServiceType === 'Test' || provider.MobileDeicingRequestServiceType === 'Finavia2'">
                            <label>
                                <span class="caption">{{'Allow order modification'|translate}}</span>
                                <input type="checkbox" [(ngModel)]="provider.AllowOrderModification" [name]="'provider[' + i + '].AllowOrderModification'" />
                            </label>
                            <div class="field" *ngIf="provider.AllowOrderModification && provider.MobileDeicingRequestServiceType == 'Finavia2'">
                                <span class="caption">{{'Allow order modification for statuses'|translate}}</span>
                                <multiselect-enum [enum]="deicingResponseStatusOptions" [(ngModel)]="provider.AllowOrderModificationOptions" [name]="'provider[' + i + '].AllowOrderModificationOptions'" width="400" required></multiselect-enum>
                            </div>
                            <label>
                                <span class="caption">{{'Allow order cancellation'|translate}}</span>
                                <input type="checkbox" [(ngModel)]="provider.AllowOrderCancellation" [name]="'provider[' + i + '].AllowOrderCancellation'" />
                            </label>
                            <div class="field" *ngIf="provider.AllowOrderCancellation && provider.MobileDeicingRequestServiceType == 'Finavia2'">
                                <span class="caption">{{'Allow order cancellation for statuses'|translate}}</span>
                                <multiselect-enum [enum]="deicingResponseStatusOptions" [(ngModel)]="provider.AllowOrderCancellationOptions" [name]="'provider[' + i + '].AllowOrderCancellationOptions'" width="400" required></multiselect-enum>
                            </div>
                            <label *ngIf="provider.AllowOrderCancellation">
                                <span class="caption">{{'Order cancellation confirmation message'|translate}}</span>
                                <input required type="text" [(ngModel)]="provider.OrderCancellationConfirmationMessage" [name]="'provider[' + i + '].OrderCancellationConfirmationMessage'" style="width: 650px;" />
                            </label>
                        </ng-container>
                        <ng-container *ngIf="provider.MobileDeicingRequestServiceType === 'Finavia'">
                            <label>
                                <span class="caption">{{'Stop monitoring deicing request once status is Ready'|translate}}</span>
                                <input type="checkbox" [(ngModel)]="provider.StopMonitoringOnceReady" [name]="'provider[' + i + '].StopMonitoringOnceReady'" />
                            </label>
                        </ng-container>
                        <label>
                            <span class="caption">{{'Selection limit'|translate}}</span>
                            <input type="number" min="1" step="1" [(ngModel)]="provider.MobileDeicingRequestSelectionLimit" [name]="'provider[' + i + '].MobileDeicingRequestSelectionLimit'" style="width: 60px;" />
                        </label>

                        <h3>{{'Airports'|translate}}</h3>
                        <table class="table">
                            <tr>
                                <th>{{'Name'|translate}}</th>
                                <th></th>
                            </tr>
                            <tr *ngFor="let mobileDeicingRequestProviderAirport of provider.MobileDeicingRequestProviderAirports; let i = index">
                                <td>
                                    {{airportsDict[mobileDeicingRequestProviderAirport.AirportId].ICAOCode + '/' + airportsDict[mobileDeicingRequestProviderAirport.AirportId].IATACode + ' - ' + airportsDict[mobileDeicingRequestProviderAirport.AirportId].Name}}
                                </td>
                                <td>
                                    <a *ngIf="('ConfigDeleteClientApiTokens'|hasPermission)" (click)="removeMobileDeicingRequestProviderAirport(provider, mobileDeicingRequestProviderAirport)" class="mdi mdi-delete"></a>
                                </td>
                            </tr>
                        </table>
                        <br />
                        <select [(ngModel)]="airportOption" name="airportOption">
                            <option *ngFor="let clientAirport of clientAirportsNotEnabled()" [ngValue]="clientAirport">
                                {{airportsDict[clientAirport.AirportId].ICAOCode + '/' + airportsDict[clientAirport.AirportId].IATACode + ' - ' + airportsDict[clientAirport.AirportId].Name}}
                            </option>
                        </select>
                        <input type="button" *ngIf="('ConfigEditClientApiTokens'|hasPermission)" (click)="addMobileDeicingRequestProviderAirport(provider, airportOption); airportOption = null;" value="{{'Add airport'|translate}}" [disabled]="airportOption == null" />
                        <br />
                        <br />

                        <h3>{{'Options'|translate}}</h3>
                        <table class="table">
                            <tr>
                                <th>{{'Code'|translate}}</th>
                                <th>{{'Display label'|translate}}</th>
                                <th>{{'Equipment types'|translate}}</th>
                                <th>{{'Available?'|translate}}</th>
                                <th>{{'Order'|translate}}</th>
                                <th></th>
                            </tr>
                            <tr *ngFor="let mobileDeicingRequestProviderOption of provider.MobileDeicingRequestProviderOptions; let j = index">
                                <td><input type="text" [(ngModel)]="mobileDeicingRequestProviderOption.Code" [name]="'mobileDeicingRequestProviderOption[' + i + '][' + j + '].Code'" required style="width: 100px;" /></td>
                                <td><input type="text" [(ngModel)]="mobileDeicingRequestProviderOption.Description" [name]="'mobileDeicingRequestProviderOption[' + i + '][' + j + '].Description'" required style="width: 250px;" /></td>
                                <td>
                                    <multiselect width="220" [none]="'All'" [(ngModel)]="mobileDeicingRequestProviderOption.MobileDeicingRequestProviderOptionEquipmentTypes" [name]="'mobileDeicingRequestProviderOption[' + i + '][' + j + '].MobileDeicingRequestProviderOptionEquipmentTypes'">
                                        <multiselect-option *ngFor="let equipmentType of equipmentTypes|orderBy:['IATACode', 'ICAOCode']" [value]="equipmentType.Id" [name]="(equipmentType.IATACode + '/' + (equipmentType.ICAOCode || ('N/A'|translate))) + ' ' + equipmentType.ManufacturerAndModelDescription"></multiselect-option>
                                    </multiselect>
                                </td>
                                <td><input type="checkbox" [(ngModel)]="mobileDeicingRequestProviderOption.Available" [name]="'mobileDeicingRequestProviderOption[' + i + '][' + j + '].Available'" /></td>
                                <td><input type="number" required min="0" step="1" [(ngModel)]="mobileDeicingRequestProviderOption.Order" [name]="'mobileDeicingRequestProviderOption[' + i + '][' + j + '].Order'" style="width: 50px;" /></td>
                                <td>
                                    <a *ngIf="('ConfigEditClientApiTokens'|hasPermission)" (click)="removeMobileDeicingRequestProviderOption(provider, mobileDeicingRequestProviderOption)" class="mdi mdi-delete"></a>
                                </td>
                            </tr>
                        </table>
                        <br />
                        <input type="button" *ngIf="('ConfigEditClientApiTokens'|hasPermission)" (click)="addMobileDeicingRequestProviderOption(provider)" value="{{'Add option'|translate}}" />
                    </div>
                </fieldset>
            </div>
        </div>
    </form>
</div>
