import {Component, Inject, OnInit} from "@angular/core";
import {DIALOG_DATA} from "@angular/cdk/dialog";
import {
    DialogComponent,
    DialogContentComponent,
    DialogHeaderComponent,
    SwxModule
} from "swx.front-end-lib";
import {CdkDrag, CdkDragHandle} from "@angular/cdk/drag-drop";

@Component({
    standalone: true,
    templateUrl: 'ReportDeicingHotErrorsDialog.component.html',
    styleUrl: "./ReportDeicingHotErrorsDialog.component.css",
    imports: [DialogComponent, DialogHeaderComponent, SwxModule, DialogContentComponent, CdkDrag, CdkDragHandle]
})
export class ReportDeicingHotErrorsDialogComponent implements OnInit {
    hot!: any;
    
    constructor(@Inject(DIALOG_DATA) private data: any) {
    }
    
    ngOnInit() {
        this.hot = this.data.hot;
    }
}
