import {Component, Inject, OnInit} from "@angular/core";
import {FormsModule} from "@angular/forms";
import {DialogComponent, DialogContentComponent, DialogFooterComponent, DialogHeaderComponent, FileDownloadService, SwxModule} from "swx.front-end-lib";
import {DIALOG_DATA} from "@angular/cdk/dialog";
import {CdkDrag, CdkDragHandle} from "@angular/cdk/drag-drop";

@Component({
    templateUrl: './RawAcarsDialog.component.html',
    standalone: true,
    imports: [FormsModule, SwxModule, DialogComponent, DialogHeaderComponent, DialogContentComponent, DialogFooterComponent, CdkDrag, CdkDragHandle]
})
export class RawAcarsDialogComponent implements OnInit {
    rawData!: string;
    title!: string;
    
    constructor(
        @Inject(DIALOG_DATA) private data: { rawData: string, title?: string },
        private download: FileDownloadService) {
    }

    ngOnInit() {
        this.title = this.data.title || 'ACARS';
        this.rawData = this.data.rawData;
    }

    onDownload(data: string, fileName: string) {
        this.download.download(new Blob([data]), fileName)
    }
}
