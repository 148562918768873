import {Component, Inject, OnInit} from "@angular/core";
import {DIALOG_DATA} from "@angular/cdk/dialog";
import {DialogComponent, DialogContentComponent, DialogHeaderComponent} from "swx.front-end-lib";

@Component({
    templateUrl: './TemplatePreviewDialog.component.html',
    imports: [
        DialogComponent,
        DialogHeaderComponent,
        DialogContentComponent,
    ],
    standalone: true
})
export class TemplatePreviewDialogComponent implements OnInit {
    title!: string;
    cols!: number;
    rows!: number;
    output!: string;
    
    constructor(@Inject(DIALOG_DATA) private data: { cols: number, rows: number, output: string, title: string }) {
    }
    
    ngOnInit() {
        this.title = this.data.title;
        this.cols = this.data.cols;
        this.rows = this.data.rows;
        this.output = this.data.output;
    }
}
