<div class="noMargins">
    <page-title>{{'Algorithm performance'|translate}}</page-title>
    <form #form="ngForm">
        <div class="toolbar">
            <label class="inline">
                <span class="caption">{{'Station'|translate}}</span>
                <multiselect [(ngModel)]="query.StationId" name="StationId">
                    <multiselect-option *ngFor="let station of stations|orderBy:'Name'" [value]="station.Id" [name]="station.Name"></multiselect-option>
                </multiselect>
            </label>
            <label class="inline">
                <span class="caption">{{'Sensor select matrix'|translate}}</span>
                <multiselect [(ngModel)]="query.SensorSelectMatrixId" name="SensorSelectMatrixId">
                    <multiselect-option *ngFor="let sensorSelectMatrix of sensorSelectMatrices|orderBy:'Name'" [value]="sensorSelectMatrix.Id" [name]="sensorSelectMatrix.Name"></multiselect-option>
                </multiselect>
            </label>
            <label class="inline">
                <span class="caption">{{'Weather categories'|translate}}</span>
                <multiselect [(ngModel)]="query.WeatherCategory" name="WeatherCategory">
                    <multiselect-option *ngFor="let weatherCategory of weatherCategories|keys" [value]="weatherCategory" [name]="weatherCategories[weatherCategory]"></multiselect-option>
                </multiselect>
            </label>
            <label class="inline">
                <span class="caption">{{'Max temp. (°C)'|translate}}</span>
                <input type="number" step="any" [(ngModel)]="query.MaxTemp" name="MaxTemp" style="width: 50px;" />
            </label>
            <!--<label class="inline">
                <span class="caption">{{'METAR client weather configuration'|translate}}</span>
                <select [(ngModel)]="query.MetarWeatherMappingProfileId" name="query.MetarWeatherMappingProfileId">
                    <option [ngValue]="null">{{'None'|translate}}</option>
                    <option *ngFor="let option of metarWeatherMappingProfiles|orderBy:'Name'" [ngValue]="option.Id">
                        {{option.Name}}
                    </option>
                </select>
            </label>-->
            <label class="inline">
                <span class="caption">{{'From'|translate}}</span>
                <input date [options]="datePickerOptions" [(ngModel)]="query.FromDate" required placeholder="Start Date" name="query.FromDate">
            </label>
            <label class="inline">
                <span class="caption">{{'To'|translate}}</span>
                <input date [options]="datePickerOptions" [(ngModel)]="query.ToDate" name="query.ToDate">
            </label>
            <filters [(ngModel)]="query.Filters" [columnDefs]="columnDefs" name="query.Filters"></filters>
            <button type="button" (click)="resetQuery()"><span class="mdi">❌</span> {{'Reset'|translate}}</button>
            <button type="button" (click)="refresh()" [disabled]="!form.valid"><span class="mdi mdi-refresh"></span> {{'Apply'|translate}}</button>
            <button type="button" (click)="grid.export()" [disabled]="!form.valid"><span class="mdi mdi-download"></span>{{'Export'|translate}}</button>
            <button type="button" (click)="grid.selectColumns()" [disabled]="!form.valid"><span class="mdi mdi-table-edit"></span>{{'Columns'|translate}}</button>
        </div>
    </form>

    <grid #grid
          gridId="preferences.AlgorithmPerformance.ColumnExport"
          [query]="query"
          [resource]="api.AlgorithmPerformance"
          [exportResource]="api.AlgorithmPerformanceExport"
          [columnDefs]="columnDefs">
    </grid>
</div>
