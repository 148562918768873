import {Component, Inject, OnInit} from '@angular/core';
import {DIALOG_DATA} from "@angular/cdk/dialog";
import {DialogComponent, DialogContentComponent, DialogHeaderComponent} from "swx.front-end-lib";

@Component({
    selector: 'swx-heat-flaps-alert-dialog',
    templateUrl: './HeatFlapsAlertDialog.component.html',
    standalone: true,
    imports: [
        DialogComponent, DialogHeaderComponent, DialogContentComponent,
    ]
})
export class HeatFlapsAlertDialogComponent implements OnInit {
    message!: any;
    title!: string;
    
    constructor(@Inject(DIALOG_DATA) private data: { message: string, title: string }) {
    }
    
    ngOnInit() {
        this.message = this.data.message;
        this.title = this.data.title;
    }
}
