import {MDMUploadDialogComponent} from "./MDMUploadDialog.component";
import {Injectable, ViewContainerRef} from "@angular/core";
import {ApiService} from "../shared/Api.service";
import {ConfirmService, NotificationService, TranslateService} from "swx.front-end-lib";
import {Dialog} from "@angular/cdk/dialog";


@Injectable({ providedIn: 'root' })
export class MDMStateCellRendererService {
    constructor(
        public api: ApiService,
        private translateService: TranslateService,
        private confirmService: ConfirmService,
        private dialog: Dialog,
        private notification: NotificationService
    ) {
    }

    // isRelease: whether the row data comes from the releases grid. (when uploading from the MobileApp grid, use the MobileAppReleaseId instead of the MobileApp Id)
    mdmStateCellRenderer(params, refresh: () => void, isRelease: boolean) {
        const container = document.createElement('div');
        if (params.data?.BuildFileUploadId != null
            && params.data.ProvisioningProfileId != null
            && (params.data.WorkspaceOneAppId != null || params.data.Platform === 'IOSEnterprise' || params.data.Platform === 'IOSEnterpriseXArchive')) {
            if (params.data?.WorkspaceOneAppId == null) {
                const uploadButton = document.createElement('a');
                uploadButton.innerHTML = '<span class="mdi mdi-upload"></span> Upload';
                uploadButton.addEventListener('click', () => {
                    this.dialog.open(MDMUploadDialogComponent,
                        {
                            data: {
                                title: 'Upload to MDM',
                                row: params,
                                isRelease: isRelease,
                                refresh: refresh,
                            },
                            width: '550px',
                            height: '650px',
                        });
                });
                container.appendChild(uploadButton);
            } else if (params.data.Platform === 'IOSEnterprise' || params.data.Platform === 'IOSEnterpriseXArchive') {
                container.innerHTML = '<span class="mdi mdi-check-circle-outline"></span> Uploaded (';
                const deleteButton = document.createElement('a');
                deleteButton.innerHTML = 'Delete';
                deleteButton.addEventListener('click', () => {
                    this.confirmService.confirm(this.translateService.translate('Are you sure?')).then(() => {
                        this.api.WorkspaceOne.delete({appId: params.data?.WorkspaceOneAppId, appReleaseId: params.data.Id}).then(e => {
                            if (e.deleted) {
                                this.notification.show("Deleted");
                            } else {
                                this.notification.show("Error deleting.");
                            }
                            refresh();
                        })
                    });
                });
                container.appendChild(deleteButton);
                container.append(')');
            }
        }
        return container;
    }
}
