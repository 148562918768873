import {AfterViewInit, Component, OnInit, ViewChild} from "@angular/core";
import {ApiService} from "../shared/Api.service";
import {FileDownloadService, IAgGridColGroupDef, StorageService, ValueFormatters, SwxModule, GridModule } from "swx.front-end-lib";
import {StationDataRawCellRendererComponent} from "./StationDataRawCellRenderer.component";
import { NgFor } from "@angular/common";
import { FormsModule } from "@angular/forms";

@Component({
    templateUrl: './StationDataGen2.component.html',
    standalone: true,
    imports: [SwxModule, FormsModule, NgFor, GridModule]
})
export class StationDataGen2Component implements OnInit, AfterViewInit {
    @ViewChild('grid') grid;
    storeKey = 'stationDataGen2Query';
    query: any;
    datePickerOptions = {
        maxDate: new Date(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate(), new Date().getUTCHours(), new Date().getUTCMinutes(), new Date().getUTCSeconds()),
        dateFormat: 'yy-mm-dd',
        useUtc: true
    };
    components;
    constructor(private api: ApiService, 
                private storage: StorageService, 
                private fileDownload: FileDownloadService,
                private formatters: ValueFormatters,
                ) {
        this.components = {
            stationDataRawCellRenderer: StationDataRawCellRendererComponent
        }
    }
    
    ngOnInit() {
        const storedQuery = this.storage.load(this.storeKey)
        if (storedQuery) {
            this.query = storedQuery;
        } else {
            this.resetQuery();
        }
    }

    ngAfterViewInit() {
        const queryParams = new URLSearchParams(window.location.search);
        var stationId = queryParams.get('stationId');
        if (stationId) {
            this.query.StationId = [ parseInt(stationId) ];
        }
        if (stationId) {
            var fromDate = new Date();
            fromDate.setDate(fromDate.getDate() - 1);
            this.query.FromDate = fromDate.toISOString();
            this.query.ToDate = null;
            setTimeout(() => this.grid.refresh());
        }
    }

    resetQuery() {
        this.query = {
            Filters: []
        };
    }

    apply() {
        this.storage.save(this.storeKey, this.query);
        this.grid.refresh();
    }

    stations = this.api.Station.query();
    resource = this.api.StationReadingGen2;
    exportResourcePost = this.api.StationReadingGen2Export;

    downloadRawData() {
        this.api.StationReadingGen2RawData.export(this.query).then((response) => {
            this.fileDownload.download(response.body, (header) => response.headers.get(header))
        })
    };

    columnDefs: IAgGridColGroupDef[] = [
        {
            headerName: 'Sensor reading',
            children: [
                { colId: "StationReading.ReadingDateDate", field: "StationReading.ReadingDate", headerName: "Date", width: 90, pinned: 'left', filterType: "date", valueFormatter: this.formatters.utcDateFormatter() },
                { colId: "StationReading.ReadingDateTime", field: "StationReading.ReadingDate", headerName: "Time", width: 80, pinned: 'left', "searchable": false, valueFormatter: this.formatters.utcTimeLongFormatter() },
                { colId: "StationReading.ReportedReadingDateTime", field: "StationReading.ReportedReadingDate", headerName: "Reported time", width: 80, pinned: 'left', "searchable": false, valueFormatter: this.formatters.utcTimeLongFormatter() },
                { colId: "StationName", field: "StationName", headerName: "Station", width: 80, pinned: 'left' },
                { colId: "StationReading.TelegramNumber", field: "StationReading.TelegramNumber", headerName: "Telegram number", width: 80, filterType: "integer", pinned: 'left' },
                { colId: "StationReading.Crc", field: "StationReading.Crc", headerName: "CRC", width: 80, pinned: 'left' },
                { colId: "Raw", field: "StationReading.RawData", headerName: "Raw", width: 40, cellRenderer: 'stationDataRawCellRenderer', pinned: 'left' },
                { colId: "StationReading.Id", field: "StationReading.Id", headerName: "#", width: 80, filterType: "integer", pinned: 'left' },
            ]
        },
        {
            headerName: 'Sensor reading',
            children: [
                { colId: "StationReading.QueueSize", field: "StationReading.QueueSize", headerName: "Queue size", width: 50, filterType: "integer" },
                { colId: "StationReading.PowerErrorCount", field: "StationReading.PowerErrorCount", headerName: "Power error count", width: 60, filterType: "integer" },
                { colId: "StationReading.VpnIp", field: "StationReading.VpnIp", headerName: "VPN IP", width: 120, },
                { colId: "StationReading.SerialMaintenanceMode", field: "StationReading.SerialMaintenanceMode", headerName: "Serial maintenance mode?", width: 70, filterType: "boolean" },
                { colId: "StationReading.StationProblemNames", field: "StationReading.StationProblemNames", headerName: "Station alarms", width: 350, sortable: false, "searchable": false },
            ]
        },
        {
            headerName: 'Sartorius',
            children: [
                { colId: "StationReading.Sartorius.CycleStage", field: "StationReading.Sartorius.CycleStage", headerName: "Stage", width: 60, filterType: "integer" },
                { colId: "StationReading.Sartorius.Weight1", field: "StationReading.Sartorius.Weight1", headerName: "Weight 1 (g)", width: 80, filterType: "float" },
                { colId: "StationReading.Sartorius.Weight2", field: "StationReading.Sartorius.Weight2", headerName: "Weight 2 (g)", width: 80, filterType: "float" },
                { colId: "StationReading.Sartorius.PrecipitationRate", field: "StationReading.Sartorius.PrecipitationRate", headerName: "Precipitation rate (g/dm²/h)", width: 100, filterType: "float" },
                { colId: "StationReading.Sartorius.ProgramVersion", field: "StationReading.Sartorius.ProgramVersion", headerName: "Version", width: 150 },
                { colId: "StationReading.Sartorius.TaskStr", field: "StationReading.Sartorius.TaskStr", headerName: "Task", width: 150 },
                { colId: "StationReading.Sartorius.Alarms", field: "StationReading.Sartorius.Alarms", headerName: "Alarms", width: 150, filterType: "enum", source: "SartoriusAlarm" },
                { colId: "StationReading.Sartorius.DigitalInputs", field: "StationReading.Sartorius.DigitalInputs", headerName: "Digital inputs", width: 150, filterType: "enum", source: "SartoriusDigitalInput" },
                { colId: "StationReading.Sartorius.DigitalOutputs", field: "StationReading.Sartorius.DigitalOutputs", headerName: "Digital outputs", width: 150, filterType: "enum", source: "SartoriusDigitalOutput" },
                { colId: "StationReading.Sartorius.ValveStateVoltage", field: "StationReading.Sartorius.ValveStateVoltage", headerName: "Valve state voltage (V)", filterType: "float" },
            ]
        },
        {
            headerName: 'Vaisala FD12P',
            children: [
                { colId: "StationReading.VaisalaFD12P.Alarm1", field: "StationReading.VaisalaFD12P.Alarm1", headerName: "Alarm 1", width: 80, filterType: "integer" },
                { colId: "StationReading.VaisalaFD12P.Alarm2", field: "StationReading.VaisalaFD12P.Alarm2", headerName: "Alarm 2", width: 80, filterType: "integer" },
                { colId: "StationReading.VaisalaFD12P.WMOCode", field: "StationReading.VaisalaFD12P.WMOCode", headerName: "WMO", width: 100, filterType: "integer" },
                { colId: "StationReading.VaisalaFD12P.WMOCode15Minute", field: "StationReading.VaisalaFD12P.WMOCode15Minute", headerName: "WMO 15 min.", width: 100, filterType: "integer" },
                { colId: "StationReading.VaisalaFD12P.NWSWeatherCode", field: "StationReading.VaisalaFD12P.NWSWeatherCode", headerName: "NSW code", width: 100 },
                { colId: "StationReading.VaisalaFD12P.MetarWeatherCode", field: "StationReading.VaisalaFD12P.MetarWeatherCode", headerName: "METAR code", width: 100 },
                { colId: "StationReading.VaisalaFD12P.Intensity", field: "StationReading.VaisalaFD12P.Intensity", headerName: "Intensity (mm/h)", width: 100, filterType: "float" },
                { colId: "StationReading.VaisalaFD12P.CumulativeWater", field: "StationReading.VaisalaFD12P.CumulativeWater", headerName: "Water precipitation sum (mm)", width: 100, filterType: "float" },
                { colId: "StationReading.VaisalaFD12P.CumulativeSnow", field: "StationReading.VaisalaFD12P.CumulativeSnow", headerName: "Snow precipitation sum (mm)", width: 100, filterType: "float" },
                { colId: "StationReading.VaisalaFD12P.Temperature", field: "StationReading.VaisalaFD12P.Temperature", headerName: "Temperature (°C)", width: 100, filterType: "float" },
                { colId: "StationReading.VaisalaFD12P.Visibility", field: "StationReading.VaisalaFD12P.Visibility", headerName: "Visibility (m)", width: 100, filterType: "float" },
            ]
        },
        {
            headerName: 'Thies LPM',
            children: [
                { colId: "StationReading.ThiesLPM.SensorFailed", field: "StationReading.ThiesLPM.SensorFailed", headerName: "Sensor failed?", width: 80 },
                { colId: "StationReading.ThiesLPM.WMOCode", field: "StationReading.ThiesLPM.WMOCode", headerName: "WMO", width: 60, filterType: "integer" },
                { colId: "StationReading.ThiesLPM.MetarWeatherCode", field: "StationReading.ThiesLPM.MetarWeatherCode", headerName: "METAR code", width: 60 },
                { colId: "StationReading.ThiesLPM.Intensity", field: "StationReading.ThiesLPM.Intensity", headerName: "Intensity (mm/h)", width: 75, filterType: "float" },
                { colId: "StationReading.ThiesLPM.IntensityLiquid", field: "StationReading.ThiesLPM.IntensityLiquid", headerName: "Intensity liquid (mm/h)", width: 75, filterType: "float" },
                { colId: "StationReading.ThiesLPM.IntensitySolid", field: "StationReading.ThiesLPM.IntensitySolid", headerName: "Intensity solid (mm/h)", width: 75, filterType: "float" },
                { colId: "StationReading.ThiesLPM.Visibility", field: "StationReading.ThiesLPM.Visibility", headerName: "Visibility (m)", width: 80, filterType: "float" },
                { colId: "StationReading.ThiesLPM.PrecipitationAmount", field: "StationReading.ThiesLPM.PrecipitationAmount", headerName: "Precipitation amount (mm)", width: 100, filterType: "float" },
                { colId: "StationReading.ThiesLPM.RadarReflectivity", field: "StationReading.ThiesLPM.RadarReflectivity", headerName: "Radar reflectivity (dBZ)", width: 90, filterType: "float" },
                { colId: "StationReading.ThiesLPM.MeasuringQuality", field: "StationReading.ThiesLPM.MeasuringQuality", headerName: "Measuring quality (%)", width: 90, filterType: "float" },
                { colId: "StationReading.ThiesLPM.MaximumDiameterHail", field: "StationReading.ThiesLPM.MaximumDiameterHail", headerName: "Maximum diameter hail (mm)", width: 105, filterType: "float" },
            ]
        },
        {
            headerName: 'Campbell PWS100',
            children: [
                { colId: "StationReading.CampbellPWS100.FaultStatus", field: "StationReading.CampbellPWS100.FaultStatus", headerName: "Fault status", width: 100 },
                { colId: "StationReading.CampbellPWS100.WMOCode", field: "StationReading.CampbellPWS100.WMOCode", headerName: "WMO", width: 100, filterType: "integer" },
                { colId: "StationReading.CampbellPWS100.MetarWeatherCode", field: "StationReading.CampbellPWS100.MetarWeatherCode", headerName: "METAR code", width: 100 },
                { colId: "StationReading.CampbellPWS100.Intensity", field: "StationReading.CampbellPWS100.Intensity", headerName: "Intensity (mm/h)", width: 100, filterType: "float" },
                { colId: "StationReading.CampbellPWS100.Visibility", field: "StationReading.CampbellPWS100.Visibility", headerName: "Visibility (m)", width: 100, filterType: "float" },
                { colId: "StationReading.CampbellPWS100.RelativeHumidity", field: "StationReading.CampbellPWS100.RelativeHumidity", headerName: "Relative humidity (RH%)", width: 100, filterType: "float" },
                { colId: "StationReading.CampbellPWS100.Temperature", field: "StationReading.CampbellPWS100.Temperature", headerName: "Temperature (°C)", width: 100, filterType: "float" },
                { colId: "StationReading.CampbellPWS100.WetBulb", field: "StationReading.CampbellPWS100.WetBulb", headerName: "Wet bulb (°C)", width: 100, filterType: "float" },
                { colId: "StationReading.CampbellPWS100.UpperDetectorTemperature", field: "StationReading.CampbellPWS100.UpperDetectorTemperature", headerName: "Upper detector temp (°C)", width: 100, filterType: "float" },
                { colId: "StationReading.CampbellPWS100.LowerDetectorTemperature", field: "StationReading.CampbellPWS100.LowerDetectorTemperature", headerName: "Lower detector temp (°C)", width: 100, filterType: "float" },
                { colId: "StationReading.CampbellPWS100.LaserHoodTemperature", field: "StationReading.CampbellPWS100.LaserHoodTemperature", headerName: "Laser hood temp (°C)", width: 100, filterType: "float" },
                { colId: "StationReading.CampbellPWS100.LaserTemperature", field: "StationReading.CampbellPWS100.LaserTemperature", headerName: "Laser temp (°C)", width: 100, filterType: "float" },
                { colId: "StationReading.CampbellPWS100.LaserDriveCurrent", field: "StationReading.CampbellPWS100.LaserDriveCurrent", headerName: "Laser drive current (mA)", width: 100, filterType: "float" },
            ]
        },
        {
            headerName: 'Biral SWS-200/250',
            children: [
                { colId: "StationReading.BiralSWS200.Status", field: "StationReading.BiralSWS200.Status", headerName: "Status", width: 60 },
                { colId: "StationReading.BiralSWS200.WMOCode", field: "StationReading.BiralSWS200.WMOCode", headerName: "WMO", width: 100, filterType: "integer" },
                { colId: "StationReading.BiralSWS200.AveragingPeriod", field: "StationReading.BiralSWS200.AveragingPeriod", headerName: "Averaging period (s)", width: 100, filterType: "float" },
                { colId: "StationReading.BiralSWS200.PrecipitationAmount", field: "StationReading.BiralSWS200.PrecipitationAmount", headerName: "Precipitation amount (mm)", width: 100, filterType: "float" },
                { colId: "StationReading.BiralSWS200.Intensity", field: "StationReading.BiralSWS200.Intensity", headerName: "Intensity (mm/h)", width: 100, filterType: "float" },
                { colId: "StationReading.BiralSWS200.Visibility", field: "StationReading.BiralSWS200.Visibility", headerName: "Visibility (m)", width: 100, filterType: "float" },
                { colId: "StationReading.BiralSWS200.Temperature", field: "StationReading.BiralSWS200.Temperature", headerName: "Temperature (°C)", width: 100, filterType: "float" },
            ]
        },
        {
            headerName: 'Vaisala WS425',
            children: [
                { colId: "StationReading.VaisalaWS425.Status", field: "StationReading.VaisalaWS425.Status", headerName: "Status", width: 100 },
                { colId: "StationReading.VaisalaWS425.WindSpeed", field: "StationReading.VaisalaWS425.WindSpeed", headerName: "Wind speed (m/s)", width: 100, filterType: "float" },
                { colId: "StationReading.VaisalaWS425.WindDirection", field: "StationReading.VaisalaWS425.WindDirection", headerName: "Wind direction (°)", width: 100, filterType: "float" },
            ]
        },
        {
            headerName: 'Thies 2D Compact',
            children: [
                { colId: "StationReading.Thies2DCompact.SensorFailed", field: "StationReading.Thies2DCompact.SensorFailed", headerName: "Sensor failed?", width: 100, filterType: "integer" },
                { colId: "StationReading.Thies2DCompact.WindSpeed", field: "StationReading.Thies2DCompact.WindSpeed", headerName: "Wind speed (m/s)", width: 100, filterType: "float" },
                { colId: "StationReading.Thies2DCompact.WindDirection", field: "StationReading.Thies2DCompact.WindDirection", headerName: "Wind direction (°)", width: 100, filterType: "float" },
                { colId: "StationReading.Thies2DCompact.Temperature", field: "StationReading.Thies2DCompact.Temperature", headerName: "Temperature (°C)", width: 100, filterType: "float" },
            ]
        },
        {
            headerName: 'Vaisala/Digital temp probe',
            children: [
                { colId: "StationReading.DigitalTempProbe.RelativeHumidity", field: "StationReading.DigitalTempProbe.RelativeHumidity", headerName: "Relative humidity (RH%)", width: 100, filterType: "float" },
                { colId: "StationReading.DigitalTempProbe.Temperature", field: "StationReading.DigitalTempProbe.Temperature", headerName: "Temperature (°C)", width: 105, filterType: "float" },
            ]
        },
        {
            headerName: 'Thies temp probe',
            children: [
                { colId: "StationReading.ThiesTempProbe.RelativeHumidity", field: "StationReading.ThiesTempProbe.RelativeHumidity", headerName: "Relative humidity (RH%)", width: 100, filterType: "float" },
                { colId: "StationReading.ThiesTempProbe.Temperature", field: "StationReading.ThiesTempProbe.Temperature", headerName: "Temperature (°C)", width: 105, filterType: "float" },
            ]
        },
        {
           headerName: 'Active frost',
           children: [
               { colId: "StationReading.ActiveFrost.Temperature1", field: "StationReading.ActiveFrost.Temperature1", headerName: "Temperature 1 (°C)", width: 105, filterType: "float" },
               { colId: "StationReading.ActiveFrost.Temperature2", field: "StationReading.ActiveFrost.Temperature2", headerName: "Temperature 2 (°C)", width: 105, filterType: "float" },
               { colId: "StationReading.ActiveFrost.Temperature3", field: "StationReading.ActiveFrost.Temperature3", headerName: "Temperature 3 (°C)", width: 105, filterType: "float" },
               { colId: "StationReading.ActiveFrost.Temperature4", field: "StationReading.ActiveFrost.Temperature4", headerName: "Temperature 4 (°C)", width: 105, filterType: "float" },
               { colId: "StationReading.ActiveFrost.ConeSurfaceTemperature", field: "StationReading.ActiveFrost.ConeSurfaceTemperature", headerName: "Active Frost sensor temperature (°C)", width: 150, filterType: "float" },
           ] 
        }
        ];
}




