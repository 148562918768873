<swx-dialog>
    <swx-dialog-header>{{title}}</swx-dialog-header>
    <swx-dialog-content>
        @if(metarReading) {
            <div class="form">
                <label>
                    <div style="max-width: 500px; font-family: monospace;">{{metarReading.RawData}}</div>
                </label>
                <label>
                    <span class="caption">{{'Date'|translate}}</span>
                    <span>{{metarReading.Timestamp|utcDateTimeFormat:dateTimeFormat}}</span>
                </label>
                <label>
                    <span class="caption">{{'Type'|translate}}</span>
                    <span>{{metarReading.MetarType}}</span>
                </label>
                <label>
                    <span class="caption">{{'Weather code'|translate}}</span>
                    <span>{{metarReading.WxString}}</span>
                </label>
                <label>
                    <span class="caption">{{'Temperature (°C)'|translate}}</span>
                    <span>{{metarReading.TempC}}</span>
                </label>
                <label>
                    <span class="caption">{{'Visibility (SM)'|translate}}</span>
                    <span>{{metarReading.VisibilityStatuteMi}}</span>
                </label>
            </div>
        }
        @else {
            <p>METAR reading not available.</p>
        }
        
    </swx-dialog-content>
</swx-dialog>
