import {Component, EventEmitter, Inject, OnInit} from "@angular/core";
import {ConfirmService, DialogComponent, DialogContentComponent, DialogHeaderComponent, SwxModule, TranslateService} from "swx.front-end-lib";
import { FormsModule } from "@angular/forms";
import {DIALOG_DATA, DialogRef} from "@angular/cdk/dialog";
import {CdkDrag, CdkDragHandle} from "@angular/cdk/drag-drop";
import {ApiService} from "../shared/Api.service";

export interface PortalUserTableauInfo {
    UserId: any;
    GroupId: any;
}

@Component({
    templateUrl: './TableauUserDialog.component.html',
    standalone: true,
    imports: [FormsModule, SwxModule, DialogComponent, DialogHeaderComponent, DialogContentComponent, CdkDrag, CdkDragHandle]
})
export class TableauUserDialogComponent implements OnInit {
    portalUser: any;
    tableauUserProvisioning: any;
    icaoCode!: string;
    onAddTableauUser = new EventEmitter<PortalUserTableauInfo>();
    onDeleteTableauUser = new EventEmitter();
    
    
    constructor(@Inject(DIALOG_DATA) private data: any,
                private dialogRef: DialogRef,
                private api: ApiService,
                private confirm: ConfirmService,
                private translateService: TranslateService,
    ) {
    }
    
    ngOnInit() {
        this.portalUser = this.data.portalUser;
        this.tableauUserProvisioning = this.data.tableauUserProvisioning;
        this.icaoCode = this.data.icaoCode.toUpperCase();
    }
    
    createTableauUser() {
        this.api.TableauUser.post({
            username: this.portalUser.Email,
            group: this.icaoCode,
        }).then(result => {
            this.onAddTableauUser.emit({
                UserId: result.UserId,
                GroupId: result.GroupId,
            })
        })
        // createTableauUser: () => {
        //     this.api.TableauUser.post({
        //         username: portalUser.Email,
        //         group: this.client.ICAOCode.toUpperCase(),
        //     }).then(result => {
        //         portalUser.TableauTrustedAuthenticationUserId = result.UserId;
        //         portalUser.TableauTrustedAuthenticationUsername = portalUser.Email;
        //         portalUser.TableauTrustedAuthenticationGroupId = result.GroupId;
        //         portalUser.TableauTrustedAuthenticationGroupName = this.client.ICAOCode.toUpperCase();
        //
        //         this.tableauUsersChanged = true;
        //         this.ngForm.form.markAsDirty();
        //     });
        // },
        
    }
    deleteTableauUser() {
        if (this.confirm.confirm(this.translateService.translate('Are you sure?'))) {
            this.api.TableauUser.delete({ id: this.portalUser.TableauTrustedAuthenticationUserId }).then(result => {
                this.onDeleteTableauUser.emit();
            })
        }
        // deleteTableauUser: () => {
        //     if (confirm(this.translateService.translate('Are you sure?'))) {
        //         this.api.TableauUser.delete({ id: portalUser.TableauTrustedAuthenticationUserId }).then(result => {
        //             portalUser.TableauTrustedAuthenticationUserId = null;
        //             portalUser.TableauTrustedAuthenticationUsername = null;
        //             portalUser.TableauTrustedAuthenticationGroupId = null;
        //             portalUser.TableauTrustedAuthenticationGroupName = null;
        //
        //             this.tableauUsersChanged = true;
        //             this.ngForm.form.markAsDirty();
        //         });
        //     }
        // }
        
    }
}
