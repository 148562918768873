import { Component, OnInit } from "@angular/core";
import { ApiService } from "../shared/Api.service";
import moment from 'moment';
import { SwxModule } from "swx.front-end-lib";
import { NgFor, NgIf } from "@angular/common";
import { FormsModule } from "@angular/forms";

@Component({
    templateUrl: 'WeatherProfileRerun.component.html',
    standalone: true,
    imports: [FormsModule, NgFor, SwxModule, NgIf]
})
export class WeatherProfileRerunComponent implements OnInit {
    datePickerOptions: any;
    stations: any = [];
    sensorSelectMatrices = this.api.SensorSelectMatrix.query();
    query: any;
    running: boolean = false;
    results: any;
    moment = moment;

    constructor(private api: ApiService) {
        this.api.Station.query({ IncludeInactive: false }).$promise.then(stations => {
            this.stations = stations.filter(s => s.StationType == 'Gen3' || s.StationType == 'GenV');
        });
    }

    ngOnInit() {
        this.datePickerOptions = {
            maxDate: new Date(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate(), new Date().getUTCHours(), new Date().getUTCMinutes(), new Date().getUTCSeconds()),
            dateFormat: 'yy-mm-dd',
            useUtc: true
        };

        this.query = {
            DeleteExistingWeatherProfiles: true,
        };
    }

    start() {
        this.running = true;
        this.results = '';

        this.api.WeatherProfileRerun.post(this.query).then(response => {
            let subscription = this.api.WeatherProfileRerunTask.stream({ taskId: response.TaskId}).subscribe((result:any) => {
                this.results += result + '\n';
                if (result === 'Done') {
                    subscription.unsubscribe();
                    this.running = false;
                }
            });
        })
            .catch(err => {
                this.running = false;
            });
    }
}
