<swx-dialog>
    <swx-dialog-header>{{title}}</swx-dialog-header>
    <swx-dialog-content>
        <div class="alert" *ngFor="let alert of alerts|orderBy:'CreatedDate'" [style.width]="alert.EmailBody != null ? '600px' : '240px'">
            <h3>{{alert.CreatedDate|utcDateTimeFormat:'HH:mm'}}</h3>
            <div class="field" *ngIf="alerts[0].RawData != null">
                <textarea readonly cols="24" rows="10">{{alert.RawData}}</textarea>
            </div>
            <div class="field" *ngIf="alerts[0].EmailRecipients != null">
                <div class="recipients">{{'To: '|translate}} {{alert.EmailRecipients}}</div>
            </div>
            <div class="field" *ngIf="alerts[0].EmailRecipients != null">
                <div class="subject">{{alert.EmailSubject}}</div>
            </div>
            <div class="field" *ngIf="alerts[0].EmailRecipients != null" style="border: 1px solid #ddd;">
                <div class="body" [innerHTML]="getBody(alert.EmailBody)"></div>
            </div>
        </div>
    </swx-dialog-content>
</swx-dialog>
