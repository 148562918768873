import {Component, ViewChild, ViewContainerRef} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from '../shared/Api.service';
import { ChangesService, SwxModule, GridModule } from 'swx.front-end-lib';
import { TicketTrackingSystemService } from '../shared/TicketTrackingSystem.service';
import { FileDownloadService, ConfigGridComponent, OrderByPipe } from 'swx.front-end-lib';
import { HasPermissionService, HasPermissionPipe } from '../shared/HasPermission.pipe';
import { NgIf } from '@angular/common';
import { FormsModule } from '@angular/forms';

@Component({
    templateUrl: 'LWEEquationProfileDetail.component.html',
    standalone: true,
    imports: [SwxModule, FormsModule, NgIf, GridModule, HasPermissionPipe]
})
export class LWEEquationProfileDetailComponent {
    item: any;
    returnPath;
    @ViewChild('ngForm', {static: true}) ngForm;
    @ViewChild('grid', { static: true }) grid: ConfigGridComponent;
    tab;
    metarWarnings = this.api.MetarWarning.query();
    weatherCategories = {
        SLD: "Super-cooled Large Droplets",
        FZDZ: "Freezing drizzle",
        FZRA: "Freezing rain",
        SN: "Snow"
    };
    columnDefs = [
        {
            headerName: "Weather criteria",
            children: [
                { colId: "WeatherCategory", field: "WeatherCategory", headerName: "Weather category", width: 60, cellClass: "select", cellTemplate: `
                    <select [(ngModel)]="params.data.WeatherCategory" [name]="'params.data[' + params.node.id + '].WeatherCategory'" required>
                        <option *ngFor="let option of parent.weatherCategories|keys" [ngValue]="option">
                            {{option}}
                        </option>
                    </select>`, pinned: 'left' },
                { colId: "MetarWarning", field: "MetarWarning", headerName: "METAR warning", width: 70, cellClass: "select", cellTemplate: `
                    <select [(ngModel)]="params.data.MetarWarning" [name]="'params.data[' + params.node.id + '].MetarWarning'">
                        <option [ngValue]="null">{{'Any'|translate}}</option>
                        <option *ngFor="let option of parent.metarWarnings|keys" [ngValue]="option">
                            {{option}}
                        </option>
                    </select>`, pinned: 'left' },
                { colId: "MaxWindSpeed", field: "MaxWindSpeed", headerName: "Wind speed < (m/s)", width: 70, filterType: 'integer', cellTemplate: `<input type="number" step="any" min="0" max="999" [(ngModel)]="params.data.MaxWindSpeed" [name]="'params.data[' + params.node.id + '].MaxWindSpeed'" placeholder="Not present" style="width: 99%;"/>`, pinned: 'left' },
            ]
        },
        {
            headerName: "Sensor LWE < (g/dm²/h)",
            children: [
                { colId: "RemoteEndpointMaxLWE", field: "RemoteEndpointMaxLWE", headerName: "Remote endpoint", width: 70, filterType: 'integer', cellTemplate: `<input type="number" step="any" min="0" max="999" placeholder="Not present" [(ngModel)]="params.data.RemoteEndpointMaxLWE" [name]="'params.data[' + params.node.id + '].RemoteEndpointMaxLWE'" style="width: 99%;"/>`, pinned: 'left' },
                { colId: "SartoriusMaxLWE", field: "SartoriusMaxLWE", headerName: "Sartorius", width: 70, filterType: 'integer', cellTemplate: `<input type="number" step="any" min="0" max="999" placeholder="Not present" [(ngModel)]="params.data.SartoriusMaxLWE" [name]="'params.data[' + params.node.id + '].SartoriusMaxLWE'" style="width: 99%;"/>`, pinned: 'left' },
                { colId: "CampbellPWS100MaxLWE", field: "CampbellPWS100MaxLWE", headerName: "Campbell PWS100", width: 70, filterType: 'integer', cellTemplate: `<input type="number" step="any" min="0" max="999" placeholder="Not present" [(ngModel)]="params.data.CampbellPWS100MaxLWE" [name]="'params.data[' + params.node.id + '].CampbellPWS100MaxLWE'" style="width: 99%;"/>`, pinned: 'left' },
                { colId: "ThiesLPMMaxLWE", field: "ThiesLPMMaxLWE", headerName: "Thies LPM", width: 70, filterType: 'integer', cellTemplate: `<input type="number" step="any" min="0" max="999" placeholder="Not present" [(ngModel)]="params.data.ThiesLPMMaxLWE" [name]="'params.data[' + params.node.id + '].ThiesLPMMaxLWE'" style="width: 99%;"/>`, pinned: 'left' },
                { colId: "ThiesLPMSecondaryMaxLWE", field: "ThiesLPMSecondaryMaxLWE", headerName: "Thies LPM Secondary", width: 70, filterType: 'integer', cellTemplate: `<input type="number" step="any" min="0" max="999" placeholder="Not present" [(ngModel)]="params.data.ThiesLPMSecondaryMaxLWE" [name]="'params.data[' + params.node.id + '].ThiesLPMSecondaryMaxLWE'" style="width: 99%;"/>`, pinned: 'left' },
                { colId: "OTTPluvio2MaxLWE", field: "OTTPluvio2MaxLWE", headerName: "OTT Pluvio2", width: 70, filterType: 'integer', cellTemplate: `<input type="number" step="any" min="0" max="999" placeholder="Not present" [(ngModel)]="params.data.OTTPluvio2MaxLWE" [name]="'params.data[' + params.node.id + '].OTTPluvio2MaxLWE'" style="width: 99%;"/>`, pinned: 'left' },
                { colId: "OTTPluvio2DFARMaxLWE", field: "OTTPluvio2DFARMaxLWE", headerName: "OTT Pluvio2 DFAR", width: 70, filterType: 'integer', cellTemplate: `<input type="number" step="any" min="0" max="999" placeholder="Not present" [(ngModel)]="params.data.OTTPluvio2DFARMaxLWE" [name]="'params.data[' + params.node.id + '].OTTPluvio2DFARMaxLWE'" style="width: 99%;"/>`, pinned: 'left' },
                { colId: "OTTParsivel2MaxLWE", field: "OTTParsivel2MaxLWE", headerName: "OTT Parsivel 2", width: 70, filterType: 'integer', cellTemplate: `<input type="number" step="any" min="0" max="999" placeholder="Not present" [(ngModel)]="params.data.OTTParsivel2MaxLWE" [name]="'params.data[' + params.node.id + '].OTTParsivel2MaxLWE'" style="width: 99%;"/>`, pinned: 'left' },
                { colId: "OTTParsivel2SecondaryMaxLWE", field: "OTTParsivel2SecondaryMaxLWE", headerName: "OTT Parsivel 2 Secondary", width: 70, filterType: 'integer', cellTemplate: `<input type="number" step="any" min="0" max="999" placeholder="Not present" [(ngModel)]="params.data.OTTParsivel2SecondaryMaxLWE" [name]="'params.data[' + params.node.id + '].OTTParsivel2SecondaryMaxLWE'" style="width: 99%;"/>`, pinned: 'left' },
                { colId: "VaisalaPWD22MaxLWE", field: "VaisalaPWD22MaxLWE", headerName: "Vaisala PWD22", width: 70, filterType: 'integer', cellTemplate: `<input type="number" step="any" min="0" max="999" placeholder="Not present" [(ngModel)]="params.data.VaisalaPWD22MaxLWE" [name]="'params.data[' + params.node.id + '].VaisalaPWD22MaxLWE'" style="width: 99%;"/>`, pinned: 'left' },
                { colId: "VaisalaPWD22SecondaryMaxLWE", field: "VaisalaPWD22SecondaryMaxLWE", headerName: "Vaisala PWD22 Secondary", width: 70, filterType: 'integer', cellTemplate: `<input type="number" step="any" min="0" max="999" placeholder="Not present" [(ngModel)]="params.data.VaisalaPWD22SecondaryMaxLWE" [name]="'params.data[' + params.node.id + '].VaisalaPWD22SecondaryMaxLWE'" style="width: 99%;"/>`, pinned: 'left' },
                { colId: "VaisalaFD71PMaxLWE", field: "VaisalaFD71PMaxLWE", headerName: "Vaisala FD71P", width: 70, filterType: 'integer', cellTemplate: `<input type="number" step="any" min="0" max="999" placeholder="Not present" [(ngModel)]="params.data.VaisalaFD71PMaxLWE" [name]="'params.data[' + params.node.id + '].VaisalaFD71PMaxLWE'" style="width: 99%;"/>`, pinned: 'left' },
                { colId: "PondHotPlateMaxLWE", field: "PondHotPlateMaxLWE", headerName: "Pond Hot Plate", width: 70, filterType: 'integer', cellTemplate: `<input type="number" step="any" min="0" max="999" placeholder="Not present" [(ngModel)]="params.data.PondHotPlateMaxLWE" [name]="'params.data[' + params.node.id + '].PondHotPlateMaxLWE'" style="width: 99%;"/>`, pinned: 'left' },
                { colId: "GeonorT200DAMaxLWE", field: "GeonorT200DAMaxLWE", headerName: "Geonor T200 DA", width: 70, filterType: 'integer', cellTemplate: `<input type="number" step="any" min="0" max="999" placeholder="Not present" [(ngModel)]="params.data.GeonorT200DAMaxLWE" [name]="'params.data[' + params.node.id + '].GeonorT200DAMaxLWE'" style="width: 99%;"/>`, pinned: 'left' },
                { colId: "GeonorT200DFARMaxLWE", field: "GeonorT200DFARMaxLWE", headerName: "Geonor T200 DFAR", width: 70, filterType: 'integer', cellTemplate: `<input type="number" step="any" min="0" max="999" placeholder="Not present" [(ngModel)]="params.data.GeonorT200DFARMaxLWE" [name]="'params.data[' + params.node.id + '].GeonorT200DFARMaxLWE'" style="width: 99%;"/>`, pinned: 'left' },
            ]
        },
        {
            headerName: "",
            children: [
                { colId: "Id", field: "Id", headerName: "#", width: 50, pinnedLeft: true, filterType: 'integer' },
            ]
        },
        {
            headerName: "",
            children: [
                { colId: "RemoteEndpointWeight", field: "RemoteEndpointWeight", headerName: "Remote endpoint LWE weight", width: 90, filterType: 'integer', cellTemplate: `<input *ngIf="params.data.RemoteEndpointMaxLWE" [required]="params.data.RemoteEndpointMaxLWE" type="number" step="any" [(ngModel)]="params.data.RemoteEndpointWeight" [name]="'params.data[' + params.node.id + '].RemoteEndpointWeight'" style="width: 99%;"/>` },
                { colId: "SartoriusWeight", field: "SartoriusWeight", headerName: "Sartorius LWE weight", width: 90, filterType: 'integer', cellTemplate: `<input *ngIf="params.data.SartoriusMaxLWE" [required]="params.data.SartoriusMaxLWE" type="number" step="any" [(ngModel)]="params.data.SartoriusWeight" [name]="'params.data[' + params.node.id + '].SartoriusWeight'" style="width: 99%;"/>` },
                { colId: "CampbellPWS100Weight", field: "CampbellPWS100Weight", headerName: "Campbell PWS100 LWE weight", width: 90, filterType: 'integer', cellTemplate: `<input *ngIf="params.data.CampbellPWS100MaxLWE" [required]="params.data.CampbellPWS100MaxLWE" type="number" step="any" [(ngModel)]="params.data.CampbellPWS100Weight" [name]="'params.data[' + params.node.id + '].CampbellPWS100Weight'" style="width: 99%;"/>` },
                { colId: "ThiesLPMWeight", field: "ThiesLPMWeight", headerName: "Thies LPM LWE weight", width: 90, filterType: 'integer', cellTemplate: `<input *ngIf="params.data.ThiesLPMMaxLWE" [required]="params.data.ThiesLPMMaxLWE" type="number" step="any" [(ngModel)]="params.data.ThiesLPMWeight" [name]="'params.data[' + params.node.id + '].ThiesLPMWeight'" style="width: 99%;"/>` },
                { colId: "ThiesLPMSecondaryWeight", field: "ThiesLPMSecondaryWeight", headerName: "Thies LPM Secondary LWE weight", width: 90, filterType: 'integer', cellTemplate: `<input *ngIf="params.data.ThiesLPMSecondaryMaxLWE" [required]="params.data.ThiesLPMSecondaryMaxLWE" type="number" step="any" [(ngModel)]="params.data.ThiesLPMSecondaryWeight" [name]="'params.data[' + params.node.id + '].ThiesLPMSecondaryWeight'" style="width: 99%;"/>` },
                { colId: "OTTPluvio2Weight", field: "OTTPluvio2Weight", headerName: "OTT Pluvio2 LWE weight", width: 90, filterType: 'integer', cellTemplate: `<input *ngIf="params.data.OTTPluvio2MaxLWE" [required]="params.data.OTTPluvio2MaxLWE" type="number" step="any" [(ngModel)]="params.data.OTTPluvio2Weight" [name]="'params.data[' + params.node.id + '].OTTPluvio2Weight'" style="width: 99%;"/>` },
                { colId: "OTTPluvio2DFARWeight", field: "OTTPluvio2DFARWeight", headerName: "OTT Pluvio2 DFAR LWE weight", width: 90, filterType: 'integer', cellTemplate: `<input *ngIf="params.data.OTTPluvio2DFARMaxLWE" [required]="params.data.OTTPluvio2DFARMaxLWE" type="number" step="any" [(ngModel)]="params.data.OTTPluvio2DFARWeight" [name]="'params.data[' + params.node.id + '].OTTPluvio2DFARWeight'" style="width: 99%;"/>` },
                { colId: "OTTParsivel2Weight", field: "OTTParsivel2Weight", headerName: "OTT Parsivel 2 LWE weight", width: 90, filterType: 'integer', cellTemplate: `<input *ngIf="params.data.OTTParsivel2MaxLWE" [required]="params.data.OTTParsivel2MaxLWE" type="number" step="any" [(ngModel)]="params.data.OTTParsivel2Weight" [name]="'params.data[' + params.node.id + '].OTTParsivel2Weight'" style="width: 99%;"/>` },
                { colId: "OTTParsivel2SecondaryWeight", field: "OTTParsivel2SecondaryWeight", headerName: "OTT Parsivel 2 Secondary LWE weight", width: 90, filterType: 'integer', cellTemplate: `<input *ngIf="params.data.OTTParsivel2SecondaryMaxLWE" [required]="params.data.OTTParsivel2SecondaryMaxLWE" type="number" step="any" [(ngModel)]="params.data.OTTParsivel2SecondaryWeight" [name]="'params.data[' + params.node.id + '].OTTParsivel2SecondaryWeight'" style="width: 99%;"/>` },
                { colId: "VaisalaPWD22Weight", field: "VaisalaPWD22Weight", headerName: "Vaisala PWD22 LWE weight", width: 90, filterType: 'integer', cellTemplate: `<input *ngIf="params.data.VaisalaPWD22MaxLWE" [required]="params.data.VaisalaPWD22MaxLWE" type="number" step="any" [(ngModel)]="params.data.VaisalaPWD22Weight" [name]="'params.data[' + params.node.id + '].VaisalaPWD22Weight'" style="width: 99%;"/>` },
                { colId: "VaisalaPWD22SecondaryWeight", field: "VaisalaPWD22SecondaryWeight", headerName: "Vaisala PWD22 Secondary LWE weight", width: 90, filterType: 'integer', cellTemplate: `<input *ngIf="params.data.VaisalaPWD22SecondaryMaxLWE" [required]="params.data.VaisalaPWD22SecondaryMaxLWE" type="number" step="any" [(ngModel)]="params.data.VaisalaPWD22SecondaryWeight" [name]="'params.data[' + params.node.id + '].VaisalaPWD22SecondaryWeight'" style="width: 99%;"/>` },
                { colId: "VaisalaFD71PWeight", field: "VaisalaFD71PWeight", headerName: "Vaisala FD71P LWE weight", width: 90, filterType: 'integer', cellTemplate: `<input *ngIf="params.data.VaisalaFD71PMaxLWE" [required]="params.data.VaisalaFD71PMaxLWE" type="number" step="any" [(ngModel)]="params.data.VaisalaFD71PWeight" [name]="'params.data[' + params.node.id + '].VaisalaFD71PWeight'" style="width: 99%;"/>` },
                { colId: "PondHotPlateWeight", field: "PondHotPlateWeight", headerName: "Pond Hot Plate LWE weight", width: 90, filterType: 'integer', cellTemplate: `<input *ngIf="params.data.PondHotPlateMaxLWE" [required]="params.data.PondHotPlateMaxLWE" type="number" step="any" [(ngModel)]="params.data.PondHotPlateWeight" [name]="'params.data[' + params.node.id + '].PondHotPlateWeight'" style="width: 99%;"/>` },
                { colId: "GeonorT200DAWeight", field: "GeonorT200DAWeight", headerName: "Geonor T200 DA LWE weight", width: 90, filterType: 'integer', cellTemplate: `<input *ngIf="params.data.GeonorT200DAMaxLWE" [required]="params.data.GeonorT200DAMaxLWE" type="number" step="any" [(ngModel)]="params.data.GeonorT200DAWeight" [name]="'params.data[' + params.node.id + '].GeonorT200DAWeight'" style="width: 99%;"/>` },
                { colId: "GeonorT200DFARWeight", field: "GeonorT200DFARWeight", headerName: "Geonor T200 DFAR LWE weight", width: 90, filterType: 'integer', cellTemplate: `<input *ngIf="params.data.GeonorT200DFARMaxLWE" [required]="params.data.GeonorT200DFARMaxLWE" type="number" step="any" [(ngModel)]="params.data.GeonorT200DFARWeight" [name]="'params.data[' + params.node.id + '].GeonorT200DFARWeight'" style="width: 99%;"/>` },
                { colId: "LWEFactor", field: "LWEFactor", headerName: "LWE factor", width: 90, filterType: 'integer', cellTemplate: `<input required type="number" step="any" [(ngModel)]="params.data.LWEFactor" [name]="'params.data[' + params.node.id + '].LWEFactor'" style="width: 99%;"/>` },
                { colId: "WindFactor", field: "WindFactor", headerName: "WS factor", width: 90, filterType: 'integer', cellTemplate: `<input *ngIf="params.data.MaxWindSpeed" [required]="params.data.MaxWindSpeed" type="number" step="any" [(ngModel)]="params.data.WindFactor" [name]="'params.data[' + params.node.id + '].WindFactor'" style="width: 99%;"/>` },
                { colId: "TempFactor", field: "TempFactor", headerName: "Temp factor", width: 90, filterType: 'integer', cellTemplate: `<input required type="number" step="any" [(ngModel)]="params.data.TempFactor" [name]="'params.data[' + params.node.id + '].TempFactor'" style="width: 99%;"/>` },
                { colId: "HumidityFactor", field: "HumidityFactor", headerName: "Humidty factor", width: 90, filterType: 'integer', cellTemplate: `<input required type="number" step="any" [(ngModel)]="params.data.HumidityFactor" [name]="'params.data[' + params.node.id + '].HumidityFactor'" style="width: 99%;"/>` },

                { colId: "RemoteEndpointSquareFactor", field: "RemoteEndpointSquareFactor", headerName: "Remote endpoint LWE² factor", width: 90, filterType: 'integer', cellTemplate: `<input *ngIf="params.data.RemoteEndpointMaxLWE" [required]="params.data.RemoteEndpointMaxLWE" type="number" step="any" [(ngModel)]="params.data.RemoteEndpointSquareFactor" [name]="'params.data[' + params.node.id + '].RemoteEndpointSquareFactor'" style="width: 99%;"/>` },
                { colId: "SartoriusSquareFactor", field: "SartoriusSquareFactor", headerName: "Sartorius LWE² factor", width: 90, filterType: 'integer', cellTemplate: `<input *ngIf="params.data.SartoriusMaxLWE" [required]="params.data.SartoriusMaxLWE" type="number" step="any" [(ngModel)]="params.data.SartoriusSquareFactor" [name]="'params.data[' + params.node.id + '].SartoriusSquareFactor'" style="width: 99%;"/>` },
                { colId: "CampbellPWS100SquareFactor", field: "CampbellPWS100SquareFactor", headerName: "Campbell PWS100 LWE² factor", width: 90, filterType: 'integer', cellTemplate: `<input *ngIf="params.data.CampbellPWS100MaxLWE" [required]="params.data.CampbellPWS100MaxLWE" type="number" step="any" [(ngModel)]="params.data.CampbellPWS100SquareFactor" [name]="'params.data[' + params.node.id + '].CampbellPWS100SquareFactor'" style="width: 99%;"/>` },
                { colId: "ThiesLPMSquareFactor", field: "ThiesLPMSquareFactor", headerName: "Thies LPM LWE² factor", width: 90, filterType: 'integer', cellTemplate: `<input *ngIf="params.data.ThiesLPMMaxLWE" [required]="params.data.ThiesLPMMaxLWE" type="number" step="any" [(ngModel)]="params.data.ThiesLPMSquareFactor" [name]="'params.data[' + params.node.id + '].ThiesLPMSquareFactor'" style="width: 99%;"/>` },
                { colId: "ThiesLPMSecondarySquareFactor", field: "ThiesLPMSecondarySquareFactor", headerName: "Thies LPM Secondary LWE² factor", width: 90, filterType: 'integer', cellTemplate: `<input *ngIf="params.data.ThiesLPMSecondaryMaxLWE" [required]="params.data.ThiesLPMSecondaryMaxLWE" type="number" step="any" [(ngModel)]="params.data.ThiesLPMSecondarySquareFactor" [name]="'params.data[' + params.node.id + '].ThiesLPMSecondarySquareFactor'" style="width: 99%;"/>` },
                { colId: "OTTPluvio2SquareFactor", field: "OTTPluvio2SquareFactor", headerName: "OTT Pluvio2 LWE² factor", width: 90, filterType: 'integer', cellTemplate: `<input *ngIf="params.data.OTTPluvio2MaxLWE" [required]="params.data.OTTPluvio2MaxLWE" type="number" step="any" [(ngModel)]="params.data.OTTPluvio2SquareFactor" [name]="'params.data[' + params.node.id + '].OTTPluvio2SquareFactor'" style="width: 99%;"/>` },
                { colId: "OTTPluvio2DFARSquareFactor", field: "OTTPluvio2DFARSquareFactor", headerName: "OTT Pluvio2 DFAR LWE² factor", width: 90, filterType: 'integer', cellTemplate: `<input *ngIf="params.data.OTTPluvio2DFARMaxLWE" [required]="params.data.OTTPluvio2DFARMaxLWE" type="number" step="any" [(ngModel)]="params.data.OTTPluvio2DFARSquareFactor" [name]="'params.data[' + params.node.id + '].OTTPluvio2DFARSquareFactor'" style="width: 99%;"/>` },
                { colId: "OTTParsivel2SquareFactor", field: "OTTParsivel2SquareFactor", headerName: "OTT Parsivel 2 LWE² factor", width: 90, filterType: 'integer', cellTemplate: `<input *ngIf="params.data.OTTParsivel2MaxLWE" [required]="params.data.OTTParsivel2MaxLWE" type="number" step="any" [(ngModel)]="params.data.OTTParsivel2SquareFactor" [name]="'params.data[' + params.node.id + '].OTTParsivel2SquareFactor'" style="width: 99%;"/>` },
                { colId: "OTTParsivel2SecondarySquareFactor", field: "OTTParsivel2SecondarySquareFactor", headerName: "OTT Parsivel 2 Secondary LWE² factor", width: 90, filterType: 'integer', cellTemplate: `<input *ngIf="params.data.OTTParsivel2SecondaryMaxLWE" [required]="params.data.OTTParsivel2SecondaryMaxLWE" type="number" step="any" [(ngModel)]="params.data.OTTParsivel2SecondarySquareFactor" [name]="'params.data[' + params.node.id + '].OTTParsivel2SecondarySquareFactor'" style="width: 99%;"/>` },
                { colId: "VaisalaPWD22SquareFactor", field: "VaisalaPWD22SquareFactor", headerName: "Vaisala PWD22 LWE² factor", width: 90, filterType: 'integer', cellTemplate: `<input *ngIf="params.data.VaisalaPWD22MaxLWE" [required]="params.data.VaisalaPWD22MaxLWE" type="number" step="any" [(ngModel)]="params.data.VaisalaPWD22SquareFactor" [name]="'params.data[' + params.node.id + '].VaisalaPWD22SquareFactor'" style="width: 99%;"/>` },
                { colId: "VaisalaPWD22SecondarySquareFactor", field: "VaisalaPWD22SecondarySquareFactor", headerName: "Vaisala PWD22 Secondary LWE² factor", width: 90, filterType: 'integer', cellTemplate: `<input *ngIf="params.data.VaisalaPWD22SecondaryMaxLWE" [required]="params.data.VaisalaPWD22SecondaryMaxLWE" type="number" step="any" [(ngModel)]="params.data.VaisalaPWD22SecondarySquareFactor" [name]="'params.data[' + params.node.id + '].VaisalaPWD22SecondarySquareFactor'" style="width: 99%;"/>` },
                { colId: "VaisalaFD71PSquareFactor", field: "VaisalaFD71PSquareFactor", headerName: "Vaisala FD71P LWE² factor", width: 90, filterType: 'integer', cellTemplate: `<input *ngIf="params.data.VaisalaFD71PMaxLWE" [required]="params.data.VaisalaFD71PMaxLWE" type="number" step="any" [(ngModel)]="params.data.VaisalaFD71PSquareFactor" [name]="'params.data[' + params.node.id + '].VaisalaFD71PSquareFactor'" style="width: 99%;"/>` },
                { colId: "PondHotPlateSquareFactor", field: "PondHotPlateSquareFactor", headerName: "Pond Hot Plate LWE² factor", width: 90, filterType: 'integer', cellTemplate: `<input *ngIf="params.data.PondHotPlateMaxLWE" [required]="params.data.PondHotPlateMaxLWE" type="number" step="any" [(ngModel)]="params.data.PondHotPlateSquareFactor" [name]="'params.data[' + params.node.id + '].PondHotPlateSquareFactor'" style="width: 99%;"/>` },
                { colId: "GeonorT200DASquareFactor", field: "GeonorT200DASquareFactor", headerName: "Geonor T200 DA LWE² factor", width: 90, filterType: 'integer', cellTemplate: `<input *ngIf="params.data.GeonorT200DAMaxLWE" [required]="params.data.GeonorT200DAMaxLWE" type="number" step="any" [(ngModel)]="params.data.GeonorT200DASquareFactor" [name]="'params.data[' + params.node.id + '].GeonorT200DASquareFactor'" style="width: 99%;"/>` },
                { colId: "GeonorT200DFARSquareFactor", field: "GeonorT200DFARSquareFactor", headerName: "Geonor T200 DFAR LWE² factor", width: 90, filterType: 'integer', cellTemplate: `<input *ngIf="params.data.GeonorT200DFARMaxLWE" [required]="params.data.GeonorT200DFARMaxLWE" type="number" step="any" [(ngModel)]="params.data.GeonorT200DFARSquareFactor" [name]="'params.data[' + params.node.id + '].GeonorT200DFARSquareFactor'" style="width: 99%;"/>` },
                { colId: "WindSquareFactor", field: "WindSquareFactor", headerName: "WS² factor", width: 90, filterType: 'integer', cellTemplate: `<input *ngIf="params.data.MaxWindSpeed" [required]="params.data.MaxWindSpeed" type="number" step="any" [(ngModel)]="params.data.WindSquareFactor" [name]="'params.data[' + params.node.id + '].WindSquareFactor'" style="width: 99%;"/>` },
                { colId: "TempSquareFactor", field: "TempSquareFactor", headerName: "Temp² factor", width: 90, filterType: 'integer', cellTemplate: `<input required type="number" step="any" [(ngModel)]="params.data.TempSquareFactor" [name]="'params.data[' + params.node.id + '].TempSquareFactor'" style="width: 99%;"/>` },
                { colId: "HumiditySquareFactor", field: "HumiditySquareFactor", headerName: "Humidty² factor", width: 90, filterType: 'integer', cellTemplate: `<input required type="number" step="any" [(ngModel)]="params.data.HumiditySquareFactor" [name]="'params.data[' + params.node.id + '].HumiditySquareFactor'" style="width: 99%;"/>` },
                { colId: "Constant", field: "Constant", headerName: "Constant", width: 90, filterType: 'integer', cellTemplate: `<input required type="number" step="any" [(ngModel)]="params.data.Constant" [name]="'params.data[' + params.node.id + '].Constant'" style="width: 99%;"/>` },
            ]
        },
        {
            headerName: "",
            children: [
                { colId: "OperationalToleranceFactor", field: "OperationalToleranceFactor", headerName: "Operation tolerance factor", width: 90, filterType: 'integer', cellTemplate: `<input required type="number" step="any" [(ngModel)]="params.data.OperationalToleranceFactor" [name]="'params.data[' + params.node.id + '].OperationalToleranceFactor'" style="width: 99%;"/>` },
                { colId: "OperationalToleranceOffset", field: "OperationalToleranceOffset", headerName: "Operation tolerance offset", width: 90, filterType: 'integer', cellTemplate: `<input required type="number" step="any" [(ngModel)]="params.data.OperationalToleranceOffset" [name]="'params.data[' + params.node.id + '].OperationalToleranceOffset'" style="width: 99%;"/>` },
            ]
        },
        {
            headerName: "",
            children: [
                { colId: "Actions", field: "Id", headerName: "", width: 80, pinnedRight: true, cellClass: "actions", cellTemplate: `<a *ngIf="parent.hasPermissionService.hasPermission('ConfigEditLWEEquations')" (click)="parent.removeEquation(params.data)" class="mdi mdi-delete" title="{{'Remove'|translate}}"></a>` },
            ]
        }
    ];

    constructor(
        private router: Router,
        private viewContainerRef: ViewContainerRef,
        private route: ActivatedRoute,
        private api: ApiService,
        private changes: ChangesService,
        private ticketTrackingSystem: TicketTrackingSystemService,
        public hasPermissionService: HasPermissionService,
        private fileDownloadService: FileDownloadService,
        public orderByPipe: OrderByPipe,
    ) {
        this.tab = location.hash ? location.hash.substring(1) : 'basicInfo';

        this.returnPath = this.route.snapshot.url[0].path.replace('/:id', '');
        const id = this.route.snapshot.params['id'];
        const copyId = this.route.snapshot.queryParams['copy'];
        const isNew = id === 'new';

        if (copyId) {
            this.item = this.api.LWEEquationProfile.get({ id: copyId });
            this.item.$promise.then(item => {
                delete this.item.Id;
                if (this.item.LWEEquations) {
                    this.item.LWEEquations.forEach(spec => {
                        delete spec.LWEEquationProfileId;
                    });
                    item.LWEEquations = this.orderByPipe.transform(item.LWEEquations, ['WeatherCategory', 'SartoriusMaxLWE', 'CampbellPWS100MaxLWE', 'ThiesLPMMaxLWE', 'ThiesLPMSecondaryMaxLWE', 'OTTPluvio2MaxLWE', 'OTTPluvio2DFARMaxLWE', 'OTTParsivel2MaxLWE', 'OTTParsivel2SecondaryMaxLWE', 'VaisalaPWD22MaxLWE', 'VaisalaPWD22SecondaryMaxLWE', 'VaisalaFD71PMaxLWE', 'PondHotPlateMaxLWE', 'GeonorT200DAMaxLWE', 'GeonorT200DFARMaxLWE', 'MetarWarning', 'MaxWindSpeed']);
                }
            });
        } else if (isNew) {
            this.item = this.api.LWEEquationProfile.create({
                Active: true,
            });
        } else {
            this.item = api.LWEEquationProfile.get({ id: id });
            this.item.$promise.then(item => {
                item.LWEEquations = this.orderByPipe.transform(item.LWEEquations, ['WeatherCategory', 'SartoriusMaxLWE', 'CampbellPWS100MaxLWE', 'ThiesLPMMaxLWE', 'ThiesLPMSecondaryMaxLWE', 'OTTPluvio2MaxLWE', 'OTTPluvio2DFARMaxLWE', 'OTTParsivel2MaxLWE', 'OTTParsivel2SecondaryMaxLWE', 'VaisalaPWD22MaxLWE', 'VaisalaPWD22SecondaryMaxLWE', 'VaisalaFD71PMaxLWE', 'PondHotPlateMaxLWE', 'GeonorT200DAMaxLWE', 'GeonorT200DFARMaxLWE', 'MetarWarning', 'MaxWindSpeed']);
            });
        }
    }

    save() {
        this.ticketTrackingSystem.trackAndSave(this.item, this.returnPath);
    }

    cancel() {
        this.router.navigateByUrl(this.returnPath);
    }

    viewHistory() {
        this.changes.show({
            SubjectType: ['LWEEquationProfile'],
            SubjectId: this.item.Id
        });
    };

    switchTab(tab) {
        location.hash = tab;
        this.tab = tab;
    };

    exportProfile() {
        var query = {
            Id: this.item.Id,
            ColumnDefs: this.columnDefs,
        };

        this.api.LWEEquationProfileExport
            .exportPost(query)
            .then((response) => this.fileDownloadService.download(response.body, (header) => response.headers.get(header)));
    }

    addEquation() {
        this.item.LWEEquations = this.item.LWEEquations || [];
        this.item.LWEEquations.unshift({});
        this.grid.gridApi.applyTransaction({ addIndex: 0, add: this.grid.rowData.slice(0, 1) })
        this.ngForm.form.updateValueAndValidity();
        this.ngForm.form.markAsDirty();
    }

    removeEquation(spec) {
        this.item.LWEEquations.splice(this.item.LWEEquations.indexOf(spec), 1);
        this.grid.updateFilteredRows();
        this.ngForm.form.updateValueAndValidity();
        this.ngForm.form.markAsDirty();
    }
}
