import {Component, Inject, Input, OnInit} from "@angular/core";
import {DialogComponent, DialogContentComponent, DialogHeaderComponent, FileDownloadService, SwxModule} from "swx.front-end-lib";
import { FormsModule } from "@angular/forms";
import {DIALOG_DATA} from "@angular/cdk/dialog";
import {CdkDrag, CdkDragHandle} from "@angular/cdk/drag-drop";

@Component({
    templateUrl: './RawDataDialog.component.html',
    standalone: true,
    imports: [FormsModule, SwxModule, DialogComponent, DialogHeaderComponent, DialogContentComponent, CdkDrag, CdkDragHandle]
})
export class RawDataDialogComponent implements OnInit {
    hot!: any;
    title!: string;
    
    constructor(@Inject(DIALOG_DATA) private data: any,
                private download: FileDownloadService) {
    }
    
    ngOnInit() {
        this.hot = this.data.hot;
        this.title = this.data.title;
    }

    onDownload(data: string, fileName: string) {
        this.download.download(new Blob([data]), fileName)
    }
}
