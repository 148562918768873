import {Component, ViewChild, ViewContainerRef, ViewEncapsulation} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from '../shared/Api.service';
import { ChangesService, OrderByPipe, SwxModule } from 'swx.front-end-lib';
import { TicketTrackingSystemService } from '../shared/TicketTrackingSystem.service';
import { HasPermissionService, HasPermissionPipe } from '../shared/HasPermission.pipe';
import {StationMonitoringConditionListComponent} from "../stationMonitoringProfile/StationMonitoringConditionList.component";
import { FlapContaminationAlertConditionListComponent } from './FlapContaminationAlertConditionList.component';
import { NgIf, NgFor } from '@angular/common';
import { FormsModule } from '@angular/forms';

@Component({
    templateUrl: 'FlapContaminationAlertProfileDetail.component.html',
    styleUrls: ['FlapContaminationAlertProfileDetail.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [
        SwxModule,
        FormsModule,
        NgIf,
        NgFor,
        FlapContaminationAlertConditionListComponent,
        HasPermissionPipe,
    ],
})
export class FlapContaminationAlertProfileDetailComponent {
    item: any;
    returnPath;
    tab;
    clients = this.api.Client.query();
    ibmMqServerConfigurations = this.api.IBMMQServerConfiguration.query();
    @ViewChild('ngForm', {static: true}) ngForm;
    flapContaminationAlertProfileEquipmentTypeOption;

    constructor(
        private router: Router,
        private viewContainerRef: ViewContainerRef,
        private route: ActivatedRoute,
        private api: ApiService,
        private changes: ChangesService,
        private ticketTrackingSystem: TicketTrackingSystemService,
        public hasPermissionService: HasPermissionService,
        public orderByPipe: OrderByPipe,
    ) {
        this.tab = location.hash ? location.hash.substring(1) : 'basicInfo';

        this.returnPath = this.route.snapshot.url[0].path.replace('/:id', '');
        const id = this.route.snapshot.params['id'];
        const copyId = this.route.snapshot.queryParams['copy'];
        const isNew = id === 'new';

        if (copyId) {
            this.item = this.api.FlapContaminationAlertProfile.get({ id: copyId });
            this.item.$promise.then(() => {
                delete this.item.Id;

                if (this.item.FlapContaminationAlertProfileConditions) {
                    this.item.FlapContaminationAlertProfileConditions.forEach(condition => {
                        delete condition.FlapContaminationAlertProfileId;

                        var newId = StationMonitoringConditionListComponent.uuidv4();
                        this.item.FlapContaminationAlertProfileConditions.filter(c => c.ParentConditionId === condition.Id).forEach(child => {
                            child.Id = StationMonitoringConditionListComponent.uuidv4();
                            child.ParentConditionId = newId;
                        });

                        condition.Id = newId;
                    });
                }
            });
        } else if (isNew) {
            this.item = this.api.FlapContaminationAlertProfile.create({
                Active: true,
            });
        } else {
            this.item = this.api.FlapContaminationAlertProfile.get({ id: id });
        }

        this.item.$promise.then(_ => {
            this.item.FlapContaminationAlertProfileConditions = this.item.FlapContaminationAlertProfileConditions || [];
            this.item.FlapContaminationAlertProfileConditions = this.orderByPipe.transform(this.item.FlapContaminationAlertProfileConditions, 'Order');

            this.switchTab(this.tab);
        });
    }

    save() {
        this.item.$promise.then(item => {
            this.ticketTrackingSystem.trackAndSave(this.item, this.returnPath);
        });
    }

    cancel() {
        this.router.navigateByUrl(this.returnPath);
    }

    viewHistory() {
        this.changes.show({
            SubjectType: ['FlapContaminationAlertProfile'],
            SubjectId: this.item.Id
        });
    }

    switchTab(tab) {
        location.hash = tab;
        this.tab = tab;
    }

}
