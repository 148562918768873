<swx-dialog cdkDrag>
    <swx-dialog-header cdkDragHandle>
        {{ title }}
    </swx-dialog-header>
    <swx-dialog-content>
        <pre>{{rawData}}</pre>
    </swx-dialog-content>
    <swx-dialog-footer>
        <input type="button" [disabled]="!rawData" (click)="onDownload(rawData, 'raw_request.txt')" value="{{'Download'|translate}}" />
    </swx-dialog-footer>
</swx-dialog>
