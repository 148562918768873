<form #form #ngForm="ngForm" (submit)="save()">
    <page-title>{{('Airport'|translate) + ': ' + (item.Name || 'new')}}</page-title>
    <div id="userEditPage" class="noMargins">
        <div class="toolbar">
            <a class="tab" (click)="switchTab('basicInfo')" [class.active]="tab == 'basicInfo'" [class.invalid]="!ngForm.form.controls.basicInfoForm?.valid">{{'Basic info'|translate}}</a>
            <input type="submit" [disabled]="!ngForm.form.valid || ngForm.form.pristine" value="{{'Save'|translate}}" />
            <input type="button" (click)="cancel()" value="{{'Cancel'|translate}}" />
            <input type="button" *ngIf="('DataChanges'|hasPermission)" (click)="viewHistory()" [disabled]="!item.Id" value="{{'View history'|translate}}" />
        </div>
        <fieldset class="form contentMargins" [disabled]="!('ConfigEditAirports'|hasPermission)" ngModelGroup="basicInfoForm">
            <label>
                <span class="caption">{{'Name'|translate}}</span>
                <input type="text" required [(ngModel)]="item.Name" name="Name" maxlength="100" />
            </label>
            <label>
                <span class="caption">{{'Active'|translate}}</span>
                <input type="checkbox" [(ngModel)]="item.Active" name="Active" />
            </label>
            <label>
                <span class="caption">{{'Is failsafe airport?'|translate}}</span>
                <input type="checkbox" [(ngModel)]="item.IsFailsafeAirport" name="IsFailsafeAirport" />
            </label>
            <label>
                <span class="caption">{{'IATA Code'|translate}}</span>
                <input type="text" required [(ngModel)]="item.IATACode" maxlength="3" name="IATACode" />
            </label>
            <label>
                <span class="caption">{{'ICAO Code'|translate}}</span>
                <input type="text" required [(ngModel)]="item.ICAOCode" name="ICAOCode" maxlength="4" />
            </label>
            <label>
                <span class="caption">{{'Latitude'|translate}}</span>
                <input type="number" min="-180" max="180" step="any" required [(ngModel)]="item.Latitude" name="Latitude" />
            </label>
            <label>
                <span class="caption">{{'Longitude'|translate}}</span>
                <input type="number" min="-180" max="180" step="any" required [(ngModel)]="item.Longitude" name="Longitude" />
            </label>
            <label>
                <span class="caption">{{'City'|translate}}</span>
                <input type="text" [(ngModel)]="item.City" name="City" />
            </label>
            <label>
                <span class="caption">{{'Country'|translate}}</span>
                <autocomplete [(ngModel)]="item.Country" name="Country" [options]="countries$|async" required></autocomplete>
            </label>
            <label>
                <span class="caption">{{'Region'|translate}}</span>
                <autocomplete [(ngModel)]="item.Region" name="Region" [options]="regions$|async" required></autocomplete>
            </label>
            <label>
                <span class="caption">{{'Time zone'|translate}}</span>
                <input type="text" required [(ngModel)]="item.TimeZone" name="TimeZone" />
            </label>

            <div *ngIf="stations.length > 0">
                <label>
                    <span class="caption">{{'Has SureforeCast AllClear?'|translate}}</span>
                    <input type="checkbox" [(ngModel)]="item.HasSureForecastAllClear" name="HasSureForecastAllClear" />
                </label>

                <!-- Nowcast -->
                <div *ngIf="item.HasSureForecastAllClear">
                    <label>
                        <span class="caption">{{'Has Nowcast?'|translate}}</span>
                        <input type="checkbox" [(ngModel)]="item.HasNowcast" name="HasNowcast" (change)="onNowcastChange()" />
                    </label>
                    <ng-container *ngIf="item.HasNowcast">
                        <label>
                            <span class="caption">{{'Nowcast Station (Simulation)'|translate}}</span>
                            <select [(ngModel)]="item.NowcastStatioGen3Id" name="NowcastStatioGen3Id" [selectedIndex]="-1">
                                <option [ngValue]="null">{{'None'|translate}}</option>
                                <option *ngFor="let station of stations|filter:{StationType:'Simulation'}" [value]="station.Id">
                                    {{station.Name}}
                                </option>
                            </select>
                        </label>
                        <label *ngIf="item.NowcastStatioGen3Id">
                            <span class="caption">{{'Nowcast SSM (Simulation)'|translate}}</span>
                            <select [(ngModel)]="item.NowcastSSMGen2Id" name="NowcastSSMGen2Id" [selectedIndex]="-1">
                                <option [ngValue]="null">{{'None'|translate}}</option>
                                <option *ngFor="let ssm of sensorSelectMatrices|filter:{StationType:'Simulation'}" [value]="ssm.Id">
                                    {{ssm.Name}}
                                </option>
                            </select>
                        </label>

                        <label>
                            <span class="caption">{{'Nowcast Station (Gen 2)'|translate}}</span>
                            <select [(ngModel)]="item.NowcastStationGen2Id" name="NowcastStationGen2Id" [selectedIndex]="-1">
                                <option [ngValue]="null">{{'None'|translate}}</option>
                                <option *ngFor="let station of stations|filter:{StationType:'Gen2'}" [value]="station.Id">
                                    {{station.Name}}
                                </option>
                            </select>
                        </label>
                        <label *ngIf="item.NowcastStationGen2Id">
                            <span class="caption">{{'Nowcast SSM (Gen 2)'|translate}}</span>
                            <select [(ngModel)]="item.NowcastSSMGen2Id" name="NowcastSSMGen2Id" [selectedIndex]="-1">
                                <option [ngValue]="null">{{'None'|translate}}</option>
                                <option *ngFor="let ssm of sensorSelectMatrices|filter:{StationType:'Gen2'}" [value]="ssm.Id">
                                    {{ssm.Name}}
                                </option>
                            </select>
                        </label>

                        <label>
                            <span class="caption">{{'Nowcast Station (Gen 3 A)'|translate}}</span>
                            <select [(ngModel)]="item.NowcastStationGen3AId" name="NowcastStationGen3AId" [selectedIndex]="-1">
                                <option [ngValue]="null">{{'None'|translate}}</option>
                                <option *ngFor="let station of stations|filter:{StationType:'Gen3'}" [value]="station.Id">
                                    {{station.Name}}
                                </option>
                            </select>
                        </label>
                        <label *ngIf="item.NowcastStationGen3AId">
                            <span class="caption">{{'Nowcast SSM (Gen 3 A)'|translate}}</span>
                            <select [(ngModel)]="item.NowcastSSMGen3AId" name="NowcastSSMGen3AId" [selectedIndex]="-1">
                                <option [ngValue]="null">{{'None'|translate}}</option>
                                <option *ngFor="let ssm of sensorSelectMatrices|filter:{StationType:'Gen3'}" [value]="ssm.Id">
                                    {{ssm.Name}}
                                </option>
                            </select>
                        </label>


                        <label>
                            <span class="caption">{{'Nowcast Station (Gen 3 B)'|translate}}</span>
                            <select [(ngModel)]="item.NowcastStationGen3BId" name="NowcastStationGen3BId" [selectedIndex]="-1">
                                <option [ngValue]="null">{{'None'|translate}}</option>
                                <option *ngFor="let station of stations|filter:{StationType:'Gen3'}" [value]="station.Id">
                                    {{station.Name}}
                                </option>
                            </select>
                        </label>
                        <label *ngIf="item.NowcastStationGen3BId">
                            <span class="caption">{{'Nowcast SSM (Gen 3 B)'|translate}}</span>
                            <select [(ngModel)]="item.NowcastSSMGen3BId" name="NowcastSSMGen3BId" [selectedIndex]="-1">
                                <option [ngValue]="null">{{'None'|translate}}</option>
                                <option *ngFor="let ssm of sensorSelectMatrices|filter:{StationType:'Gen3'}" [value]="ssm.Id">
                                    {{ssm.Name}}
                                </option>
                            </select>
                        </label>
                        <label>

                            <span class="caption">{{'Nowcast Station (Gen V)'|translate}}</span>
                            <select [(ngModel)]="item.NowcastStationGenVId" name="NowcastStationGenVId" [selectedIndex]="-1">
                                <option [ngValue]="null">{{'None'|translate}}</option>
                                <option *ngFor="let station of stations|filter:{StationType:'Gen5'}" [value]="station.Id">
                                    {{station.Name}}
                                </option>
                            </select>
                        </label>
                        <label *ngIf="item.NowcastStationGenVId">
                            <span class="caption">{{'Nowcast SSM (Gen V)'|translate}}</span>
                            <select [(ngModel)]="item.NowcastSSMGenVId" name="NowcastSSMGenVId" [selectedIndex]="-1">
                                <option [ngValue]="null">{{'None'|translate}}</option>
                                <option *ngFor="let ssm of sensorSelectMatrices|filter:{StationType:'Gen5'}" [value]="ssm.Id">
                                    {{ssm.Name}}
                                </option>
                            </select>
                        </label>

                        <!--                 Nowcast debug string -->
                        <label>
                            <span class="caption">{{'Debug nowcast response'|translate}}</span>
                            <input type="checkbox" [(ngModel)]="useDebugNowcast" name="useDebugNowcast" (change)="onUseDebugNowcastChange(useDebugNowcast)" />
                        </label>
                        <div *ngIf="useDebugNowcast">
                            <nowcast-debug value="item.DebugNowcast" value-changed="onDebugValueChanged(value)"></nowcast-debug>
                        </div>
                    </ng-container>
                </div>
            </div>


            <label>
                <span class="caption">{{'Use FlightAware Firehose?'|translate}}</span>
                <input type="checkbox" [(ngModel)]="item.UseFlightAwareFirehose" name="UseFlightAwareFirehose" />
            </label>

            <label>
                <span class="caption">{{'METAR source'|translate}}</span>
                <select [(ngModel)]="item.MetarSource" name="MetarSource">
                    <option [ngValue]="null">{{'Default'|translate}}</option>
                    <option *ngFor="let ms of metarSources|keyvalue" [value]="ms.key">
                        {{ms.value}}
                    </option>
                </select>
            </label>
            <label *ngIf="item.MetarSource == 'Debug'">
                <span class="caption">{{'METAR string'|translate}}</span>
                <input type="text" ng-required="item.MetarSource == 'Debug'" [(ngModel)]="item.DebugMetar" name="DebugMetar" style="width: 400px;" />
            </label>

            <label>
                <span class="caption">{{'Debug NOAA RAP wind speed (m/s)'|translate}}</span>
                <input type="number" min="0" step="any" [(ngModel)]="item.DebugNoaaRapWindSpeed" name="DebugNoaaRapWindSpeed" />
            </label>
            <label>
                <span class="caption">{{'Debug Nowcast LWE12 expectation (%)'|translate}}</span>
                <input type="number" min="0" max="100" step="any" [(ngModel)]="item.DebugLweForecastExpectation" name="DebugLweForecastExpectation" />
            </label>
        </fieldset>
    </div>
</form>
