import {Component, OnInit, ViewChild, ViewContainerRef} from "@angular/core";
import {ApiService} from "../shared/Api.service";
import { IAgGridColGroupDef, StorageService, ValueFormatters, SwxModule, GridModule } from "swx.front-end-lib";
import {ValueFormatterParams} from "ag-grid-community";
import moment from "moment/moment";
import { NgFor } from "@angular/common";
import { FormsModule } from "@angular/forms";

@Component({
    templateUrl: './YvrWeatherHot.component.html',
    standalone: true,
    imports: [SwxModule, FormsModule, NgFor, GridModule]
})
export class YvrWeatherHotComponent implements OnInit {
    @ViewChild('grid') grid;
    storeKey = 'YvrWeatherHotQuery';
    query: any;
    datePickerOptions = {
        maxDate: new Date(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate(), new Date().getUTCHours(), new Date().getUTCMinutes(), new Date().getUTCSeconds()),
        dateFormat: 'yy-mm-dd',
        useUtc: true
    };
    clients = this.api.Client.query();
    airports = this.api.Airport.query();
    resource = this.api.YvrWeatherHot;
    exportResourcePost = this.api.YvrWeatherHotExport;

    constructor(private api: ApiService,
                private viewContainerRef: ViewContainerRef,
                private storage: StorageService,
                private formatters: ValueFormatters) {
    }

    ngOnInit() {
        const storedQuery = this.storage.load(this.storeKey);
        if (storedQuery) {
            this.query = storedQuery;
        }
        else {
            this.resetQuery();
        }
    }

    resetQuery() {
        this.query = {
            Filters: []
        };
    }

    apply() {
        this.storage.save(this.storeKey, this.query);
        this.grid.refresh();
    }

    columnDefs: IAgGridColGroupDef[] = [
        {
            headerName: 'YVR Weather HOT',
            children: [
                { colId: "id", field: "id", headerName: "#", width: 90, filterType: "integer", pinned: 'left' },
                { colId: "datetime_utc_date", field: "datetime_utc", headerName: "Date", width: 100, pinned: 'left', valueFormatter: this.formatters.utcDateFormatter() },
                { colId: "datetime_utc_time", field: "datetime_utc", headerName: "Time", width: 80, excelIgnore: true, pinned: 'left', valueFormatter: this.formatters.utcTimeLongFormatter() },
                { colId: "ClientName", field: "ClientName", headerName: "Client", width: 140, pinned: 'left' },
                { colId: "AirportICAOCode", field: "AirportICAOCode", headerName: "Airport", width: 80, pinned: 'left' },
            ]
        },
        {
            headerName: "Data",
            children: [
                { colId: "id", field: "id", headerName: "id", type: "integer" },
                { colId: "datetime_utc", field: "datetime_utc", headerName: "datetime_utc", excelIgnore: true, valueFormatter: this.formatters.utcTimeLongFormatter() },
                { colId: "station_weather_id", field: "station_weather_id", headerName: "station_weather_id", filterType: "integer" },
                { colId: "station_weather_timestamp", field: "station_weather_timestamp", headerName: "station_weather_timestamp", filterType: "date", valueFormatter: (params: ValueFormatterParams) => params.value ? moment.utc(params.value).format('YYYY-MM-DDTHH:mm:ssZ') : '' },
                { colId: "metar_weather_id", field: "metar_weather_id", headerName: "metar_weather_id", filterType: "integer" },
                { colId: "metar_weather_timestamp", field: "metar_weather_timestamp", headerName: "metar_weather_timestamp", filterType: "date", valueFormatter: (params: ValueFormatterParams) => params.value ? moment.utc(params.value).format('YYYY-MM-DDTHH:mm:ssZ') : '' },
                { colId: "station_temperature", field: "station_temperature", headerName: "station_temperature (degrees)", filterType: "float" },
                { colId: "station_weather_type", field: "station_weather_type", headerName: "station_weather_type", filterType: "float" },
                { colId: "station_relative_humidity", field: "station_relative_humidity", headerName: "station_relative_humidity (%)", filterType: "float" },
                { colId: "station_wind_direction", field: "station_wind_direction", headerName: "station_wind_direction (degrees)", filterType: "float" },
                { colId: "station_wind_speed", field: "station_wind_speed", headerName: "station_wind_speed (knots)", filterType: "float" },
                { colId: "station_dew_point", field: "station_dew_point", headerName: "station_dew_point (degrees Celsius)", filterType: "float" },
                { colId: "station_frost_point", field: "station_frost_point", headerName: "station_frost_point (degrees Celsius)", filterType: "float" },
                { colId: "station_visibility", field: "station_visibility", headerName: "station_visibility (statute miles)", filterType: "float" },
                { colId: "station_lwe", field: "station_lwe", headerName: "station_lwe (g/dm²/h)", filterType: "float" },
                { colId: "metar_raw_message", field: "metar_raw_message", headerName: "metar_raw_message" },
                { colId: "metar_weather_string", field: "metar_weather_string", headerName: "metar_weather_string" },
                { colId: "metar_temperature", field: "metar_temperature", headerName: "metar_temperature (degrees Celsius)", filterType: "float" },
                { colId: "metar_wind_speed", field: "metar_wind_speed", headerName: "metar_wind_speed (knots)", filterType: "float" },
                { colId: "metar_wind_gust", field: "metar_wind_gust", headerName: "metar_wind_gust (knots)", filterType: "float" },
                { colId: "metar_visibility", field: "metar_visibility", headerName: "metar_visibility (statute miles)", filterType: "float" },
                { colId: "station_type1_aluminum_hot", field: "station_type1_aluminum_hot", headerName: "station_type1_aluminum_hot (Minutes)", filterType: "float" },
                { colId: "station_type1_composite_hot", field: "station_type1_composite_hot", headerName: "station_type1_composite_hot (Minutes)", filterType: "float" },
                { colId: "station_type4_generic_tc_hot", field: "station_type4_generic_tc_hot", headerName: "station_type4_generic_tc_hot (Minutes)", filterType: "float" },
                { colId: "station_type4_generic_faa_hot", field: "station_type4_generic_faa_hot", headerName: "station_type4_generic_faa_hot (Minutes)", filterType: "float" },
                { colId: "station_type4_safewing_tc_hot", field: "station_type4_safewing_tc_hot", headerName: "station_type4_safewing_tc_hot (Minutes)", filterType: "float" },
                { colId: "station_type4_safewing_faa_hot", field: "station_type4_safewing_faa_hot", headerName: "station_type4_safewing_faa_hot (Minutes)", filterType: "float" },
                { colId: "station_type4_dow_endurance_eg106_tc_hot", field: "station_type4_dow_endurance_eg106_tc_hot", headerName: "station_type4_dow_endurance_eg106_tc_hot (Minutes)", filterType: "float" },
                { colId: "station_type4_dow_endurance_eg106_faa_hot", field: "station_type4_dow_endurance_eg106_faa_hot", headerName: "station_type4_dow_endurance_eg106_faa_hot (Minutes)", filterType: "float" },
                { colId: "metar_type1_aluminum_hot", field: "metar_type1_aluminum_hot", headerName: "metar_type1_aluminum_hot (Minutes)", filterType: "float" },
                { colId: "metar_type1_composite_hot", field: "metar_type1_composite_hot", headerName: "metar_type1_composite_hot (Minutes)", filterType: "float" },
                { colId: "metar_type4_generic_tc_hot", field: "metar_type4_generic_tc_hot", headerName: "metar_type4_generic_tc_hot (Minutes)", filterType: "float" },
                { colId: "metar_type4_generic_faa_hot", field: "metar_type4_generic_faa_hot", headerName: "metar_type4_generic_faa_hot (Minutes)", filterType: "float" },
                { colId: "metar_type4_safewing_tc_hot", field: "metar_type4_safewing_tc_hot", headerName: "metar_type4_safewing_tc_hot (Minutes)", filterType: "float" },
                { colId: "metar_type4_safewing_faa_hot", field: "metar_type4_safewing_faa_hot", headerName: "metar_type4_safewing_faa_hot (Minutes)", filterType: "float" },
                { colId: "metar_type4_dow_endurance_eg106_tc_hot", field: "metar_type4_dow_endurance_eg106_tc_hot", headerName: "metar_type4_dow_endurance_eg106_tc_hot (Minutes)", filterType: "float" },
                { colId: "metar_type4_dow_endurance_eg106_faa_hot", field: "metar_type4_dow_endurance_eg106_faa_hot", headerName: "metar_type4_dow_endurance_eg106_faa_hot (Minutes)", filterType: "float" },
            ]
        },
    ];
}
