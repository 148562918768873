<swx-dialog cdkDrag>
    <swx-dialog-header cdkDragHandle>
        {{title}}
    </swx-dialog-header>
    <swx-dialog-content>
        <table class="table">
            <tr>
                <th>{{'Request'|translate}}</th>
                <th>{{'Response'|translate}}</th>
            </tr>
            <tr>
                <td>
                    <textarea readonly cols="24" rows="18" [(ngModel)]="hot.RawRequest"></textarea>
                    <br /><br />
                    <input type="button" [disabled]="!hot.RawRequest" (click)="onDownload(hot.RawRequest, 'raw_request.txt')" value="{{'Download'|translate}}" />
                </td>
                <td>
                    <textarea readonly cols="24" rows="18" [(ngModel)]="hot.HotResponse.RawData"></textarea>
                    <br /><br />
                    <input type="button" [disabled]="!hot.HotResponse.RawData" (click)="onDownload(hot.HotResponse.RawData, 'raw_response.txt')" value="{{'Download'|translate}}" />
                </td>
            </tr>
        </table>
    </swx-dialog-content>
</swx-dialog>

