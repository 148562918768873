import { Component, Input } from '@angular/core';
import {DecimalPipe, NgIf, NgSwitch, NgSwitchCase, NgSwitchDefault} from '@angular/common';

@Component({
    selector: 'station-weather-icon',
    templateUrl: 'StationWeatherIcon.component.html',
    standalone: true,
    imports: [NgIf, NgSwitch, NgSwitchCase, NgSwitchDefault, DecimalPipe]
})
export class StationWeatherIconComponent {
    @Input() stationInfo;
}
