<div class="noMargins">
    <page-title>{{'HOTs'|translate}}</page-title>
    <form #form="ngForm" class="toolbar gridFilters">
        <label class="inline">
            <span class="caption">{{'Client'|translate}}</span>
            <multiselect [(ngModel)]="query.ClientId" name="ClientId">
                <multiselect-option *ngFor="let client of clients|orderBy:'Name'" [value]="client.Id" [name]="client.Name"></multiselect-option>
            </multiselect>
        </label>
        <label class="inline">
            <span class="caption">{{'Airport'|translate}}</span>
            <multiselect [(ngModel)]="query.AirportId" name="query.AirportId" >
                <multiselect-option *ngFor="let airport of airports|orderBy:['-HasStation','ICAOCode']" [value]="airport.Id" [name]="airport.ICAOCode + '/' + airport.IATACode + ' - ' + airport.Name"></multiselect-option>
            </multiselect>
        </label>
        <label class="field">
            <input type="checkbox" [(ngModel)]="query.RowPerHot" name="query.RowPerHot" (ngModelChange)="updateColumns('rowperhot')">
            <span class="caption">{{'Multiple rows per fluid HOTs'|translate}}</span>
        </label>
        <label>
            <span class="caption">{{'Comparison fluids'|translate}}</span>
            <multiselect [all]="false" [(ngModel)]="query.FluidId" [width]="600" name="query.FluidId" (change)="updateColumns('fluid')">
                <multiselect-option *ngFor="let fluid of fluids|orderBy:['FluidProfileName', 'Type', 'Name']" [value]="fluid.Id" [name]="fluid.FluidProfileName + ' - ' + fluid.Type + ' - ' + fluid.Name + ' (' + fluid.Dilution + ')'"></multiselect-option>
            </multiselect>
        </label>
        <label class="inline">
            <span class="caption">{{'From'|translate}}</span>
            <input date [options]="datePickerOptions" [(ngModel)]="query.FromDate" required placeholder="Start Date" name="query.FromDate">
        </label>
        <label class="inline">
            <span class="caption">{{'To'|translate}}</span>
            <input date [options]="datePickerOptions" [(ngModel)]="query.ToDate" name="query.ToDate">
        </label>
        <filters [(ngModel)]="query.Filters" [columnDefs]="columnDefs" name="query.Filters"></filters>
        <button type="button" (click)="resetQuery()"><span class="mdi">❌</span> {{'Reset'|translate}}</button>
        <button type="button" (click)="apply()" [disabled]="!form.valid"><span class="mdi mdi-refresh"></span> {{'Apply'|translate}}</button>
        <button type="button" (click)="grid.export()" [disabled]="!form.valid" ><span class="mdi mdi-download"></span>{{'Export'|translate}}</button>
        <button type="button" (click)="grid.selectColumns()" [disabled]="!form.valid" ><span class="mdi mdi-table-edit"></span>{{'Columns'|translate}}</button>
    </form>
    <grid #grid
          [resource]="resource" 
          [exportResource]="exportResource" 
          [columnDefs]="columnDefs"
          gridId="preferences.hotData.ColumnExport" 
          [query]="query"
          [parent]="this"
          [components]="components" 
          [headerHeight]="96"></grid>
</div>
