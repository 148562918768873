/// <reference path="../../../node_modules/@types/jqueryui/index.d.ts" />

import {Component, Inject, OnInit} from "@angular/core";
import {DialogComponent, DialogContentComponent, DialogHeaderComponent, NotificationService, SwxModule} from "swx.front-end-lib";
import { NgFor, NgIf } from "@angular/common";
import { FormsModule } from "@angular/forms";
import {DIALOG_DATA, DialogRef} from "@angular/cdk/dialog";
import {ApiService} from "../shared/Api.service";
import {CdkDrag, CdkDragHandle} from "@angular/cdk/drag-drop";

@Component({
    templateUrl: "MDMUploadDialog.component.html",
    styleUrl: 'MDMUploadDialog.component.css',
    standalone: true,
    imports: [
        FormsModule,
        NgFor,
        NgIf,
        SwxModule,
        DialogComponent,
        DialogHeaderComponent,
        DialogContentComponent,
        CdkDrag,
        CdkDragHandle,
    ],
})
export class MDMUploadDialogComponent implements OnInit {
    title!: string;
    row!: any;
    refresh: any; // callback
    isRelease!: boolean;
    groups: any[];
    
    form = {
        smartGroups: {},
        pushMode: "auto",
    };

    constructor(@Inject(DIALOG_DATA) private data: any,
                private dialogRef: DialogRef,
                private api: ApiService,
                private notification: NotificationService) {}

    ngOnInit() {
        this.groups = this.api.WorkspaceOneGroup.query();
        this.title = this.data.title;
        this.row = this.data.row;
        this.isRelease = this.data.isRelease;
        this.refresh = this.data.refresh;
    }
    
    numGroupsSelected() {
        return Object.keys(this.form.smartGroups).filter(o => this.form.smartGroups[o]).length;
    }

    submitForm() {
        this.api.WorkspaceOne.post({
            appName: this.row.data.MobileAppName,
            appReleaseId: this.isRelease ? this.row.data.Id : this.row.data.MobileAppReleaseId,
            groupIds: Object.keys(this.form.smartGroups).filter(
                (k) => this.form.smartGroups[k]
            ),
            pushMode: this.form.pushMode ? this.form.pushMode : "auto",
        }).then((e) => {
            this.dialogRef.close();
            
            if (e.errors.length) {
                this.notification.show('Error: ' + e.errors.join('<br />'), { type: 'error', sticky: true })
            } else {
                this.notification.show("Successfully uploaded");
            }
            this.refresh();
        });
    }
}
