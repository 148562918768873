import {Component, ViewChild, ViewContainerRef} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from '../shared/Api.service';
import { ChangesService, SwxModule } from 'swx.front-end-lib';
import { TicketTrackingSystemService } from '../shared/TicketTrackingSystem.service';
import { HasPermissionService, HasPermissionPipe } from '../shared/HasPermission.pipe';
import { NgIf, NgFor } from '@angular/common';
import { FormsModule } from '@angular/forms';

@Component({
    templateUrl: 'WeatherTypeCountProfileDetail.component.html',
    standalone: true,
    imports: [SwxModule, FormsModule, NgIf, NgFor, HasPermissionPipe]
})
export class WeatherTypeCountProfileDetailComponent {
    item: any;
    returnPath;
    @ViewChild('ngForm', {static: true}) ngForm;
    tab;
    weatherTypes = this.api.WeatherTypeNg.query();
    counts: any;

    constructor(
        private router: Router,
        private viewContainerRef: ViewContainerRef,
        private route: ActivatedRoute,
        private api: ApiService,
        private changes: ChangesService,
        private ticketTrackingSystem: TicketTrackingSystemService,
        public hasPermissionService: HasPermissionService,
    ) {
        this.tab = location.hash ? location.hash.substring(1) : 'basicInfo';

        this.returnPath = this.route.snapshot.url[0].path.replace('/:id', '');
        const id = this.route.snapshot.params['id'];
        const copyId = this.route.snapshot.queryParams['copy'];
        const isNew = id === 'new';

        if (copyId) {
            this.item = api.WeatherTypeCountProfile.get({id: copyId});
            this.item.$promise.then(() => {
                delete this.item.Id;

                if (this.item.WeatherTypeCounts) {
                    this.item.WeatherTypeCounts.forEach(related => {
                        delete related.WeatherTypeCountProfileId;
                    });
                }
            });
        } else if (isNew) {
            this.item = this.api.WeatherTypeCountProfile.create({
                Active: true,
            });
        } else {
            this.item = api.WeatherTypeCountProfile.get({id: id});
        }

        this.item.$promise.then(() => this.loadWeatherTypeCounts());
    }

    save() {
        this.item.$promise.then(() => {
            this.ticketTrackingSystem.trackAndSave(this.item, this.returnPath);
        });
    }

    cancel() {
        this.router.navigateByUrl(this.returnPath);
    }

    viewHistory() {
        this.changes.show({
            SubjectType: ['WeatherTypeCountProfile'],
            SubjectId: this.item.Id
        });
    };

    switchTab(tab) {
        location.hash = tab;
        this.tab = tab;
    };

    loadWeatherTypeCounts() {
        this.weatherTypes.$promise.then(() => {
            this.item.WeatherTypeCounts = this.item.WeatherTypeCounts || [];

            var counts = {};
            this.item.WeatherTypeCounts.forEach(weatherTypeCount => {
                counts[weatherTypeCount.InstantWeatherTypeNg] = counts[weatherTypeCount.InstantWeatherTypeNg] || {};
                counts[weatherTypeCount.InstantWeatherTypeNg][weatherTypeCount.MostRecentWeatherTypeNg] = weatherTypeCount;
            });

            Object.keys(this.weatherTypes).forEach(instantWeatherType => {
                counts[instantWeatherType] = counts[instantWeatherType] || {};
                Object.keys(this.weatherTypes).forEach(mostRecentWeatherType => {
                    if (instantWeatherType != mostRecentWeatherType
                        && typeof counts[instantWeatherType][mostRecentWeatherType] === 'undefined'
                        && instantWeatherType != '$promise'
                        && instantWeatherType != '$resolved'
                        && mostRecentWeatherType != '$promise'
                        && mostRecentWeatherType != '$resolved'
                    ) {
                        counts[instantWeatherType][mostRecentWeatherType] = {
                            InstantWeatherTypeNg: instantWeatherType,
                            MostRecentWeatherTypeNg: mostRecentWeatherType,
                            Count: 1
                        };
                        this.item.WeatherTypeCounts.push(counts[instantWeatherType][mostRecentWeatherType]);
                    }
                });
            });
            
            this.counts = counts;
        });
    }
}
