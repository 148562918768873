import {Component, OnInit, ViewChild, ViewContainerRef} from "@angular/core";
import {
    IAgGridColGroupDef,
    QuerySerializerService,
    StorageService,
    ValueFormatters,
    yesNo,
    SwxModule,
    GridModule,
    RawDataViewDialogComponent
} from "swx.front-end-lib";
import {ApiService} from "../shared/Api.service";
import {HotGridColumnsService} from "../hotData/HotGridColumns.service";
import {RequestDetailDialogComponent} from "../hotMap/RequestDetailDialog.component";
import { NgFor } from "@angular/common";
import { FormsModule } from "@angular/forms";
import {Dialog} from "@angular/cdk/dialog";

@Component({
    templateUrl: './DeicingRequest.component.html',
    standalone: true,
    imports: [SwxModule, FormsModule, NgFor, GridModule]
})
export class DeicingRequestComponent implements OnInit {
    storeKey = 'deicingRequestQuery';
    @ViewChild('grid') grid;
    query: any;
    datePickerOptions = {
        maxDate: new Date(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate(), new Date().getUTCHours(), new Date().getUTCMinutes(), new Date().getUTCSeconds()),
        dateFormat: 'yy-mm-dd',
        useUtc: true
    };

    clients = this.api.Client.query();
    airports = this.api.Airport.query();
    resource = this.api.DeicingRequest;
    exportResourcePost = this.api.DeicingRequestExport;
    
    constructor(private storage: StorageService,
                private api: ApiService,
                private dialog: Dialog,
                private formatters: ValueFormatters,
                private hotGridColumns: HotGridColumnsService,
                private querySerializer: QuerySerializerService) {
    }
    
    ngOnInit() {
        const storedQuery = this.storage.load(this.storeKey);
        if (storedQuery){
            this.query = storedQuery;
        }
        else {
            this.resetQuery();
        }
    }

    resetQuery() {
        this.query = {
            Filters: []
        };
    };

    apply() {
        this.storage.save(this.storeKey, this.query);
        this.grid.refresh();
    }


    columnDefs: IAgGridColGroupDef[] = [
        {
            headerName: '',
            children: [
                { colId: "Date", field: "DeicingRequest.CreatedDate", headerName: "Date", width: 100, valueFormatter: this.formatters.utcDateFormatter(), pinned: 'left' },
                { colId: "Time", field: "DeicingRequest.CreatedDate", headerName: "Time", width: 70, excelIgnore: true, pinned: 'left', valueFormatter: this.formatters.utcTimeLongFormatter() },
                { colId: "DeicingRequest.Id", field: "DeicingRequest.Id", headerName: "#", width: 60 },
                { colId: "MobileDeicingRequestProviderName", field: "MobileDeicingRequestProviderName", headerName: "Deicing request provider", width: 150 },
                { colId: "DeicingResponse.ClientApiRequestId", field: "DeicingResponse.ClientApiRequestId", headerName: "Client API request # ", width: 160 },
                { colId: "DeicingResponse.Status", field: "DeicingResponse.Status", headerName: "Status", width: 100, filterType: "enum", source: "DeicingResponseStatus" },
                { colId: "Client.Name", field: "Client.Name", headerName: "Client", width: 200 },
                { colId: "Airport.ICAOCode", field: "Airport.ICAOCode", headerName: "Airport", width: 100 },
                { colId: "DeicingRequest.APIClientCode", field: "DeicingRequest.APIClientCode", headerName: "Client API code", width: 100 },
                { colId: "AppVersion", field: "AppVersion", headerName: "Version", width: 100 },
                { colId: "GivenAircraftIdentifier", field: "GivenAircraftIdentifier", headerName: "Given aircraft identifier", width: 80 },
                { colId: "AircraftIdentifier", field: "AircraftIdentifier", headerName: "Mapped aircraft identifier", width: 80 },
                { colId: "GivenFlightIdentifier", field: "GivenFlightIdentifier", headerName: "Flight identifier / call sign", width: 85 },
                { colId: "GivenEquipmentTypeIdentifier", field: "GivenEquipmentTypeIdentifier", headerName: "Given equipment type code", width: 90 },
                { colId: "EquipmentTypeIdentifier", field: "EquipmentTypeIdentifier", headerName: "Mapped equipment type code", width: 90 },
                { colId: "DeicingRequest.Types", field: "DeicingRequest.Types", headerName: "Request type", width: 160 },
                { colId: "DeicingResponse.Error", field: "DeicingResponse.Error", headerName: "Error", width: 150 },
                { colId: "DeicingResponse.ExtraMessage", field: "DeicingResponse.ExtraMessage", headerName: "Message", width: 150 },
                { colId: "DeicingRequest.RequestInfo", field: "DeicingRequest.RequestInfo", headerName: "Request", "cellTemplate": "<a (click)='parent.showRequestInfo(params.data)' class='mdi mdi-eye'></a>", width: 90 },
                { colId: "DeicingResponse.ResponseInfo", field: "DeicingResponse.ResponseInfo", headerName: "Raw response", "cellTemplate": "<a (click)='parent.showResponseInfo(params.data)' class='mdi mdi-eye'></a>", width: 90 },
                { colId: "DeicingRequest.Latitude", field: "DeicingRequest.Latitude", headerName: "Latitude", width: 100, filterType: "float" },
                { colId: "DeicingRequest.Longitude", field: "DeicingRequest.Longitude", headerName: "Longitude", width: 100, filterType: "float" },
            ]
        },
        {
            headerName: 'Mobile app',
            children: [
                { colId: "HotRequestId", field: "HotRequestId", headerName: "HOT request #", width: 100, filterType: "integer", "cellTemplate": "<div class='ui-grid-cell-contents' *ngIf='params.data?.HotRequestId'><a (click)='parent.showHotRequest(params.data)'>{{params.data?.HotRequestId}}</a></div>" },
            ]
        },
        {
            headerName: '',
            children: [
                { 
                    colId: "DeicingRequest.ManualEntryMode", 
                    field: "DeicingRequest.ManualEntryMode", 
                    headerName: "Manual entry mode?", 
                    width: 110, 
                    filterType: "boolean", 
                    cellRenderer: yesNo,
                },
            ]
        },
        {
            headerName: 'Weather override',
            children: [
                //{ colId: "DeicingRequest.MetarWeatherType", field: "DeicingRequest.MetarWeatherType", headerName: "Weather type", width: 150, "cellFilter": "describeEnum:'MetarWeatherType'", filterType: "enum", source: "MetarWeatherType" },
                { colId: "DeicingRequest.Temperature", field: "DeicingRequest.Temperature", headerName: "Temperature", width: 100, filterType: "float" },
            ]
        },
    ];


    showRequestInfo(data) {
        var title = "Deicing Request";
        var data = data?.DeicingRequest.RequestInfo;
        this.showInfo(title, data);
    };

    showResponseInfo(data) {
        var title = "Deicing Response";
        var data = data.DeicingResponse.ResponseInfo;
        this.showInfo(title, data);
    };

    showInfo(dialogTitle, data) {
        this.dialog.open(RawDataViewDialogComponent, {
            data: {
                rawData: data,
                title: dialogTitle,
            },
            width: '300px',
            height: '300px',
        });
    };

    showHotRequest(data) {
        this.api.HotRequest
            .query(this.querySerializer.toHttpParams({
                Filters: [
                    {
                        Field: 'HotRequest.Id',
                        Operator: 'Equals',
                        Value: data.HotRequestId
                    }
                ]
            }))
            .$promise
            .then(hots => {
                this.dialog.open(RequestDetailDialogComponent, {
                    data: {
                        hots: hots,
                        title: 'HOT request #' + data.HotRequestId,
                        columnDefs: this.hotGridcolumnDefs
                    },
                    width: '80%',
                    //headerTemplate: gridHelper.headerTemplate,
                    //enableColumnMenus: false,
                });
            });
    }
    
    hotGridcolumnDefs = this.hotGridColumns.getBaseColumns()
        .concat(this.hotGridColumns.generateHotColumns('Type1AHot', 'Type 1 aluminum'))
        .concat(this.hotGridColumns.generateHotColumns('Type1CHot', 'Type 1 composite'))
        .concat(this.hotGridColumns.generateHotColumns('Type2Hot', 'Type 2'))
        .concat(this.hotGridColumns.generateHotColumns('Type3Hot', 'Type 3'))
        .concat(this.hotGridColumns.generateHotColumns('Type4Hot', 'Type 4'))
        .concat(this.hotGridColumns.generateHotColumns('Type4HotGeneric', 'Type 4 generic'));
} 
