import {Component, Inject, OnInit} from '@angular/core';
import {DIALOG_DATA} from "@angular/cdk/dialog";
import {DialogComponent, DialogContentComponent, DialogHeaderComponent} from "swx.front-end-lib";

@Component({
    templateUrl: './StationProblemRawDataDialog.component.html',
    imports: [
        DialogComponent, DialogHeaderComponent, DialogContentComponent,
    ],
    standalone: true
})
export class StationProblemRawDataDialogComponent implements OnInit {
    title!: string;
    rawData!: string;
    
    constructor(@Inject(DIALOG_DATA) private data: any) {
    }
    
    ngOnInit() {
        this.title = this.data.title;
        this.rawData = this.data.rawData;
    }
}
