import {Component, ViewChild, ViewContainerRef} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from '../shared/Api.service';
import { Base64UploadAdapterPlugin, ChangesService, OrderByPipe, SwxModule } from 'swx.front-end-lib';
import { TicketTrackingSystemService } from '../shared/TicketTrackingSystem.service';
import { HasPermissionService, HasPermissionPipe } from '../shared/HasPermission.pipe';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { NgIf, NgFor } from '@angular/common';
import { FormsModule } from '@angular/forms';

@Component({
    templateUrl: 'ActiveFrostProfileDetail.component.html',
    standalone: true,
    imports: [SwxModule, FormsModule, NgIf, NgFor, CKEditorModule, HasPermissionPipe]
})
export class ActiveFrostProfileDetailComponent {
    item: any;
    returnPath;
    tab;
    activeFrostTimes = this.api.ActiveFrostTime.query();
    weatherTypes = this.api.ClientWeatherType.query();
    @ViewChild('ngForm', {static: true}) ngForm;
    clients = this.api.Client.query();
    client;
    airports = this.api.Airport.query();
    availableAirports: any = [];
    airport;
    emailFroms = this.api.EmailFrom.query();
    Editor = ClassicEditor;
    ckConfig = {
        extraPlugins: [ Base64UploadAdapterPlugin ],
        toolbar: { items: [ 'bold', 'italic','|', 'link', 'imageUpload', 'heading', 'insertTable', 'bulletedList', 'numberedList', 'outdent', 'indent', '|', 'undo', 'redo' ]}
    };

    constructor(
        private router: Router,
        private viewContainerRef: ViewContainerRef,
        private route: ActivatedRoute,
        private api: ApiService,
        private changes: ChangesService,
        private ticketTrackingSystem: TicketTrackingSystemService,
        public hasPermissionService: HasPermissionService,
        private orderByPipe: OrderByPipe,
    ) {
        this.tab = location.hash ? location.hash.substring(1) : 'basicInfo';

        this.returnPath = this.route.snapshot.url[0].path.replace('/:id', '');
        const id = this.route.snapshot.params['id'];
        const copyId = this.route.snapshot.queryParams['copy'];
        const isNew = id === 'new';

        if (copyId) {
            this.item = this.api.ActiveFrostProfile.get({ id: copyId });
            this.item.$promise.then(() => {
                delete this.item.Id;
            });
        } else if (isNew) {
            this.item = this.api.ActiveFrostProfile.create({
                Active: true,
            });
        } else {
            this.item = this.api.ActiveFrostProfile.get({ id: id });
        }

        this.weatherTypes.$promise.then(() => {
            this.weatherTypes["NAN"] = "None (Wx data missing or unavailable)";
        });

        this.item.$promise.then(() => {
            this.updateAvailableAirports();
        });
    }

    save() {
        this.item.$promise.then(item => {
            this.ticketTrackingSystem.trackAndSave(this.item, this.returnPath);
        });
    }

    cancel() {
        this.router.navigateByUrl(this.returnPath);
    }

    viewHistory() {
        this.changes.show({
            SubjectType: ['ActiveFrostProfile'],
            SubjectId: this.item.Id
        });
    }

    switchTab(tab) {
        location.hash = tab;
        this.tab = tab;
    }

    updateAvailableAirports() {
        if (this.item.ClientId) {
            if (this.client == null || this.client.Id !== this.item.ClientId) {
                this.client = this.api.Client.get(this.item.ClientId);
            }

            this.client.$promise.then(client => {
                this.airports.$promise.then(() => {
                    this.item.ActiveFrostProfileAirports = this.item.ActiveFrostProfileAirports || [];
                    
                    this.availableAirports = this.orderByPipe.transform(this.airports
                        .filter(a => {
                            var clientAirport = client.ClientAirports.find(ca => ca.AirportId === a.Id);

                            if (clientAirport == null
                                || (clientAirport.StationSimulationId == null
                                    && clientAirport.StationGen2Id == null
                                    && clientAirport.StationGen3AId == null
                                    && clientAirport.StationGen3BId == null
                                    && clientAirport.StationGenVId == null)) return false;

                            return this.item.ActiveFrostProfileAirports.every(ca => ca.AirportId !== a.Id);
                        }), 'ICAOCode');
                });
            });
        }
    }

    addActiveFrostProfileAirport(airport) {
        this.item.ActiveFrostProfileAirports = this.item.ActiveFrostProfileAirports || [];
        this.item.ActiveFrostProfileAirports.push({
            AirportId: airport.Id,
        });
        this.ngForm.form.markAsDirty();
        this.updateAvailableAirports();
    }

    removeActiveFrostProfileAirport(activeFrostProfileAirport) {
        this.item.ActiveFrostProfileAirports.splice(this.item.ActiveFrostProfileAirports.indexOf(activeFrostProfileAirport), 1);
        this.ngForm.form.markAsDirty();
        this.updateAvailableAirports();
    }
}
