import {AfterViewInit, Component, OnInit, ViewChild} from "@angular/core";
import {ApiService} from "../shared/Api.service";
import {IAgGridColGroupDef, StorageService, SwxModule, GridModule } from "swx.front-end-lib";
import {StationReadingGen3ColumnService} from "../stationDataGen3/StationDataGen3.component";
import {StationDataRawCellRendererComponent} from "../stationDataGen2/StationDataRawCellRenderer.component";
import { NgFor } from "@angular/common";
import { FormsModule } from "@angular/forms";

@Component({
    templateUrl: './StationDataGenV.component.html',
    standalone: true,
    imports: [SwxModule, FormsModule, NgFor, GridModule]
})
export class StationDataGenVComponent implements OnInit, AfterViewInit {
    @ViewChild('grid') grid;
    storeKey = 'stationDataGenVQuery';
    query: any;
    datePickerOptions = {
        maxDate: new Date(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate(), new Date().getUTCHours(), new Date().getUTCMinutes(), new Date().getUTCSeconds()),
        dateFormat: 'yy-mm-dd',
        useUtc: true
    };
    stations = this.api.Station.query();
    resource = this.api.StationReadingGenV;
    exportResourcePost = this.api.StationReadingGenVExport;
    columnDefs: IAgGridColGroupDef[];
    components = {
        'stationDataRawCellRenderer': StationDataRawCellRendererComponent,
    }
    constructor(private api: ApiService,
                private storage: StorageService,
                private stationReadingGen3ColumnService: StationReadingGen3ColumnService) {
    }
    
    ngOnInit() {
        const storedQuery = this.storage.load(this.storeKey);
        if (storedQuery) {
            this.query = storedQuery;
        } else {
            this.resetQuery();
        }
        
        this.columnDefs = this.stationReadingGen3ColumnService.getColumns();
    }

    ngAfterViewInit() {
        const queryParams = new URLSearchParams(window.location.search);
        var stationId = queryParams.get('stationId');
        if (stationId) {
            this.query.StationId = [ parseInt(stationId) ];
        }
        if (stationId) {
            var fromDate = new Date();
            fromDate.setDate(fromDate.getDate() - 1);
            this.query.FromDate = fromDate.toISOString();
            this.query.ToDate = null;
            setTimeout(() => this.grid.refresh());
        }
    }

    resetQuery() {
        this.query = {
            Filters: []
        };
    }

    refresh() {
        this.storage.save(this.storeKey, this.query)
        this.grid.refresh();
    }
}
