import {Component, ViewChild, ViewContainerRef} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from '../shared/Api.service';
import { ChangesService, SwxModule } from 'swx.front-end-lib';
import { TicketTrackingSystemService } from '../shared/TicketTrackingSystem.service';
import { HasPermissionService, HasPermissionPipe } from '../shared/HasPermission.pipe';
import { FormsModule } from '@angular/forms';
import { NgIf, NgFor } from '@angular/common';

@Component({
    templateUrl: 'StationProblemConfigurationProfileDetail.component.html',
    styles: [`
        .stationProblemLevel.Warning {
            color: #ffa500;
        }

        .stationProblemLevel.Critical {
            color: #ff0000;
        }
        
        .stationProblemLevel option.Warning {
            color: #ffa500;
        }

        .stationProblemLevel option.Critical {
            color: #ff0000;
        }
    `],
    standalone: true,
    imports: [
        SwxModule,
        NgIf,
        FormsModule,
        NgFor,
        HasPermissionPipe,
    ],
})
export class StationProblemConfigurationProfileDetailComponent {
    item: any;
    returnPath;
    @ViewChild('ngForm', { static: true }) ngForm;
    @ViewChild('alertsForm', { static: true }) alertsForm;
    tab;
    stationProblemTypes = this.api.StationProblemType.query();
    stationProblemLevels = this.api.StationProblemLevel.query();
    OTTPluvio2HeaterStatuses = this.api.OTTPluvio2HeaterStatus.query();
    OTTPluvio2SensorStatuses = this.api.OTTPluvio2SensorStatus.query();
    SensorReadingVaisalaWMT700Statuses = this.api.SensorReadingVaisalaWMT700Status.query();
    SensorReadingPondHotPlateSystemStatuses = this.api.SensorReadingPondHotPlateSystemStatus.query();
    weatherProfileErrors = this.api.WeatherProfileError.query();
    OTTParsivel2SensorStatuses = this.api.OTTParsivel2SensorStatus.query();
    from: any;
    stationProblemType: any;
    
    constructor(
        private router: Router,
        private viewContainerRef: ViewContainerRef,
        private route: ActivatedRoute,
        private api: ApiService,
        private changes: ChangesService,
        private ticketTrackingSystem: TicketTrackingSystemService,
        public hasPermissionService: HasPermissionService,
    ) {
        this.tab = location.hash ? location.hash.substring(1) : 'basicInfo';
        
        this.returnPath = this.route.snapshot.url[0].path.replace('/:id', '');
        const id = this.route.snapshot.params['id'];
        const copyId = this.route.snapshot.queryParams['copy'];
        const isNew = id === 'new';

        if (copyId) {
            this.item = this.api.StationProblemConfigurationProfile.get({ id: copyId });
            this.item.$promise.then(() => {
                delete this.item.Id;

                if (this.item.StationProblemConfigurations) {
                    this.item.StationProblemConfigurations.forEach(related => {
                        delete related.StationId;
                    });
                }
            });
        } else if (isNew) {
            this.item = this.api.StationProblemConfigurationProfile.create({
                Active: true,
            });
        } else {
            this.item = this.api.StationProblemConfigurationProfile.get({ id: id });
        }

        if (this.item.$promise) {
            this.item.$promise.then(_ => {
                this.item.StationProblemConfigurations = this.item.StationProblemConfigurations || [];

                this.item.StationProblemConfigurations.forEach(configuration => {
                    configuration.Options = JSON.parse(configuration.Options || '{}')
                });
            });
        }
    }
    
    save() {
        var clonedItem = this.api.StationProblemConfigurationProfile.create(JSON.parse(JSON.stringify(this.item)));

        clonedItem.StationProblemConfigurations.forEach(configuration => configuration.Options = JSON.stringify(configuration.Options));

        this.ticketTrackingSystem.trackAndSave(clonedItem, this.returnPath);
    }

    cancel() {
        this.router.navigateByUrl(this.returnPath);
    }

    viewHistory() {
        this.changes.show({
            SubjectType: ['StationProblemConfigurationProfile'],
            SubjectId: this.item.Id
        });
    };

    switchTab(tab) {
        location.hash = tab;
        this.tab = tab;
    };

    stationProblemTypesNotEnabled() {
        return Object.keys(this.stationProblemTypes)
            .filter(r => !this.item.StationProblemConfigurations?.some(c => c.Type === r) && r !== 'ConnectionFailed')
            .sort()
    }

    addStationProblemType() {
        this.item.StationProblemConfigurations = this.item.StationProblemConfigurations || [];
        this.item.StationProblemConfigurations.push({
            Type: this.stationProblemType,
            MinimumNumberOfConsecutiveEvents: 1,
            MinimumEventsPerHour: 1,
            MinutesToPersist: 60,
            Options: {},
        });
        this.stationProblemType = null;
        this.ngForm.form.markAsDirty();
    }

    removeStationProblemConfiguration(stationProblemConfiguration) {
        this.item.StationProblemConfigurations.splice(this.item.StationProblemConfigurations.indexOf(stationProblemConfiguration), 1);
        this.ngForm.form.markAsDirty();
    }
    
    isAlarmOptionEnabled(config, option) {
        var selected = typeof config.Options !== 'string' ? [] : (config.Options || '').split(', ');
        return selected.indexOf(option) > -1;
    };

    toggleAlarmOption(config, option) {
        var selected = config.Options && typeof config.Options === 'string' ? config.Options.split(', ') : [];
        var index = selected.indexOf(option);
        if (index > -1) {
            selected.splice(index, 1);
        } else {
            selected.push(option);
        }
        config.Options = selected.join(', ');
        this.ngForm.form.markAsDirty();
    };
}
