import {Component, OnInit, ViewChild, ViewContainerRef} from "@angular/core";
import { ApiService } from "../shared/Api.service";
import {GridComponent, IAgGridColGroupDef, IAgGridColumnDef, ValueFormatters, SwxModule, GridModule, RawDataViewDialogComponent} from "swx.front-end-lib";
import { RootScope } from "../shared/RootScope.service";
import moment from 'moment';
import {StationProblemRawDataDialogComponent} from "../stationStatus/StationProblemRawDataDialog.component";
import {environment} from "../environments/environment";
import { NgFor } from "@angular/common";
import { FormsModule } from "@angular/forms";
import {Dialog} from "@angular/cdk/dialog";


@Component({
    templateUrl: './StationMonitoringAlertList.component.html',
    standalone: true,
    imports: [
        SwxModule,
        FormsModule,
        NgFor,
        GridModule,
    ],
})
export class StationMonitoringAlertListComponent implements OnInit {
    query: any;
    storeKey = 'stationMonitoringAlertQuery';
    datePickerOptions: DateTimePickerOptions;
    @ViewChild('grid', { static: true }) grid: GridComponent;
    stationMonitoringProfiles;
    ticketTrackingBaseUrl = environment.ticketTrackingBaseUrl;
    stationMonitoringAlertColumns: IAgGridColGroupDef = {
        headerName: "",
        children: [
            { colId: "Date", field: "StationMonitoringAlert.DateTime", headerName: "Date", width: 90, valueFormatter: c => c.value ? moment.utc(c.value).format(this.$root.dateFormat) : "", pinned: "left" },
            { colId: "Time", field: "StationMonitoringAlert.DateTime", headerName: "Time", width: 60, valueFormatter: c => c.value ? moment.utc(c.value).format(this.$root.timeFormat) : "", excelIgnore: true, pinned: "left" },
            { colId: "ExitedDateTime", field: "StationMonitoringAlert.ExitedDateTime", headerName: "Exited date", width: 90, valueFormatter: c => c.value ? moment.utc(c.value).format(this.$root.dateFormat) : "", pinned: "left" },
            { colId: "ExitedTime", field: "StationMonitoringAlert.ExitedDateTime", headerName: "Exited time", width: 60, valueFormatter: c => c.value ? moment.utc(c.value).format(this.$root.timeFormat) : "", excelIgnore: true, pinned: "left" },
            { colId: "StationMonitoringAlert.Id", field: "StationMonitoringAlert.Id", headerName: "#", width: 70, filterType: "integer", pinned: 'left' },
            { colId: "StationMonitoringProfileName", field: "StationMonitoringProfileName", headerName: "Profile", width: 150 },
            { colId: "StationMonitoringActionType", field: "StationMonitoringActionType", headerName: "Type", width: 100, filterType: 'enum', source: 'StationMonitoringActionType' },
            { colId: "StationMonitoringAlertAction.Recipient", field: "StationMonitoringAlertAction.Recipient", headerName: "Recipients", width: 300 },
            { colId: "StationMonitoringAlertAction.Subject", field: "StationMonitoringAlertAction.Subject", headerName: "Subject", width: 350 },
            { colId: 'Ticket', field: 'StationMonitoringAlert.TicketTrackingId', headerName: 'Ticket', width: 90, cellTemplate: '<a *ngIf="params.data?.StationMonitoringAlert.TicketTrackingId" href="{{parent.ticketTrackingBaseUrl + params.data?.StationMonitoringAlert.TicketTrackingId}}" target="_blank">{{params.data?.StationMonitoringAlert.TicketTrackingId}}</a>' },
            { colId: "StationMonitoringAlertAction.Content", field: "StationMonitoringAlertAction.Content", headerName: "Content", width: 80, cellRenderer: c => {
                    const container = document.createElement('div');
                    if (c.value) {
                        container.innerHTML = '<a href onclick="event.preventDefault();" class="mdi mdi-eye"></a>';
                        container.querySelector('a').addEventListener('click', () => {
                            const bodyIndex = c.value?.lastIndexOf("<body>");
                            const body = bodyIndex > -1 ? (c.value?.substring(bodyIndex + 6, c.value.lastIndexOf("</body>"))) : c.value;

                            this.dialog.open(RawDataViewDialogComponent, {
                                    data: {
                                        html: body,
                                        title: 'Monitoring alert'
                                    },
                                    width: '80%',
                                    height: '800px',
                                });
                        });
                    }
                    return container;
                } },
        ]
    };
    columnDefs: (IAgGridColumnDef | IAgGridColGroupDef)[] = [
        this.stationMonitoringAlertColumns,
        {
            headerName: 'Station',
            children: [
                { colId: "Station.Name", field: "Station.Name", headerName: "Station", width: 150 },
            ]
        },
        {
            headerName: 'Station reading',
            children: [
                { colId: "StationMonitoringAlert.StationProblemNames", field: "StationMonitoringAlert.StationProblemNames", headerName: "Station alarms", width: 350, sortable: false, "searchable": false },
                { colId: "StationMonitoringAlert.StationReadingRawData", field: "StationMonitoringAlert.StationReadingRawData", headerName: "Raw", width: 40, cellRenderer: c => {
                        const container = document.createElement('div');
                        if (c.value) {
                            const viewButton = document.createElement('a');
                            viewButton.innerHTML = '<span class="mdi mdi-eye"></span>';
                            viewButton.addEventListener('click', () => {
                                this.dialog.open(
                                    StationProblemRawDataDialogComponent,
                                    {
                                        data: {
                                            title: 'Raw data',
                                            rawData: c.value,
                                        },
                                        width: '640px',
                                        height: '720px',
                                    });
                            });
                            container.appendChild(viewButton)
                        }
                        return container;
                    }},
            ]
        },
        {
            headerName: 'METAR',
            children: [
                { colId: "MetarReading.TimestampDate", field: "MetarReading.Timestamp", headerName: "Date", width: 90, filterType: "date", valueFormatter: this.formatters.utcDateFormatter() },
                { colId: "MetarReading.TimestampTime", field: "MetarReading.Timestamp", headerName: "Time (UTC)", width: 60, excelIgnore: true, filterType: "date", valueFormatter: this.formatters.utcTimeFormatter() },
                { colId: "MetarReading.Id", field: "MetarReading.Id", headerName: "#", width: 80, filterType: "integer" },
                { colId: "MetarReading.WxString", field: "MetarReading.WxString", headerName: "Weather string", width: 120 },
                { colId: "MetarReading.TempC", field: "MetarReading.TempC", headerName: "Temperature (°C)", width: 60, filterType: "float" },
                { colId: "MetarReading.IsClearSky", field: "MetarReading.IsClearSky", headerName: "Clear sky?", width: 70, filterType: "integer" },
                { colId: "MetarReading.IsActiveFrost", field: "MetarReading.IsActiveFrost", headerName: "Active frost?", width: 80, filterType: "integer" },
                { colId: "MetarReading.VisibilityStatuteMi", field: "MetarReading.VisibilityStatuteMi", headerName: "Visibility (SM)", width: 80, filterType: "float" },
            ]
        },
    ];
    constructor(
        public $root: RootScope,
        private viewContainerRef: ViewContainerRef,
        public api: ApiService,
        private dialog: Dialog,
        private formatters: ValueFormatters,
    ) {
    }

    ngOnInit(): void {
        this.datePickerOptions = {
            maxDate: new Date(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate(), new Date().getUTCHours(), new Date().getUTCMinutes(), new Date().getUTCSeconds()),
        };

        if (window.localStorage[this.storeKey]) {
            this.query = JSON.parse(window.localStorage[this.storeKey]);
        } else {
            this.resetQuery();
        }

        this.api.StationMonitoringProfile.query().$promise.then(stationMonitoringProfiles => {
            this.stationMonitoringProfiles = stationMonitoringProfiles;
        });
    }

    resetQuery() {
        this.query = {
            Filters: [],
            FromDate: null,
            ToDate: null,
        };
    }

    refresh() {
        window.localStorage[this.storeKey] = JSON.stringify(this.query);
        this.grid.refresh();
    }
}
