import {Component, Inject, OnInit} from "@angular/core";
import {RawDataLinkCellRenderer} from "../hotData/RawDataLinkCellRenderer";
import {DialogComponent, DialogContentComponent, DialogHeaderComponent, GridModule} from "swx.front-end-lib";
import {DIALOG_DATA} from "@angular/cdk/dialog";
import {CdkDrag, CdkDragHandle} from "@angular/cdk/drag-drop";

@Component({
    templateUrl: './RequestDetailDialog.component.html',
    standalone: true,
    imports: [GridModule, DialogComponent, DialogHeaderComponent, DialogContentComponent, CdkDragHandle, CdkDrag]
})
export class RequestDetailDialogComponent implements OnInit {
    
    components; 
    hots!: any;
    columnDefs!: any;
    title!: string;
    
    constructor(@Inject(DIALOG_DATA) private data: any) {
    }
    
    ngOnInit() {
        this.title = this.data.title;
        this.hots = this.data.hots;
        this.columnDefs = this.data.columnDefs;
        
        this.components = {
            rawDataLinkCellRenderer: RawDataLinkCellRenderer
        }
    }
}
