import {Component, Inject, OnInit} from '@angular/core';
import { DialogComponent, DialogHeaderComponent, DialogContentComponent } from 'swx.front-end-lib';
import {DIALOG_DATA} from "@angular/cdk/dialog";

@Component({
    selector: 'swx-flap-contamination-alert-dialog',
    templateUrl: './FlapContaminationAlertDialog.component.html',
    standalone: true,
    imports: [DialogComponent, DialogHeaderComponent, DialogContentComponent],
})
export class FlapContaminationAlertDialogComponent implements OnInit {
    message!: string;
    
    constructor(@Inject(DIALOG_DATA) private data: { message: string }) {
    }
    
    ngOnInit() {
        this.message = this.data.message;
    }
}
