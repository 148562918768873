<div class="noMargins">
    <page-title>{{'Station performance'|translate}}</page-title>
    <form #form="ngForm">
        <div class="toolbar">
            <label class="inline">
                <span class="caption">{{'Station'|translate}}</span>
                <multiselect [(ngModel)]="query.StationId" name="StationId">
                    <multiselect-option *ngFor="let station of stations|orderBy:'Name'" [value]="station.Id" [name]="station.Name"></multiselect-option>
                </multiselect>
            </label>
            <label class="inline">
                <span class="caption">{{'Station type'|translate}}</span>
                <multiselect [(ngModel)]="query.StationType" name="StationType">
                    <multiselect-option *ngFor="let stationType of stationTypes|keys" [value]="stationType" [name]="stationTypes[stationType]"></multiselect-option>
                </multiselect>
            </label>
            <label class="inline">
                <span class="caption">{{'Alarms to count'|translate}}</span>
                <multiselect [(ngModel)]="query.StationProblemType" name="StationProblemType" [all]="false" (change)="updateColumns()">
                    <multiselect-option *ngFor="let stationProblemType of stationProblemTypeOptions" [value]="stationProblemType" [name]="stationProblemTypes[stationProblemType]"></multiselect-option>
                </multiselect>
            </label>
            <label class="inline">
                <span class="caption">{{'From'|translate}}</span>
                <input date [options]="datePickerOptions" [(ngModel)]="query.FromDate" required placeholder="Start Date" name="query.FromDate">
            </label>
            <label class="inline">
                <span class="caption">{{'To'|translate}}</span>
                <input date [options]="datePickerOptions" [(ngModel)]="query.ToDate" name="query.ToDate">
            </label>
            <filters [(ngModel)]="query.Filters" [columnDefs]="columnDefs" name="query.Filters"></filters>
            <button type="button" (click)="resetQuery()"><span class="mdi">❌</span> {{'Reset'|translate}}</button>
            <button type="button" (click)="refresh()" [disabled]="!form.valid"><span class="mdi mdi-refresh"></span> {{'Apply'|translate}}</button>
            <button type="button" (click)="grid.export()" [disabled]="!form.valid"><span class="mdi mdi-download"></span>{{'Export'|translate}}</button>
            <button type="button" (click)="grid.selectColumns()" [disabled]="!form.valid"><span class="mdi mdi-table-edit"></span>{{'Columns'|translate}}</button>
        </div>
    </form>

    <grid #grid
          gridId="preferences.StationPerformance.ColumnExport"
          [query]="query"
          [resource]="api.StationPerformance"
          [exportResource]="api.StationPerformanceExport"
          [columnDefs]="columnDefs">
    </grid>
</div>
