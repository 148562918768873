<form class="form contentMargins" name="form" #form="ngForm" (ngSubmit)="start()">
    <label>
        <span class="caption">{{'Station'|translate}}</span>
        <select [(ngModel)]="query.StationId" required name="stationId">
            <option *ngFor="let station of stations|orderBy:'Name'" [ngValue]="station.Id">
                {{station.Name}}
            </option>
        </select>
    </label>
    <div class="field">
        <span class="caption">{{'Sensor select matrices'|translate}}</span>
        <multiselect required [all]="false" name="query.SensorSelectMatrixId" [(ngModel)]="query.SensorSelectMatrixId">
            <multiselect-option *ngFor="let option of sensorSelectMatrices|filter:{StationType:(stations|findById:query?.StationId)?.StationType || 'Gen3'}|orderBy:'Name'" [value]="option.Id" [name]="option.Name"></multiselect-option>
        </multiselect>
    </div>
    <label *ngIf="query.ToDate && query.FromDate && moment(query.ToDate).diff(query.FromDate, 'days') > 30" style="color: #990000;">{{'Please select up to 1 month'|translate}}</label>
    <label>
        <span class="caption">{{'From'|translate}}</span>
        <input date required [options]="datePickerOptions" [(ngModel)]="query.FromDate" name="fromDate">
    </label>
    <label>
        <span class="caption">{{'To'|translate}}</span>
        <input date required [options]="datePickerOptions" [(ngModel)]="query.ToDate" name="toDate">
    </label>
    <label>
        <span class="caption">{{'Delete existing weather profiles'|translate}}</span>
        <input type="checkbox" [(ngModel)]="query.DeleteExistingWeatherProfiles" name="deleteExistingWeatherProfiles"/>
    </label>
    <br />
    <input type="submit" [disabled]="!form.valid || !form.dirty || running || moment(query.ToDate).diff(query.FromDate, 'days') > 30" [value]="'Start'|translate" />
</form>
<div class="result" *ngIf="!!results">
    <h2>{{'Result'|translate}}</h2>
    <pre>{{results}}</pre>
    <br />
</div>
