import {Component, OnInit, ViewChild, ViewContainerRef, ViewEncapsulation} from "@angular/core";
import {ApiService} from "../shared/Api.service";
import {GoogleMapsLoaderService, QuerySerializerService, StorageService, SwxModule, GridModule } from "swx.front-end-lib";
import {HotGridColumnsService} from "../hotData/HotGridColumns.service";
import {RequestDetailDialogComponent} from "./RequestDetailDialog.component";
import { NgFor, KeyValuePipe } from "@angular/common";
import { FormsModule } from "@angular/forms";
import {Dialog} from "@angular/cdk/dialog";

@Component({
    templateUrl: './HotMap.component.html',
    styleUrls: ['/HotMap.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [SwxModule, FormsModule, NgFor, GridModule, KeyValuePipe]
})
export class HotMapComponent implements OnInit {
    @ViewChild('hotMap') hotMap;
    storeKey = 'hotMapQuery'
    fromDate = new Date(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate(), new Date().getUTCHours(), new Date().getUTCMinutes(), new Date().getUTCSeconds());
    query: any;
    mapLoaded: Promise<any> = null;
    markers = []

    constructor(private api: ApiService,
                private viewContainerRef: ViewContainerRef, 
                private storage: StorageService, 
                private hotGridColumns: HotGridColumnsService, 
                private googleMapsLoaderService: GoogleMapsLoaderService,
                private dialog: Dialog,
                private querySerializerService: QuerySerializerService) {
    }

    ngOnInit() {
        this.fromDate.setDate(this.fromDate.getDate() - 7)
        const storedQuery = this.storage.load(this.storeKey);
        
        if (storedQuery) {
            this.query = storedQuery;
        } else {
            this.resetQuery();
        }
        
        this.mapLoaded = new Promise((resolve, reject) => {
            this.googleMapsLoaderService.load().then(maps => {
                var mapElement = this.hotMap.nativeElement;

                resolve(new maps.Map(mapElement,
                    {
                        zoom: 3,
                        center: new maps.LatLng(45.5017, -73.5673),
                        mapTypeId: maps.MapTypeId.TERRAIN
                    }));
            });
        });
    }

    hotRequestTypes = {
        "API": "API",
        "Portal": "Portal",
        "MobileAPI": "MOBILE API",
        "ManualDeicingReport": "Manual mode deicing report"
    };

    resetQuery() {
        this.query = {
            Filters: [],
            FromDate: this.fromDate,
            HotRequestType: Object.keys(this.hotRequestTypes)
        };
    }
    
    apply() {
        this.storage.save(this.storeKey, this.query);
        this.refreshMap();
    }

    datePickerOptions = {
        maxDate: new Date(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate(), new Date().getUTCHours(), new Date().getUTCMinutes(), new Date().getUTCSeconds()),
        dateFormat: 'yy-mm-dd',
        useUtc: true
    };

    clients = this.api.Client.query();
    resource = this.api.HotRequest;

    columnDefs = this.hotGridColumns.getBaseColumns()
        .concat(this.hotGridColumns.generateHotColumns('Type1AHot', 'Type 1 aluminum'))
        .concat(this.hotGridColumns.generateHotColumns('Type1CHot', 'Type 1 composite'))
        .concat(this.hotGridColumns.generateHotColumns('Type2Hot', 'Type 2'))
        .concat(this.hotGridColumns.generateHotColumns('Type3Hot', 'Type 3'))
        .concat(this.hotGridColumns.generateHotColumns('Type4Hot', 'Type 4'))
        .concat(this.hotGridColumns.generateHotColumns('Type4HotGeneric', 'Type 4 generic'));
    
    refreshMap() {
        this.googleMapsLoaderService.load().then(maps => {
            this.mapLoaded.then(map => {
                this.markers.forEach(marker => marker.setMap(null));
                let query = {
                    ...this.query,
                    PageSize: 100,
                    PageNumber: 1,
                }
                query.Filters = [...this.query.Filters]
                
                query.Filters.push({
                    Field: 'HotRequest.Latitude',
                    Operator: 'NotEmpty'
                });
                query.Filters.push({
                    Field: 'HotRequest.Longitude',
                    Operator: 'NotEmpty'
                });
                query = this.querySerializerService.toHttpParams(query);
                this.resource.query(query).$promise.then(items => {
                    items.forEach((item: any) => {
                        var loc = new maps.LatLng(item.HotRequest.Latitude, item.HotRequest.Longitude);

                        var icon = new maps.MarkerImage("//storage.googleapis.com/support-kms-prod/SNP_2752125_en_v0", new maps.Size(9, 9), new maps.Point(0, 0), new maps.Point(0, 0));

                        var identifier = item.ClientCode;

                        var marker = new maps.Marker({
                            map: map,
                            position: loc,
                            label: identifier,
                            icon: icon
                        });

                        this.markers.push(marker);

                        maps.event.addListener(marker, 'click', () =>
                            this.dialog.open(RequestDetailDialogComponent, {
                                data: {
                                    hots: [item],
                                    title: identifier,
                                    columnDefs: this.columnDefs
                                },
                                width: '80%',
                            })
                        )
                    })
                })
            })
        })
    }
}
