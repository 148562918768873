import {AfterViewInit, Component, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import moment from 'moment';
import { RootScope } from '../shared/RootScope.service';
import { ApiService } from '../shared/Api.service';
import { IResourceItem, IResourceItemList, GridComponent, TranslateService, ConfirmService, EllipsisPipe, FileDownloadService, IAgGridColumnDef, NotificationService, SwxModule, GridModule } from 'swx.front-end-lib';
import { TicketTrackingSystemService } from '../shared/TicketTrackingSystem.service';
import { HasPermissionService, HasPermissionPipe } from '../shared/HasPermission.pipe';
import { MDMStateCellRendererService } from "./MDMStateCellRenderer.service";
import { ValueFormatterParams } from 'ag-grid-community';
import { NgFor, NgIf } from '@angular/common';
import { FormsModule } from '@angular/forms';

const INTERNAL_BETA_STATE = {
    PROCESSING: 'Processing',
    PROCESSING_EXCEPTION: 'Processing Error',
    MISSING_EXPORT_COMPLIANCE: 'Missing export compliance',
    READY_FOR_BETA_TESTING: 'Ready for Testing',
    IN_BETA_TESTING: 'Testing',
    EXPIRED: 'Expired',
    IN_EXPORT_COMPLIANCE_REVIEW: 'Reviewing export compliance'
}

const EXTERNAL_BETA_STATE = {
    PROCESSING: 'Processing',
    PROCESSING_EXCEPTION: 'Processing Error',
    MISSING_EXPORT_COMPLIANCE: 'Missing export compliance',
    READY_FOR_BETA_TESTING: 'Ready for Testing',
    IN_BETA_TESTING: 'Testing',
    EXPIRED: 'Expired',
    READY_FOR_BETA_SUBMISSION: 'Ready to submit',
    IN_EXPORT_COMPLIANCE_REVIEW: 'Reviewing export compliance',
    WAITING_FOR_BETA_REVIEW: 'Waiting for review',
    IN_BETA_REVIEW: 'In review',
    BETA_REJECTED: 'Rejected',
    BETA_APPROVED: 'Approved',
}

const APP_VERSION_STATE = {
    ACCEPTED: 'Accepted',
    DEVELOPER_REJECTED: 'Developer Rejected',
    IN_REVIEW: 'In Review',
    INVALID_BINARY: 'Invalid binary',
    METADATA_REJECTED: 'Metadata rejected',
    PENDING_APPLE_RELEASE: 'Pending Apple Release',
    PENDING_DEVELOPER_RELEASE: 'Pending Developer Release',
    PREPARE_FOR_SUBMISSION: 'Prepare for Submission',
    PROCESSING_FOR_DISTRIBUTION: 'Processing for Distribution',
    READY_FOR_DISTRIBUTION: 'Ready for Distribution',
    READY_FOR_REVIEW: 'Ready for Review',
    REJECTED: 'Rejected',
    REPLACED_WITH_NEW_VERSION: 'Replaced with new version',
    WAITING_FOR_EXPORT_COMPLIANCE: 'Waiting for export compliance',
    WAITING_FOR_REVIEW: 'Waiting for review'
}

const PLATFORM = {
    ElectronZip: 'Windows',
    ElectronAppx: 'Windows (.appx)',
    IOSVPP: 'iOS ABM',
    IOSEnterprise: 'iOS Enterprise .ipa',
    IOSTestFlight: 'iOS TestFlight',
    IOSUnlistedApp: 'iOS Unlisted App',
    IOSXCArchive: 'iOS .xcarchive'
} as const;

type MobileAppPlatform = keyof typeof PLATFORM;
type InternalBetaState = keyof typeof INTERNAL_BETA_STATE;
type ExternalBetaState = keyof typeof EXTERNAL_BETA_STATE;
type AppVersionState = keyof typeof APP_VERSION_STATE;

const statusValueFormatter = (params: ValueFormatterParams): string => {
    switch (params.data.Platform as MobileAppPlatform) {
        case 'IOSTestFlight':
            const externalBetaState = params.data.ExternalBuildState as ExternalBetaState;
            return EXTERNAL_BETA_STATE[externalBetaState];
        case 'IOSVPP':
        case 'IOSUnlistedApp':
            const appVersionState = params.data.AppVersionState as AppVersionState;
            return APP_VERSION_STATE[appVersionState];
        default:
            return '-';
    }
};

@Component({
    templateUrl: 'MobileAppList.component.html',
    standalone: true,
    imports: [
        SwxModule,
        FormsModule,
        NgFor,
        GridModule,
        NgIf,
        RouterLink,
        HasPermissionPipe,
    ],
})
export class MobileAppListComponent implements OnInit, AfterViewInit {
    items: IResourceItemList<any>;
    searchQuery;
    query: any;
    includeInactive = false;
    @ViewChild('grid', { static: true }) grid: GridComponent;
    exportResourcePost = this.api.MobileAppExport;
    mobileAppPlatforms = this.api.MobileAppPlatform.query();
    mobileAppAudiences = this.api.MobileAppAudience.query();
    clients = this.api.Client.query();
    columnDefs: IAgGridColumnDef[] = [
        { headerName: 'ID', colId: 'Id', field: 'Id', pinned: 'left', width: 50 },
        { colId: "ClientName", field: "ClientName", headerName: "Client", width: 200 },
        { colId: 'APIClientCode', field: 'APIClientCode', headerName: 'Configuration'},
        { colId: "AppTitle", field: "AppTitle", headerName: "Name", width: 100, cellRenderer: c => {
                if (c.data == null) return '';
                var container = document.createElement('div');
                container.innerHTML = '<a href="/mobileApps/' + c.data.Id + '" onclick="event.preventDefault();">' + c.value + '</a>';
                container.querySelector('a').addEventListener('click', e => this.router.navigateByUrl(e.target['getAttribute']('href')));
                return container;
            }
        },
        { colId: "Active", field: "Active", headerName: "Active?", width: 70, valueFormatter: c => c.value == null ? '' : c.value ? 'Yes' : 'No', filterType: 'boolean', },
        { colId: "Platform", field: "Platform", headerName: "Format", width: 120, valueFormatter: c => c.value ? this.mobileAppPlatforms[c.value] : c.value, filterType: 'enum', source: 'MobileAppPlatform' },
        {
            colId: "Priority", 
            field: "Priority", 
            headerName: "Priority",
            width: 60
        },
        {
            headerName: 'Status',
            colId: 'Status',
            valueFormatter: statusValueFormatter,

        },
        { colId: 'ProvisioningProfileExpirationDate', field: 'ProvisioningProfileExpirationDate', headerName: 'Provisioning Profile Expiration Date' ,width: 90, valueFormatter: c => c.value == null ? '' : moment.utc(c.value).format(this.$root.dateFormat), filterType: "date" },
        { colId: "ExpirationDate", field: "ExpirationDate", headerName: "Expiration date", width: 90, valueFormatter: c => c.value == null ? '' : moment.utc(c.value).format(this.$root.dateFormat), filterType: "date" },

        { colId: "Audience", field: "Audience", headerName: "Audience", width: 100, valueFormatter: c => c.value ? this.mobileAppAudiences[c.value] : c.value, filterType: 'enum', source: 'MobileAppAudience' },
        { colId: "Comments", field: "Comments", headerName: "Comments", width: 200, valueFormatter: c => c.value == null ? '' : EllipsisPipe.instance.transform(c.value, 50) },
        { colId: "MobileAppReleaseId", field: "MobileAppReleaseId", headerName: "#", width: 60, filterType: "integer" },
        { colId: "Version", field: "Version", headerName: "Version", width: 100, cellRenderer: c => {
                if (c.data == null) return '';
                var container = document.createElement('div');
                container.innerHTML = '<a href="/mobileAppReleases/' + c.data.MobileAppReleaseId + '" onclick="event.preventDefault();">' + c.value + '</a>';
                container.querySelector('a').addEventListener('click', e => this.router.navigateByUrl(e.target['getAttribute']('href')));
                return container;
            } },
        { colId: "Build", field: "BuildNumber", headerName: "Build", width: 50 },
        { colId: "ShowOnPortal", field: "ShowOnPortal", headerName: "Show on portal?", width: 80, cellRenderer: c => {
                if (c.data == null) return '';
                var container = document.createElement('div');
                container.innerHTML = '<input type="checkbox">';
                var checkbox = container.querySelector('[type=checkbox]') as HTMLInputElement;
                checkbox.checked = c.value;
                checkbox.addEventListener('change', () => {
                    this.api.MobileAppRelease.patch(c.data.Id,
                        {
                            ShowOnPortal: checkbox.checked,
                        });
                });
                return container;
            } },
        { colId: "Uploaded", field: "Uploaded", headerName: "Upload", width: 160, cellRenderer: c => this.mdmStateCellRendererService.mdmStateCellRenderer(c, () => this.refresh(), false), filterType: 'boolean', },
        { colId: "MobileConfigurationRequestDate", field: "MobileConfigurationRequestDate", headerName: "First launch date", width: 90, valueFormatter: c => c.value == null ? '' : moment.utc(c.value).format(this.$root.dateFormat), filterType: "date" },
        { colId: "BuildFilename", field: "BuildFilename", headerName: "Build", width: 200, cellTemplate: "<a (click)='parent.downloadBuildFile(params.data)'>{{params.value}}</a>" },
        { colId: "XArchiveFilename", field: "XArchiveFilename", headerName: ".xarchive", width: 200, cellRenderer: c => {
                var container = document.createElement('a');
                if (c.data?.XArchiveFileUploadId != null) {
                    container.innerHTML = c.data.XArchiveFilename;
                    container.addEventListener('click', () => this.api.FileUploadUrl.get(c.data.XArchiveFileUploadId).$promise
                        .then(response => this.fileDownloadService.downloadUrl(response.Url, response.Filename)));
                }
                return container;
            } },
        { colId: "CreatedDate", field: "CreatedDate", headerName: "Created date", width: 120, valueFormatter: c => c.value == null ? '' : moment.utc(c.value).format(this.$root.dateTimeFormat), filterType: "date" },
        { colId: "CreatedByUserName", field: "CreatedByUserName", headerName: "Created by", width: 120 },
        { colId: "LastModified", field: "LastModified", headerName: "Modified date", width: 120, valueFormatter: c => c.value == null ? '' : moment.utc(c.value).format(this.$root.dateTimeFormat), filterType: "date" },
        { colId: "LastModifiedByUserName", field: "LastModifiedByUserName", headerName: "Modified by", width: 120 },
        { colId: "Actions", field: "Id", headerName: "", width: 80, cellClass: "actions", cellRenderer: c => {
                if (c.data == null) return '';
                var container = document.createElement('div');
                container.innerHTML = '<a href="/mobileApps/' + c.data.Id + '" class="mdi mdi-pencil" title="' + this.translateService.translate('Edit') + '" onclick="event.preventDefault();"></a>'
                    + (this.hasPermission.hasPermission('ConfigEditMobileApps') ? '<a class="mdi mdi-delete" title="' + this.translateService.translate('Delete') + '" onclick="event.preventDefault();"></a>' : '')
                    + (this.hasPermission.hasPermission('ConfigEditMobileApps') ? '<a href="/mobileApps/new?copy=' + c.data.Id + '" class="mdi mdi-content-copy" title="' + this.translateService.translate('Copy') + '" onclick="event.preventDefault();"></a>' : '');
                Array.from(container.querySelectorAll('a[href]')).forEach(n => n.addEventListener('click', e => this.router.navigateByUrl(e.target['getAttribute']('href'))));
                Array.from(container.querySelectorAll('.mdi-delete')).forEach(n => n.addEventListener('click', () => this.delete(c.data)));
                return container;
            }, pinned: "right", searchable: false, excelIgnore: true },
    ]
    
    // columnDefs: (IAgGridColumnDef | IAgGridColGroupDef)[] = [
    //     {
    //         headerName: "App",
    //         children: [
    //             { colId: "AppTitle", field: "AppTitle", headerName: "Name", width: 100, pinned: "left", cellRenderer: c => {
    //                 if (c.data == null) return '';
    //                 var container = document.createElement('div');
    //                 container.innerHTML = '<a href="/mobileApps/' + c.data.Id + '" onclick="event.preventDefault();">' + c.value + '</a>';
    //                 container.querySelector('a').addEventListener('click', e => this.router.navigateByUrl(e.target['getAttribute']('href')));
    //                 return container;
    //             } },
    //             { colId: "Active", field: "Active", headerName: "Active?", width: 70, valueFormatter: c => c.value == null ? '' : c.value ? 'Yes' : 'No', filterType: 'boolean', },
    //             { colId: "ClientName", field: "ClientName", headerName: "Client", width: 200 },
    //             { colId: "Platform", field: "Platform", headerName: "Format", width: 120, valueFormatter: c => c.value ? this.mobileAppPlatforms[c.value] : c.value, filterType: 'enum', source: 'MobileAppPlatform' },
    //             { colId: "Audience", field: "Audience", headerName: "Audience", width: 100, valueFormatter: c => c.value ? this.mobileAppAudiences[c.value] : c.value, filterType: 'enum', source: 'MobileAppAudience' },
    //             { colId: "Comments", field: "Comments", headerName: "Comments", width: 200, valueFormatter: c => c.value == null ? '' : EllipsisPipe.instance.transform(c.value, 50) },
    //         ],
    //     },
    //     {
    //         headerName: "Latest release",
    //         children: [
    //             { colId: "MobileAppReleaseId", field: "MobileAppReleaseId", headerName: "#", width: 60, filterType: "integer" },
    //             { colId: "Version", field: "Version", headerName: "Version", width: 100, cellRenderer: c => {
    //                 if (c.data == null) return '';
    //                 var container = document.createElement('div');
    //                 container.innerHTML = '<a href="/mobileAppReleases/' + c.data.MobileAppReleaseId + '" onclick="event.preventDefault();">' + c.value + '</a>';
    //                 container.querySelector('a').addEventListener('click', e => this.router.navigateByUrl(e.target['getAttribute']('href')));
    //                 return container;
    //             } },
    //             { colId: "ShowOnPortal", field: "ShowOnPortal", headerName: "Show on portal?", width: 80, cellRenderer: c => {
    //                 if (c.data == null) return '';
    //                 var container = document.createElement('div');
    //                 container.innerHTML = '<input type="checkbox">';
    //                 var checkbox = container.querySelector('[type=checkbox]') as HTMLInputElement;
    //                 checkbox.checked = c.value;
    //                 checkbox.addEventListener('change', () => {
    //                     this.api.MobileAppRelease.patch(c.data.Id,
    //                         {
    //                             ShowOnPortal: checkbox.checked,
    //                         });
    //                 });
    //                 return container;
    //             } },
    //             { colId: "Uploaded", field: "Uploaded", headerName: "Upload", width: 160, cellRenderer: c => this.mdmStateCellRendererService.mdmStateCellRenderer(c, () => this.refresh(), this.viewContainerRef, false), filterType: 'boolean', },
    //             { colId: "MobileConfigurationRequestDate", field: "MobileConfigurationRequestDate", headerName: "First launch date", width: 90, valueFormatter: c => c.value == null ? '' : moment.utc(c.value).format(this.$root.dateFormat), filterType: "date" },
    //             { colId: "ExpirationDate", field: "ExpirationDate", headerName: "Expiration date", width: 90, valueFormatter: c => c.value == null ? '' : moment.utc(c.value).format(this.$root.dateFormat), filterType: "date" },
    //             { colId: "BuildFilename", field: "BuildFilename", headerName: "Build", width: 200, cellTemplate: "<a (click)='parent.downloadBuildFile(params.data)'>{{params.value}}</a>" },
    //             { colId: "XArchiveFilename", field: "XArchiveFilename", headerName: ".xarchive", width: 200, cellRenderer: c => {
    //                 var container = document.createElement('a');
    //                 if (c.data?.XArchiveFileUploadId != null) {
    //                     container.innerHTML = c.data.XArchiveFilename;
    //                     container.addEventListener('click', () => this.api.FileUploadDownload.exportItem(c.data.XArchiveFileUploadId)
    //                         .then(response => this.fileDownloadService.download(response.body, header => response.headers.get(header))));
    //                 }
    //                 return container;
    //             } },
    //             { colId: "CreatedDate", field: "CreatedDate", headerName: "Created date", width: 120, valueFormatter: c => c.value == null ? '' : moment.utc(c.value).format(this.$root.dateTimeFormat), filterType: "date" },
    //             { colId: "CreatedByUserName", field: "CreatedByUserName", headerName: "Created by", width: 120 },
    //             { colId: "LastModified", field: "LastModified", headerName: "Modified date", width: 120, valueFormatter: c => c.value == null ? '' : moment.utc(c.value).format(this.$root.dateTimeFormat), filterType: "date" },
    //             { colId: "LastModifiedByUserName", field: "LastModifiedByUserName", headerName: "Modified by", width: 120 },
    //         ],
    //     },
    //     {
    //         headerName: '',
    //         children: [
    //             // { colId: "_spacer", flex: 1, excelIgnore: true },
    //             { colId: "Actions", field: "Id", headerName: "", width: 80, cellClass: "actions", cellRenderer: c => {
    //                 if (c.data == null) return '';
    //                 var container = document.createElement('div');
    //                 container.innerHTML = '<a href="/mobileApps/' + c.data.Id + '" class="mdi mdi-pencil" title="' + this.translateService.translate('Edit') + '" onclick="event.preventDefault();"></a>'
    //                     + (this.hasPermission.hasPermission('ConfigEditMobileApps') ? '<a class="mdi mdi-delete" title="' + this.translateService.translate('Delete') + '" onclick="event.preventDefault();"></a>' : '')
    //                     + (this.hasPermission.hasPermission('ConfigEditMobileApps') ? '<a href="/mobileApps/new?copy=' + c.data.Id + '" class="mdi mdi-content-copy" title="' + this.translateService.translate('Copy') + '" onclick="event.preventDefault();"></a>' : '');
    //                 Array.from(container.querySelectorAll('a[href]')).forEach(n => n.addEventListener('click', e => this.router.navigateByUrl(e.target['getAttribute']('href'))));
    //                 Array.from(container.querySelectorAll('.mdi-delete')).forEach(n => n.addEventListener('click', () => this.delete(c.data)));
    //                 return container;
    //             }, pinned: "right", searchable: false, excelIgnore: true },
    //         ]
    //     }
    // ];

    constructor(
        private router: Router,
        private viewContainerRef: ViewContainerRef,
        private $root: RootScope,
        public api: ApiService,
        private translateService: TranslateService,
        private ticketTrackingSystem: TicketTrackingSystemService,
        private confirmService: ConfirmService,
        private hasPermission: HasPermissionService,
        private fileDownloadService: FileDownloadService,
        private notification: NotificationService,
        private mdmStateCellRendererService: MDMStateCellRendererService,
    ) {
    }

    ngOnInit() {
        if (window.localStorage['mobileAppQuery']) {
            this.query = JSON.parse(window.localStorage['mobileAppQuery']);
        } else {
            this.resetQuery();
        }
    }

    ngAfterViewInit(): void {
        this.grid.gridReadyPromise.then(() => {
            this.grid.refresh();
        });
    }

    resetQuery() {
        this.searchQuery = '';
        this.query = {
            Filters: []
        };
    };

    refresh() {
        window.localStorage['mobileAppQuery'] = JSON.stringify(this.query);
        this.grid.refresh();
    }
    
    delete(item: IResourceItem) {
        this.confirmService.confirm(this.translateService.translate('Are you sure?')).then(() => {
            this.ticketTrackingSystem.trackAndDelete(item, () => this.refresh());
        }).catch(() => {});
    }

    syncMDM() {
        this.api.WorkspaceOneSyncAll.post({}).then(r => {
            this.refresh()
            this.notification.show("Synced")
        })
    }

    downloadBuildFile(row) {
        this.api.FileUploadUrl.get(row.BuildFileUploadId).$promise
            .then(response => this.fileDownloadService.downloadUrl(response.Url, response.Filename));
    }
}
