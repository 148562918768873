<script src="../../../node_modules/@ckeditor/ckeditor5-upload/src/adapters/base64uploadadapter.js"></script>
<page-title>{{('Station monitoring profile'|translate) + ': ' + (item.Name || 'new')}}</page-title>
<form #ngForm="ngForm" (submit)="save()" class="noMargins" id="StationMonitoringProfileDetail">
    <div class="toolbar">
        <a class="tab" (click)="switchTab('basicInfo')" [class.active]="tab == 'basicInfo'" [class.invalid]="!ngForm.form.controls.basicInfoForm?.valid">{{'Basic info'|translate}}</a>
        <a class="tab" (click)="switchTab('conditions')" [class.active]="tab == 'conditions'" [class.invalid]="!ngForm.form.controls.conditionsForm?.valid">{{'Conditions'|translate}}</a>
        <a class="tab" (click)="switchTab('enteringActions')" [class.active]="tab == 'enteringActions'" [class.invalid]="!ngForm.form.controls.enteringActionsForm?.valid">{{'Entering actions'|translate}}</a>
        <a class="tab" (click)="switchTab('exitingActions')" [class.active]="tab == 'exitingActions'" [class.invalid]="!ngForm.form.controls.exitingActionsForm?.valid">{{'Exiting actions'|translate}}</a>
        <input type="submit" [disabled]="!ngForm.form.valid || ngForm.form.pristine" value="{{'Save'|translate}}" />
        <input type="button" (click)="cancel()" value="{{'Cancel'|translate}}" />
        <input type="button" *ngIf="('DataChanges'|hasPermission)" (click)="viewHistory()" [disabled]="!item.Id" value="{{'View history'|translate}}" />
    </div>

    <fieldset class="contentMargins form" [disabled]="!('ConfigEditStationMonitoringProfiles'|hasPermission)" [hidden]="tab != 'basicInfo'" ngModelGroup="basicInfoForm">
        <label>
            <span class="caption">{{'Name'|translate}}</span>
            <input type="text" required [(ngModel)]="item.Name" name="item.Name" maxlength="100" />
        </label>
        <label>
            <span class="caption">{{'Active'|translate}}</span>
            <input type="checkbox" [(ngModel)]="item.Active" name="item.Active" />
        </label>
        <label>
            <span class="caption">{{'Minimum duration between alerts (minutes)'|translate}}</span>
            <input required type="number" min="1" step="1" [(ngModel)]="item.MinimumAlertAgeMinutes" name="item.MinimumAlertAgeMinutes" style="width: 60px;" />
        </label>
        <label>
            <span class="caption">{{'Email sender'|translate}}</span>
            <select required [(ngModel)]="item.EmailFromId" name="item.EmailFromId">
                <option *ngFor="let emailFrom of emailFroms|orderBy:'Id'" [ngValue]="emailFrom.Id">
                    {{emailFrom.EmailAddress}}
                </option>
            </select>
        </label>
        <label>
            <span class="caption">{{'PagerDuty integration key'|translate}}</span>
            <input type="text" [(ngModel)]="item.PagerDutyIntegrationKey" name="item.PagerDutyIntegrationKey" maxlength="400" />
        </label>
    </fieldset>

    <fieldset class="form contentMargins" [disabled]="!('ConfigEditStationMonitoringProfiles'|hasPermission)" [hidden]="tab != 'conditions'" ngModelGroup="conditionsForm" *ngIf="availableFields && fieldGroups">
        <select required [(ngModel)]="item.ConditionType" [name]="'item.ConditionType'" style="margin: 0 0 12px 3px; display: block;">
            <option [ngValue]="'OrGroup'">{{'Any of'|translate}}</option>
            <option [ngValue]="'AndGroup'">{{'All of'|translate}}</option>
        </select>
        <station-monitoring-condition-list #list [ngForm]="ngForm" [conditions]="item.StationMonitoringConditions" [parent]="null" [availableFields]="availableFields" [fieldGroups]="fieldGroups"></station-monitoring-condition-list>
    </fieldset>

    <fieldset class="form contentMargins" [disabled]="!('ConfigEditStationMonitoringProfiles'|hasPermission)" [hidden]="tab != 'enteringActions'" ngModelGroup="enteringActionsForm">
        <table class="table" *ngFor="let action of item.StationMonitoringActions|filter:{AlertState:'Entering'}; let i = index" style="margin-bottom: 20px; width: 100%;">
            <tr>
                <th style="width: 100px;">{{'Type'|translate}}</th>
                <th>
                    <span *ngIf="action.Type !== 'PagerDutyEvent'">{{(action.Type === 'Ticket' ? 'Title': 'Recipient')|translate}} <span *ngIf="action.Type === 'Email'">{{'(comma separated)'|translate}}</span></span>
                </th>
                <td style="width: 40px;">
                    <a *ngIf="'ConfigEditStationMonitoringProfiles'|hasPermission" (click)="removeAction(action)" class="mdi mdi-delete"></a>
                </td>
            </tr>
            <tr *ngIf="action.Type === 'Ticket' || action.Type === 'PagerDutyEvent'">
                <td colspan="3">
                    <label>
                        <span class="caption">{{'Available tokens'|translate}}</span>
                        <span class="value" style="width: auto;">{{'{StationName} {StationAlarmsList} {Timestamp}'}}</span>
                    </label>
                </td>
            </tr>
            <tr>
                <td>
                    <select required [(ngModel)]="action.Type" [name]="'action[' + i + '].Type'">
                        <option *ngFor="let kv of enteringStationMonitoringActionTypes|keyvalue" [ngValue]="kv.key">
                            {{kv.value}}
                        </option>
                    </select>
                </td>
                <td colspan="2" *ngIf="action.Type !== 'PagerDutyEvent'">
                    <input type="text" required style="width: 100%;" [(ngModel)]="action.Recipient" [name]="'action[' + i + '].Recipient'" />
                </td>
                <td colspan="2" *ngIf="action.Type === 'PagerDutyEvent'">
                    <label class="inline">
                        <span class="caption">{{'Severity'|translate}}</span>
                        <select required [(ngModel)]="action.PagetDutyEventSeverity" [name]="'action[' + i + '].PagetDutyEventSeverity'">
                            <option *ngFor="let kv of pagetDutyEventSeverities|keyvalue" [ngValue]="kv.key">
                                {{kv.value}}
                            </option>
                        </select>
                    </label>
                    <label class="inline">
                        <span class="caption">{{'Source'|translate}}</span>
                        <input type="text" required style="width: 250px;" [(ngModel)]="action.PagetDutySource" [name]="'action[' + i + '].PagetDutySource'" />
                    </label>
                    <label class="inline">
                        <span class="caption">{{'Component'|translate}}</span>
                        <input type="text" style="width: 150px;" [(ngModel)]="action.PagetDutyComponent" [name]="'action[' + i + '].PagetDutyComponent'" />
                    </label>
                    <label class="inline">
                        <span class="caption">{{'Group'|translate}}</span>
                        <input type="text" style="width: 150px;" [(ngModel)]="action.PagetDutyGroup" [name]="'action[' + i + '].PagetDutyGroup'" />
                    </label>
                    <label class="inline">
                        <span class="caption">{{'Class'|translate}}</span>
                        <input type="text" style="width: 150px;" [(ngModel)]="action.PagetDutyClass" [name]="'action[' + i + '].PagetDutyClass'" />
                    </label>
                </td>
            </tr>
            <tr *ngIf="action.Type === 'Ticket'">
                <td colspan="3">
                    <label class="inline">
                        <span class="caption">{{'Project'|translate}}</span>
                        <select required [(ngModel)]="action.TicketTrackingProjectId" [name]="'action[' + i + '].TicketTrackingProjectId'">
                            <option *ngFor="let option of ticketTrackingProjects|orderBy:'Name'" [ngValue]="option.Id">
                                {{option.Code}} - {{option.Name}}
                            </option>
                        </select>
                    </label>
                    <label class="inline" *ngIf="action.TicketTrackingProjectId">
                        <span class="caption">{{'Type'|translate}}</span>
                        <select required [(ngModel)]="action.TicketTrackingTypeId" [name]="'action[' + i + '].TicketTrackingTypeId'">
                            <option *ngFor="let option of (ticketTrackingProjects|findById:action.TicketTrackingProjectId).Types|orderBy:'Name'" [ngValue]="option.Id">
                                {{option.Name}}
                            </option>
                        </select>
                    </label>
                </td>
            </tr>
            <tr *ngIf="action.Type === 'Email'">
                <td colspan="3">
                    <label>
                        <span class="caption">{{'Available tokens'|translate}}</span>
                        <span class="value" style="width: auto;">{{'{StationName} {StationAlarmsList} {TicketNumber} {Timestamp}'}}</span>
                    </label>
                </td>
            </tr>
            <tr *ngIf="action.Type === 'Email'">
                <td colspan="3">
                    <input type="text" required style="width: 100%;" [(ngModel)]="action.Subject" [name]="'action[' + i + '].Subject'" placeholder="Subject" />
                </td>
            </tr>
            <tr *ngIf="action.Type === 'PagerDutyEvent'">
                <td colspan="3">
                    <textarea required style="width: 100%;" [(ngModel)]="action.Content" [name]="'action[' + i + '].Content'" placeholder="Message"></textarea>
                </td>
            </tr>
            <tr *ngIf="action.Type !== 'PagerDutyEvent'">
                <td colspan="3">
                    <div *ngIf="action.Type !== 'PhoneCall'">
                        <label>
                            <span class="caption">{{'Available tokens'|translate}}</span>
                            <span class="value">{{'{StationName} {StationAlarmsList} {StationAlarmsTable} {StationRawData} {Timestamp} {StationStatusUrl}' + (action.Type === 'Email' ? ' {TicketNumber} {TicketUrl}' : '')}}</span>
                        </label>
                    </div>
                    <div *ngIf="action.Type === 'PhoneCall'">
                        <label>
                            <span class="caption">{{'Available tokens'|translate}}</span>
                            <span class="value">{{'{StationName}'}}</span>
                        </label>
                    </div>
                </td>
            </tr>
            <tr *ngIf="action.Type !== 'PagerDutyEvent'">
                <td colspan="3">
                    <ckeditor *ngIf="action.Type !== 'PhoneCall'" required [editor]="Editor" [config]="ckConfig" style="width: 100%;" [(ngModel)]="action.Content" [name]="'action[' + i + '].Content'"></ckeditor>
                    <textarea *ngIf="action.Type === 'PhoneCall'" required style="width: 100%;" [(ngModel)]="action.Content" [name]="'action[' + i + '].Content'" placeholder="Message"></textarea>
                </td>
            </tr>
        </table>
        <input type="button" class="green" *ngIf="('ConfigAddStationMonitoringProfiles'|hasPermission)" (click)="addAction('Entering')" value="{{'Add action'|translate}}" />
    </fieldset>

    <fieldset class="form contentMargins" [disabled]="!('ConfigEditStationMonitoringProfiles'|hasPermission)" [hidden]="tab != 'exitingActions'" ngModelGroup="exitingActionsForm">
        <table class="table" *ngFor="let action of item.StationMonitoringActions|filter:{AlertState:'Exiting'}; let i = index" style="margin-bottom: 20px; width: 100%;">
            <tr>
                <th style="width: 100px;">{{'Type'|translate}}</th>
                <th></th>
                <td style="width: 40px;">
                    <a *ngIf="'ConfigEditStationMonitoringProfiles'|hasPermission" (click)="removeAction(action)" class="mdi mdi-delete"></a>
                </td>
            </tr>
            <tr>
                <td>
                    <select required [(ngModel)]="action.Type" [name]="'action[' + i + '].Type'">
                        <option *ngFor="let kv of exitingStationMonitoringActionTypes|keyvalue" [ngValue]="kv.key">
                            {{kv.value}}
                        </option>
                    </select>
                </td>
                <td colspan="2" *ngIf="action.Type === 'PagerDutyEvent'">
                    <label class="inline">
                        <span class="caption">{{'Severity'|translate}}</span>
                        <select required [(ngModel)]="action.PagetDutyEventSeverity" [name]="'action[' + i + '].PagetDutyEventSeverity'">
                            <option *ngFor="let kv of pagetDutyEventSeverities|keyvalue" [ngValue]="kv.key">
                                {{kv.value}}
                            </option>
                        </select>
                    </label>
                    <label class="inline">
                        <span class="caption">{{'Source'|translate}}</span>
                        <input type="text" required style="width: 250px;" [(ngModel)]="action.PagetDutySource" [name]="'action[' + i + '].PagetDutySource'" />
                    </label>
                    <label class="inline">
                        <span class="caption">{{'Component'|translate}}</span>
                        <input type="text" style="width: 150px;" [(ngModel)]="action.PagetDutyComponent" [name]="'action[' + i + '].PagetDutyComponent'" />
                    </label>
                    <label class="inline">
                        <span class="caption">{{'Group'|translate}}</span>
                        <input type="text" style="width: 150px;" [(ngModel)]="action.PagetDutyGroup" [name]="'action[' + i + '].PagetDutyGroup'" />
                    </label>
                    <label class="inline">
                        <span class="caption">{{'Class'|translate}}</span>
                        <input type="text" style="width: 150px;" [(ngModel)]="action.PagetDutyClass" [name]="'action[' + i + '].PagetDutyClass'" />
                    </label>
                </td>
            </tr>
            <tr>
                <td colspan="3">
                    <label>
                        <span class="caption">{{'Available tokens'|translate}}</span>
                        <span class="value">{{'{Timestamp}'}}</span>
                    </label>
                </td>
            </tr>
            <tr *ngIf="action.Type === 'TicketComment'">
                <td colspan="3">
                    <ckeditor required [editor]="Editor" [config]="ckConfig" style="width: 100%;" [(ngModel)]="action.Content" [name]="'action[' + i + '].Content'"></ckeditor>
                </td>
            </tr>
            <tr *ngIf="action.Type === 'PagerDutyEvent'">
                <td colspan="3">
                    <textarea required style="width: 100%;" [(ngModel)]="action.Content" [name]="'action[' + i + '].Content'" placeholder="Message"></textarea>
                </td>
            </tr>
        </table>
        <input type="button" class="green" *ngIf="('ConfigAddStationMonitoringProfiles'|hasPermission)" (click)="addAction('Exiting')" value="{{'Add action'|translate}}" />
    </fieldset>
</form>
